import React from "react";
import ScrollToTop from "../../components/Common/ScrollTop";
import SliderStyleTwo from "../../components/Elements/Slider/SliderStyleTwo";
import CTA from "./CTASection";
import ServiceTwo from "./ServiceSectionTwo";
import Testimonial from "./TestimonialSection";

import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import Contact2 from "../contact/Contact2";
import Technology from "./TechnologySection";

const HomeTwoMain = ({ counters }) => {
  return (
    <React.Fragment>
      {/* slider-start */}
      <SliderStyleTwo />
      {/* slider-start */}
      <ServiceTwo />

      <CounterStyleThree counters={counters} />
      {/* <Process /> */}

      {/* <Service /> */}

      {/* <Process2 /> */}

      <Testimonial />

      <CTA />

      <Technology />

      {/* <ContactMain /> */}

      <Contact2 />

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeTwoMain;
