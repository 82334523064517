import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import RSMobileMenu from "./RSMobileMenu";

import axios from "axios";
import {
  default as Logo,
  default as whiteLogo,
} from "../../../assets/img/logo/logo-2.png";

const BACKEND_API = process.env.REACT_APP_BACKEND_BASEURL;

const HeaderStyleThree = (props) => {
  const { parentMenu, secondParentMenu, activeMenu } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const [data, setData] = React.useState(null);

  const getServiceData = async () => {
    try {
      const resp = await axios.get(`${BACKEND_API}/services/`);
      setData(resp.data);
    } catch (e) {
      setData(null);
    }
  };

  useEffect(() => {
    getServiceData();
  }, []);

  return (
    <React.Fragment>
      <div className="full-width-header">
        <header id="rs-header" className="rs-header style3 header-transparent">
          <div
            className={
              isVisible
                ? "menu-area menu-sticky sticky"
                : "menu-area menu-sticky"
            }
            style={{
              backgroundColor: isVisible
                ? "#fff"
                : menuOpen
                  ? "#fff"
                  : "inherit",
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="logo-part hidden-md">
                    <Link to="/">
                      <img className="normal-logo" src={whiteLogo} alt="" />
                      <img className="sticky-logo" src={Logo} alt="" />
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-9 text-right md-text-left"
                  style={{
                    backgroundColor: menuOpen ? "#fff" : "inherit",
                  }}
                >
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <div className="mobile-menu md-display-block">
                        <Link to="/" className="mobile-normal-logo">
                          <img src={whiteLogo} alt="logo" />
                        </Link>
                        <Link to="/" className="mobile-sticky-logo">
                          <img src={Logo} alt="logo" />
                        </Link>
                        <Link
                          to="#"
                          className="rs-menu-toggle"
                          onClick={() => {
                            setMenuOpen(!menuOpen);
                          }}
                          style={{
                            color: menuOpen ? "#000" : "#fff",
                          }}
                        >
                          <i className="fa fa-bars"></i>
                        </Link>
                      </div>
                      <nav className="rs-menu pr-70 lg-pr-50 md-pr-0 hidden-md">
                        <ul className="nav-menu">
                          <MenuItems
                            parentMenu={parentMenu}
                            secondParentMenu={secondParentMenu}
                            activeMenu={activeMenu}
                            data={data}
                          />
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RSMobileMenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            data={data}
          />
          <div
            onClick={() => setMenuOpen(false)}
            className={menuOpen ? "body-overlay show" : "body-overlay"}
          ></div>
        </header>
      </div>
    </React.Fragment>
  );
};

export default HeaderStyleThree;
