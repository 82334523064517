import React from "react";
import BlogDetailsMain from "../../components/BlogDetails";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ScrollToTop from "../../components/Common/ScrollTop";
import Footer from "../../components/Layout/Footer";

// Breadcrumbs Background Image
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import Contact2 from "../contact/Contact2";
import { CASE_DATA } from "./data";
import { useParams } from "react-router-dom";

const CaseStudy = () => {
  const { study } = useParams();

  const data = CASE_DATA[study];

  return (
    <React.Fragment>
      <HeaderStyleThree parentMenu="case-study" />

      <SiteBreadcrumb
        pageTitle={data.title}
        titleClass="page-title new-title pb-10"
        // pageCategory={data.category} // this is optional
        // pageName={data.title}
        breadcrumbsImg={data.bannerImage}
      />

      {/* Blog Main */}
      <BlogDetailsMain content={data.content} />
      {/* Blog Main End */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Contact2 />

      <Footer />
    </React.Fragment>
  );
};

export default CaseStudy;
