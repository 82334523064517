import dateFns from "date-fns";
import PropTypes from "prop-types";
import React from "react";

import generateTimeSlots from "./generate-time-slots";

import { List, ListItem } from "./List";

function Root({
  pickedDay,
  slotSizeMinutes,
  validator,
  pickTime,
  timezoneOffset,
}) {
  console.log("timezoneOffset", timezoneOffset);
  const timeSlots = generateTimeSlots(
    pickedDay,
    slotSizeMinutes,
    timezoneOffset,
  );

  return (
    <List>
      {timeSlots.map((slot) => {
        const isValid = validator ? validator(slot) : true;
        return (
          <ListItem
            key={slot}
            isValid={isValid}
            onClick={() => isValid && pickTime(slot)}
          >
            {dateFns.format(slot, "hh:mm A")}
          </ListItem>
        );
      })}
    </List>
  );
}

Root.propTypes = {
  pickedDay: PropTypes.instanceOf(Date),
  slotSizeMinutes: PropTypes.number.isRequired,
  validator: PropTypes.func,
  pickTime: PropTypes.func.isRequired,
  timezoneOffset: PropTypes.number.isRequired,
};

export default Root;
