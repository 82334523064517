import { motion } from "framer-motion";
import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import PortfolioTabTwo from "../../components/Elements/Tab/PortfolioTabTwo";

const Process2 = ({ data }) => {
  console.log(data);

  const fadeUpAnimation = {
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
  };

  return (
    <React.Fragment>
      <div
        id="rs-blog"
        className="rs-process style6  pt-60 pb-120 md-pt-70 md-pb-80 w-100"
      >
        <motion.div
          className="container"
          {...fadeUpAnimation}
          transition={{
            duration: 1,
          }}
        >
          <SectionTitle
            sectionClass="sec-title6 mb-50 text-center"
            subtitleClass="sub-text new-text"
            subtitle={
              data?.sub_heading ||
              "A fullrange of services to achieve your goals"
            }
            titleClass="title new-title"
            title={data?.heading || "Our Process"}
          />

          <PortfolioTabTwo accordians={data?.accordian_container || []} />
        </motion.div>
      </div>
    </React.Fragment>
  );
};

export default Process2;
