import React from "react";
import OwlCarousel from "react-owl-carousel2";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleTestimonial from "../../components/Testimonial/SingleTestimonial";

// Testimonial Images
import quote from "../../assets/img/testimonial/quote.png";

// Testimonial Avatars

function Testimonial() {
  const options = {
    items: 3,
    nav: false,
    dots: false,
    margin: 30,
    rewind: false,
    autoplay: true,
    stagePadding: 30,
    loop: true,
    center: false,
    responsive: {
      0: {
        stagePadding: 0,
        items: 1,
        dots: false,
      },
      768: {
        items: 2,
        stagePadding: 0,
        dots: true,
      },
      992: {
        items: 2,
        stagePadding: 0,
      },
      1200: {
        items: 3,
        stagePadding: 0,
      },
      1500: {
        items: 3,
        stagePadding: 0,
      },
    },
  };

  const testimonials = [
    {
      quoteImage: quote,
      authorImage:
        "https://urbanoinfotech.s3.ap-south-1.amazonaws.com/arcolinux_2024-03-05_11-56-53.png",
      Title: "Nadia Adams",
      Designation: "CEO and Co-Founder at Blue Agilis",
      Description:
        "Shubham and his team helped take the Blue Agilis product from our minimal viable prototype to a working a beta platform. Shubham was great to work with, took the time to understand our specs and worked hard to turn or vision into a reality. I recommend Shubham for anyone who is trying to create a minimal viable product and test in the market.",
    },

    {
      quoteImage: quote,
      authorImage:
        "https://urbanoinfotech.s3.ap-south-1.amazonaws.com/ArcoLinux_2024-03-05_12-05-51.png",
      Title: "Oussama Atlassi",
      Designation: "Stratégie, Growth & Revenue Ops | Fondateur @StratMachina",
      Description:
        "Shubham is keen, reliable and willing to fully understand the job he is give before committing to it. Webflow, Airtable, Bubble & Memberstack Expert. Fully responsible and efficient. I have no hesitation in recommending him to any interesting party",
    },
    {
      quoteImage: quote,
      authorImage:
        "https://urbanoinfotech.s3.ap-south-1.amazonaws.com/arcolinux_2024-03-05_12-05-51.png",
      Title: "Vivek Gupta",
      Designation: "Co-Founder and Partner at Perfality Business Solutions",
      Description:
        "We are currently working with CP, Shubham and team. They are very co-operating during the whole implementation process. I worked with Shubham mostly and found him diligent while delivering his duties. I'm happy while working with team and currently continuing the project with them.",
    },
  ];

  return (
    <React.Fragment>
      <div className="rs-testimonial main-home gray-bg pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text"
            subtitle="Testimonial"
            titleClass="title testi-title"
            title="What Our Customers Are Saying"
            effectClass="heading-line"
          />
          <OwlCarousel options={options}>
            {testimonials.map((item, key) => (
              <SingleTestimonial
                key={key}
                itemClass="testi-item"
                quoteImage={quote}
                authorImage={item.authorImage}
                Title={item.Title}
                Designation={item.Designation}
                Description={item.Description}
              />
            ))}
          </OwlCarousel>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Testimonial;
