import { motion } from "framer-motion";
import React from "react";

function CompanySection() {
  const fadeFromdownAnimation = (delay = 0) => ({
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
    transition: {
      // delay:1
      delay,
      duration: 0.7,
    },
  });

  return (
    <section class="companies">
      <div class="container">
        <div class="companies__inner">
          <motion.h2
            class="companies__title block-title"
            {...fadeFromdownAnimation()}
          >
            <span class="companies__title--stroke-text">We provide custom</span>
            <br />
            MVP development services
          </motion.h2>
          <p class="companies__subtitle">
            Each time we start a new project, we suggest creating an MVP first
            and foremost.
            <br />
            We often work with companies of different types — here are some
            types and how they can benefit from MVP’s:
          </p>
          <ul class="companies__wrapper">
            <motion.li class="companies__item" {...fadeFromdownAnimation(0)}>
              <svg
                class="companies__item-svg companies__item-svg--first"
                xmlns="http://www.w3.org/2000/svg"
                width="119"
                height="120"
                viewBox="0 0 119 120"
                fill="none"
              >
                <path
                  d="M41.042 108.734C59.3706 99.5999 59.4353 75.717 54.3461 69.5659C15.9474 48.9492 0.159282 96.6064 2.0311 94.5637C3.52855 92.9296 10.2778 88.6364 13.4248 90.9424C11.3828 92.8291 6.03801 101.185 7.44299 106.418C8.84798 111.652 4.40616 114.93 1.25831 119.561C18.4157 116.729 23.341 104.727 26.5744 102.992C29.1611 101.605 25.9305 111.64 23.9919 116.832C26.8352 114.583 34.2259 109.816 41.042 108.734Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="0.515418"
                ></path>
                <path
                  d="M61.9216 80.7001L48.0053 94.6164L39.5009 86.112L50.6339 74.9789L43.2634 67.6084L32.1304 78.7415L23.626 70.2371L37.5423 56.3208C38.5731 55.2899 40.274 55.2384 41.3564 56.2692C53.1595 44.3631 83.5176 13.7472 90.9396 7.4591C96.2484 2.87187 113.721 -1.50919 116.762 1.48024C119.803 4.46967 115.37 21.9424 110.835 27.3027C104.547 34.7247 73.9308 65.0829 62.0247 76.886C63.0039 77.9683 62.9524 79.6692 61.9216 80.7001Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M74.7041 12.3555L80.0129 17.6643"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M100.578 38.2295L105.887 43.5383"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M83.0547 14.7266L103.568 35.2402"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M99.9079 22.1487C102.014 22.1487 103.722 20.4411 103.722 18.3346C103.722 16.2281 102.014 14.5205 99.9079 14.5205C97.8014 14.5205 96.0938 16.2281 96.0938 18.3346C96.0938 20.4411 97.8014 22.1487 99.9079 22.1487Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="0.515418"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M23.5744 70.237L25.533 72.1956L19.2964 78.4322L10.792 69.9277L24.7083 56.0114C25.7907 54.9291 27.5431 54.9291 28.5739 56.0114L29.7078 57.1454C37.9545 48.5894 63.7255 21.9423 74.1369 12.8194C78.2603 9.21144 91.1973 -0.220719 93.0528 1.63479C93.6713 2.25329 92.5889 4.62422 90.9396 7.45902C83.5175 13.7471 53.1594 44.363 41.3563 56.2691C40.2739 55.2899 38.573 55.2899 37.5422 56.3207L23.5744 70.237Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M62.3855 93.6885L48.4692 107.605L39.9648 99.1004L46.2014 92.8639L48.0054 94.6678L61.9217 80.7515C62.9525 79.7207 63.004 78.0198 61.9732 76.9374C73.8794 65.1343 104.495 34.7762 110.783 27.3542C113.618 25.6533 115.989 24.5709 116.608 25.2409C118.463 27.0449 109.031 39.9819 105.423 44.1053C96.3001 54.5167 69.7045 80.2877 61.097 88.5343L62.3855 89.8229C63.4679 90.8537 63.4679 92.6062 62.3855 93.6885Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M84.5494 37.5081C86.6559 37.5081 88.3635 35.8004 88.3635 33.694C88.3635 31.5875 86.6559 29.8799 84.5494 29.8799C82.443 29.8799 80.7354 31.5875 80.7354 33.694C80.7354 35.8004 82.443 37.5081 84.5494 37.5081Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="0.515418"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M67.0761 113.738L54.7061 101.368L60.8911 86.1636L98.7228 51.3213C98.7228 51.3213 93.5686 61.7327 87.2805 82.6072C84.2911 92.5032 78.003 102.812 67.0761 113.738Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M4.0918 51.5276L16.4618 63.8976L31.6667 57.7126L66.4574 19.8809C66.4574 19.8809 56.046 25.035 35.1715 31.3231C25.327 34.3126 14.9671 40.6007 4.0918 51.5276Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                ></path>
              </svg>
              <h2 class="companies__item-title">Startups</h2>
              <p class="companies__item-subtitle">
                Test whether your idea is valuable to the market. If it’s solid,
                you’ll have tangible results which you can show to investors and
                get more funding.
              </p>
            </motion.li>
            <motion.li class="companies__item" {...fadeFromdownAnimation(0.2)}>
              <svg
                class="companies__item-svg companies__item-svg--second"
                xmlns="http://www.w3.org/2000/svg"
                width="117"
                height="115"
                viewBox="0 0 117 115"
                fill="none"
              >
                <rect
                  x="46.4248"
                  y="52.1748"
                  width="58.65"
                  height="62.1"
                  rx="6.9"
                  fill="white"
                ></rect>
                <rect
                  x="46.4248"
                  y="52.1748"
                  width="58.65"
                  height="62.1"
                  rx="6.9"
                  fill="white"
                ></rect>
                <rect
                  x="46.4248"
                  y="52.1748"
                  width="58.65"
                  height="62.1"
                  rx="6.9"
                  fill="white"
                ></rect>
                <path
                  d="M1 113.93H116"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M71.4945 48.38V9.05C71.4945 3.3 69.9995 1 63.4445 1H29.4045C22.8495 1 21.3545 3.3 21.3545 9.05V113.93H50.4495H71.4945"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M100.705 113.93H50.4502V56.4299C50.4502 50.4497 52.7502 48.3799 58.5002 48.3799H92.5402C98.7502 48.3799 100.705 51.0247 100.59 56.4299V113.93H100.705Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M43.5499 10.6597H31.1299V23.0797H43.5499V10.6597Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M62.8702 10.6597H50.4502V23.0797H62.8702V10.6597Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M43.5499 29.5195H31.1299V41.9395H43.5499V29.5195Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M62.8702 29.5195H50.4502V41.9395H62.8702V29.5195Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M43.5499 48.3799H31.1299V60.7999H43.5499V48.3799Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M43.5499 67.2397H31.1299V79.6597H43.5499V67.2397Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M72.0704 56.1997H59.6504V68.6197H72.0704V56.1997Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M91.3897 56.1997H78.9697V68.6197H91.3897V56.1997Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M72.0704 75.0596H59.6504V87.4796H72.0704V75.0596Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M91.3897 75.0596H78.9697V87.4796H91.3897V75.0596Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M72.0704 94.4946H59.6504V106.915H72.0704V94.4946Z"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M91.3897 94.4946H78.9697V106.915H91.3897V94.4946Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M43.5499 113.93H31.1299V94.38C31.1299 91.2751 32.0499 90.585 34.9249 90.585H40.3299C42.9749 90.585 43.4349 91.8501 43.4349 94.38V113.93H43.5499Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <h2 class="companies__item-title">Enterprises</h2>
              <p class="companies__item-subtitle">
                Get a viable product and save money. You don’t have to hire new
                staff our pull developers already working on current projects to
                test market ideas. Just outsource MVP development.
                <br />
                <br />
                Once you’ve made sure that the market idea is solid, we’ll
                seamlessly transfer the project and its code to your team.
              </p>
            </motion.li>
            <motion.li class="companies__item" {...fadeFromdownAnimation(0.4)}>
              <svg
                class="companies__item-svg companies__item-svg--third"
                width="142"
                height="120"
                viewBox="0 0 142 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="38.0928"
                  y="5.19189"
                  width="65.8139"
                  height="24.616"
                  rx="3.5"
                  fill="white"
                ></rect>
                <path
                  d="M51.9128 23.3079C50.8354 23.3079 49.9181 23.0679 49.1608 22.5879C48.4141 22.0972 47.8914 21.4199 47.5928 20.5559L49.1768 19.6279C49.6248 20.8866 50.5528 21.5159 51.9608 21.5159C52.6434 21.5159 53.1608 21.3826 53.5128 21.1159C53.8648 20.8492 54.0408 20.4919 54.0408 20.0439C54.0408 19.5959 53.8541 19.2492 53.4808 19.0039C53.1074 18.7586 52.4461 18.4919 51.4968 18.2039C50.5261 17.9159 49.7421 17.5746 49.1448 17.1799C48.4194 16.6679 48.0568 15.9159 48.0568 14.9239C48.0568 13.9319 48.4088 13.1479 49.1128 12.5719C49.8061 11.9852 50.6541 11.6919 51.6568 11.6919C52.5421 11.6919 53.3208 11.9106 53.9928 12.3479C54.6754 12.7746 55.1981 13.3772 55.5608 14.1559L54.0088 15.0519C53.5394 14.0066 52.7554 13.4839 51.6568 13.4839C51.1234 13.4839 50.6968 13.6119 50.3768 13.8679C50.0568 14.1132 49.8968 14.4492 49.8968 14.8759C49.8968 15.2919 50.0568 15.6226 50.3768 15.8679C50.6968 16.1026 51.2888 16.3532 52.1528 16.6199C52.6861 16.8012 52.9954 16.9079 53.0808 16.9399C53.2194 16.9826 53.4968 17.0892 53.9128 17.2599C54.2541 17.3986 54.5261 17.5479 54.7288 17.7079C55.5608 18.3586 55.9448 19.1266 55.8808 20.0119C55.8808 21.0252 55.5128 21.8306 54.7768 22.4279C54.0621 23.0146 53.1074 23.3079 51.9128 23.3079Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M56.1798 11.8999H64.3398V13.6599H61.1878V23.0999H59.3478V13.6599H56.1798V11.8999Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M74.4198 21.6279C73.2998 22.7479 71.9291 23.3079 70.3078 23.3079C68.6864 23.3079 67.3104 22.7479 66.1798 21.6279C65.0598 20.4972 64.4998 19.1212 64.4998 17.4999C64.4998 15.8786 65.0598 14.5079 66.1798 13.3879C67.3104 12.2572 68.6864 11.6919 70.3078 11.6919C71.9291 11.6919 73.2998 12.2572 74.4198 13.3879C75.5504 14.5079 76.1158 15.8786 76.1158 17.4999C76.1158 19.1212 75.5504 20.4972 74.4198 21.6279ZM67.4758 20.3799C68.2544 21.1372 69.1984 21.5159 70.3078 21.5159C71.4278 21.5159 72.3664 21.1372 73.1238 20.3799C73.8918 19.6119 74.2758 18.6519 74.2758 17.4999C74.2758 16.3479 73.8918 15.3932 73.1238 14.6359C72.3664 13.8679 71.4278 13.4839 70.3078 13.4839C69.1878 13.4839 68.2438 13.8679 67.4758 14.6359C66.7184 15.3932 66.3398 16.3479 66.3398 17.4999C66.3398 18.6412 66.7184 19.6012 67.4758 20.3799Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M86.1941 23.0999H84.1782L81.8102 19.0199H79.6341V23.0999H77.7941V11.8999H82.2741C83.2768 11.8999 84.1248 12.2519 84.8181 12.9559C85.5221 13.6386 85.8741 14.4866 85.8741 15.4999C85.8741 16.2146 85.6661 16.8706 85.2501 17.4679C84.8448 18.0546 84.3115 18.4812 83.6502 18.7479L86.1941 23.0999ZM82.2741 13.6279H79.6341V17.3719H82.2741C82.7648 17.3719 83.1808 17.1906 83.5221 16.8279C83.8635 16.4652 84.0341 16.0226 84.0341 15.4999C84.0341 14.9772 83.8635 14.5346 83.5221 14.1719C83.1808 13.8092 82.7648 13.6279 82.2741 13.6279Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M89.4466 18.3159V21.3399H94.4067V23.0999H87.6066V11.8999H94.3266V13.6599H89.4466V16.5719H93.9267V18.3159H89.4466Z"
                  fill="#5C3BFE"
                ></path>
                <rect
                  x="38.0928"
                  y="5.19189"
                  width="65.8139"
                  height="24.616"
                  rx="3.5"
                  stroke="#5C3BFE"
                ></rect>
                <rect
                  x="16"
                  y="35"
                  width="109"
                  height="73"
                  rx="4.11429"
                  fill="white"
                ></rect>
                <path
                  d="M16.5 44C16.5 38.7533 20.7533 34.5 26 34.5H116C121.247 34.5 125.5 38.7533 125.5 44V109.886C125.5 111.882 123.882 113.5 121.886 113.5H20.1143C18.1182 113.5 16.5 111.882 16.5 109.886V44Z"
                  stroke="#5C3BFE"
                ></path>
                <path
                  d="M63.2959 34H77.7074V48.3205C77.7074 50.0911 77.004 51.7891 75.752 53.0411C74.5001 54.2931 72.802 54.9965 71.0314 54.9965H69.9718C68.2013 54.9965 66.5032 54.2931 65.2512 53.0411C63.9993 51.7891 63.2959 50.0911 63.2959 48.3205V34Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M56.8883 54.4965H56.0198C52.5281 54.4965 49.7694 51.3291 50.1504 47.6537C50.1504 47.6537 50.1504 47.6536 50.1504 47.6536L51.5181 34.5H62.796V48.3205C62.796 51.7476 60.131 54.4965 56.8883 54.4965Z"
                  fill="white"
                  stroke="#5C3BFE"
                ></path>
                <path
                  d="M43.1362 54.9965H42.4177C38.3248 54.9965 35.2916 51.0673 36.1556 46.9062L38.8387 34H51.0673L49.5029 49.0391C49.367 50.6439 48.6408 52.1413 47.4648 53.2417C46.2888 54.3421 44.7465 54.9673 43.1362 54.9965Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M26.9775 34.5H38.2237L35.079 49.6331L35.0789 49.6331L35.0774 49.6412C34.8197 50.9917 34.1043 52.2121 33.0516 53.0966C31.9999 53.9803 30.6759 54.4746 29.3025 54.4965H28.8789C24.8927 54.4965 22.0126 50.4264 23.2669 46.4048L23.267 46.4047L26.9775 34.5Z"
                  fill="white"
                  stroke="#5C3BFE"
                ></path>
                <path
                  d="M15.4092 54.9965C10.8161 54.9965 7.70095 50.0714 9.53365 45.6647L11.8204 40.1623C13.371 36.4313 17.0144 34 21.0547 34H26.61L21.503 50.3715C21.1146 51.6922 20.3133 52.8538 19.2167 53.6861C18.1201 54.5184 16.7858 54.9776 15.4092 54.9965Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M90.8487 47.6532L90.8487 47.6536C91.2298 51.329 88.471 54.4965 84.9794 54.4965H84.1108C80.8681 54.4965 78.2031 51.7476 78.2031 48.3205V34.5H89.4942L90.8487 47.6532Z"
                  fill="white"
                  stroke="#5C3BFE"
                ></path>
                <path
                  d="M97.8628 54.9965H98.5858C102.679 54.9965 105.712 51.0673 104.848 46.9062L102.16 34H89.9453L91.5097 49.0391C91.6454 50.6416 92.3696 52.1371 93.5427 53.2372C94.7159 54.3373 96.2548 54.964 97.8628 54.9965Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M102.775 34.5H114.021L117.731 46.4047L117.731 46.4048C118.986 50.4264 116.106 54.4965 112.119 54.4965H111.696C110.322 54.4746 108.998 53.9802 107.947 53.0966C106.894 52.2121 106.179 50.9917 105.921 49.6412L105.921 49.6411L105.919 49.6331L102.775 34.5Z"
                  fill="white"
                  stroke="#5C3BFE"
                ></path>
                <path
                  d="M125.59 54.9965C130.196 54.9965 133.298 50.0714 131.465 45.6647L129.178 40.1623C127.628 36.4313 123.984 34 119.944 34H114.389L119.505 50.3715C119.893 51.6908 120.693 52.8513 121.787 53.6834C122.882 54.5156 124.215 54.9757 125.59 54.9965Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M111 113H92V83.6896C92 79.0347 93.4075 78 97.8056 78H106.074C110.12 78 110.824 79.8967 110.824 83.6896V113H111Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="1.15"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <rect
                  x="21"
                  y="66"
                  width="56"
                  height="20"
                  rx="4"
                  fill="white"
                ></rect>
                <rect
                  x="20.5"
                  y="66.5"
                  width="57"
                  height="24"
                  rx="3.5"
                  stroke="#5C3BFE"
                ></rect>
                <path
                  d="M67.2133 76.5904C66.987 76.7487 66.932 77.0605 67.0904 77.2867C67.2487 77.513 67.5605 77.568 67.7867 77.4096L67.2133 76.5904ZM67.7867 77.4096L77.7868 70.4095L77.2133 69.5903L67.2133 76.5904L67.7867 77.4096Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M71.6871 76.5955C71.4635 76.7576 71.4136 77.0702 71.5757 77.2938C71.7377 77.5174 72.0504 77.5673 72.274 77.4052L71.6871 76.5955ZM72.274 77.4052L77.7934 73.4048L77.2066 72.5952L71.6871 76.5955L72.274 77.4052Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M30.2629 78.4124C30.4906 78.2563 30.5487 77.945 30.3925 77.7173C30.2364 77.4895 29.9252 77.4315 29.6974 77.5876L30.2629 78.4124ZM29.6974 77.5876L20.2173 84.0874L20.7827 84.9121L30.2629 78.4124L29.6974 77.5876Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M25.7867 78.4091C26.0129 78.2507 26.0679 77.9389 25.9096 77.7127C25.7512 77.4865 25.4395 77.4315 25.2132 77.5898L25.7867 78.4091ZM25.2132 77.5898L20.2133 81.0899L20.7867 81.9091L25.7867 78.4091L25.2132 77.5898Z"
                  fill="#5C3BFE"
                ></path>
                <path d="M46 34.5V29.5" stroke="#5C3BFE"></path>
                <path d="M50 34.5V29.5" stroke="#5C3BFE"></path>
                <path d="M92 35V30" stroke="#5C3BFE"></path>
                <path d="M96 35V30" stroke="#5C3BFE"></path>
              </svg>
              <h2 class="companies__item-title">Small businesses</h2>
              <p class="companies__item-subtitle">
                Have an app ready that suits the needs of your business — and
                can easily be integrated into your existing workflow.
                <br />
                <br />
                You don’t need to get an in-house team to develop a single
                project for your business. Just hire us — we’ll create an app
                quickly.
              </p>
            </motion.li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default CompanySection;
