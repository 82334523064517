import ContactForm from "../../components/Contact/ContactForm";

function index() {
  return (
    <div
      style={{
        backgroundImage:
          "url('https://www.imageshine.in/uploads/gallery/Free-vector-watercolor-background-Wallpaper.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div
        className="container p-0 p-sm-4"
        style={{
          borderRadius: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* create a card container with good shadow and looks */}
        <div
          className="card p-0 m-3 p-sm-4 m-sm-0 w-sm-100"
          style={{
            borderRadius: "20px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            transition: "0.3s",
            background: "papayawhip",
          }}
        >
          <div className="card-body">
            <h3
              className="card-title mb-4"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#013493",
                textTransform: "uppercase",
                letterSpacing: "1px",
              }}
            >
              Book Appointment
            </h3>
            <hr />
            <p className="card-text" style={{ textAlign: "center" }}>
              Please fill out the form below to book an appointment
            </p>

            <hr />

            <div
              className="rs-contact mod1 "
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="contact-widget">
                <ContactForm background="papayawhip" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default index;
