import SectionTitle from "../../components/Common/SectionTitle";

// WhyChooseUs Images
import { motion } from "framer-motion";
import iconOne from "../../assets/img/choose/icons/style2/1.png";
import iconTwo from "../../assets/img/choose/icons/style2/2.png";
import iconThree from "../../assets/img/choose/icons/style2/3.png";
import ContactForm from "../../components/Contact/ContactForm";

function Contact2() {
  const fadeUpAnimation = {
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
  };

  return (
    <div className="rs-why-choose style2 gray-bg rs-rain-animate pt-120 pb-120 md-pt-70 md-pb-80">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 md-mb-30">
            <SectionTitle
              sectionClass="sec-title mb-40"
              subtitleClass="sub-text style4-bg left"
              subtitle="Let's Talk"
              titleClass="title pb-20"
              title="Speak With Expert Engineers."
              descClass="desc fixed-width"
              description="Connect with our expert engineers to discuss your tech needs. Book an appointment and let's collaborate!"
            />
            <motion.div className="services-wrap mb-24" {...fadeUpAnimation}>
              <div className="services-icon">
                <img src={iconOne} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title">
                  <a href="mailto:connect@urbanoinfotech.com">Email</a>
                </h3>
                <p className="services-txt">connect@urbanoinfotech.com</p>
              </div>
            </motion.div>
            <motion.div
              className="services-wrap mb-24"
              {...fadeUpAnimation}
              transition={{
                delay: 0.2,
              }}
            >
              <div className="services-icon">
                <img src={iconTwo} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title">
                  <a href="tel:+918107115738">Call Us</a>
                </h3>
                <p className="services-txt">(+91) 8107115738</p>
              </div>
            </motion.div>
            <motion.div
              className="services-wrap"
              {...fadeUpAnimation}
              transition={{
                delay: 0.4,
              }}
            >
              <div className="services-icon">
                <img src={iconThree} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title">
                  <a href>Office Address</a>
                </h3>
                <p className="services-txt">
                  15, Pallavi Row House, opp. Memnagar<br></br> Navrangpura,
                  Ahmedabad{" "}
                </p>
              </div>
            </motion.div>
          </div>
          <div
            className="col-xl-6 col-lg-6 "
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="rs-contact mod1 w-100 ">
              <div className="contact-widget">
                <ContactForm background="#F6F7F9" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-inner">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
}
export default Contact2;
