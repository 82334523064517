import React from "react";
import Footer from "../../components/Layout/Footer";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Contact2 from "./Contact2";

const Contact = () => {
  return (
    <React.Fragment>
      {/* Header */}
      <HeaderStyleThree parentMenu="contact" />
      {/* Header */}

      {/* ContactMain Section Start */}
      {/* <ContactMain /> */}
      {/* ContactMain Section End */}

      <Contact2 />

      {/* Footer */}
      <Footer />
      {/* Footer */}

      {/* SearchModal */}
      <SearchModal />
      {/* SearchModal */}
    </React.Fragment>
  );
};

export default Contact;
