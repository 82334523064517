import SectionTitle from "../../components/Common/SectionTitle";

import { motion } from "framer-motion";

const Technology = ({ data }) => {
  const fadeUpAnimation = {
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
  };

  return (
    <div
      className="rs-technology  w-full"
      style={{
        width: "100%",
      }}
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title2 text-center mb-44"
          subtitleClass="sub-text gold-color"
          subtitle={data.sub_heading}
          titleClass="title title2"
          title={data.heading}
        />
        <div className="row d-flex align-items-center justify-content-center">
          {data.logo_heading_logo_container?.map((val, i) => (
            <motion.div
              className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 h-full d-flex justify-content-center pb-3 pb-sm-0"
              key={i}
              {...fadeUpAnimation}
            >
              <div className="technology-item">
                <div className="logo-img">
                  <img src={val.icon_image_url} alt="" />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Technology;
