import { motion, useInView } from "framer-motion";
import React, { useEffect, useRef } from "react";
import SingleService from "../../components/Service/SingleService";

import axios from "axios";
import Loader from "../../components/Common/Loader";

const BACKEND_API = process.env.REACT_APP_BACKEND_BASEURL;

const ServiceTwo = () => {
  const [data, setData] = React.useState(null);

  const getServiceData = async () => {
    try {
      const resp = await axios.get(`${BACKEND_API}/services/`);
      setData(resp.data);
    } catch (e) {
      setData(null);
    }
  };

  useEffect(() => {
    getServiceData();
  }, []);

  const ref = useRef(null);
  const isInView = useInView(ref);

  if (!data) {
    return <Loader />;
  }

  return (
    <div className="rs-services main-home style1 pt-100 md-pt-70 pb-100 md-pb-70 ">
      <div className="container">
        <div className="row justify-content-center " ref={ref}>
          {data.map((value, index) => {
            return (
              <motion.div
                className="col-lg-3 col-md-6 mb-4"
                key={value.Title}
                initial={{
                  y: 100,
                  opacity: 0,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  delay: (index + 1) * 0.2,
                }}
                viewport={{
                  once: true,
                }}
              >
                <SingleService
                  serviceImage={value.icon_image_url}
                  serviceURL={value.service_url || "cloud-security"}
                  Title={value.heading}
                  Text={value.small_description}
                  itemClass="services-item"
                  index={index}
                />
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ServiceTwo;
