import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef } from "react";
import Technology from "../../components/Technology";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

function ComparisonSection() {
  const fadeFromdownAnimation = (delay = 0) => ({
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
    transition: {
      // delay:1
      delay,
      duration: 0.7,
    },
  });

  const data = [
    {
      heading: "Low Code",
      description:
        "Unleash the power of rapid application development with Low Code platforms, where the emphasis is on streamlining complex processes. These platforms empower developers to create applications swiftly by providing visual interfaces and pre-built components. By minimizing hand-coding efforts, Low Code accelerates development cycles, making it an excellent choice for projects prioritizing efficiency and a balanced trade-off between speed and customization. The result is a more agile development process, reducing time-to-market for crucial applications.",
      side_image_url:
        "https://106714.fs1.hubspotusercontent-na1.net/hubfs/106714/Low%20Code%20Citizen%20Developer.jpg",
    },
    {
      heading: "No Code",
      description:
        "Revolutionize the development landscape with No Code platforms that democratize the creation of applications. Breaking free from the traditional coding paradigm, No Code empowers individuals with limited coding expertise to actively participate in application development. Through user-friendly interfaces, non-developers can design and deploy functional applications, fostering collaboration across diverse teams. This accessibility promotes innovation and agility, making application development an inclusive endeavor that goes beyond traditional boundaries.",
      side_image_url:
        "https://www.practicalecommerce.com/wp-content/uploads/2022/07/No-code-Apps-Are-Everywhere.jpg",
    },
    {
      heading: "Custom Software Development",
      description:
        "For projects demanding unparalleled flexibility and tailored solutions, custom software development remains the gold standard. This approach involves creating applications from scratch, allowing for precise customization to meet unique business requirements. While it may require more time compared to Low Code or No Code solutions, custom development provides the highest degree of control over the application's functionality and design. It is the ideal choice for businesses seeking fully bespoke applications that align precisely with their strategic goals and operational nuances.",
      side_image_url:
        "https://www.6degreesit.com/wp-content/uploads/2019/03/custom-app-development.png",
    },
  ];

  return (
    <section class="companies">
      <div class="container">
        <div class="companies__inner">
          <motion.h2
            class="companies__title block-title"
            {...fadeFromdownAnimation()}
          >
            <span class="companies__title--stroke-text">
              Verity Of Services
            </span>
            <br />
            Different ways to Develop a MVP
          </motion.h2>
          <p class="companies__subtitle">
            Choose between Low Code, No Code or Custome Development to build
            your mvp your way
          </p>
          {/* <ul class="companies__wrapper"> */}

          <div className=" gap-4 mt-40 " id="rs-service">
            {data?.map((item, index) => (
              <Content index={index} item={item} />
            ))}
          </div>
          {/* </ul> */}
        </div>
      </div>
    </section>
  );
}

export default ComparisonSection;

function Content({ index, item }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, 300);

  const techData = {
    heading: "To Provide Best Solution",
    sub_heading: "Plateform We Use",
    logo_heading_logo_container: [
      {
        icon_image_url:
          "https://assets.stickpng.com/images/6303a615ab1b900654aad3c9.png",
      },
      {
        icon_image_url:
          "https://upload.wikimedia.org/wikipedia/commons/5/51/Webflow_logo_2023.svg",
      },
      {
        icon_image_url:
          "https://1000logos.net/wp-content/uploads/2022/05/Airtable-Logo.png",
      },
      {
        icon_image_url:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Bubble_Logo_no_code.svg/2560px-Bubble_Logo_no_code.svg.png",
      },
      {
        icon_image_url:
          "https://avatars.githubusercontent.com/u/2916417?s=280&v=4",
      },
    ],
  };

  const fadeUpAnimation = {
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
  };

  return (
    <motion.div
      className="container gap-4 p-1 rounded mb-40 "
      style={{
        position: "relative",
      }}
    >
      {/* <motion.div
      ref={ref}
      style={{
        position: "absolute",
        top: 0,
        height: "100%",
        width: "100%",
        backgroundColor: index % 2 === 0 ? "green" : "red",
        y
      }}
    >
    </motion.div> */}

      <div className={"row align-items-center w-100 gap-4 flex-nowrap"}>
        <div
          className={`col-lg-6 pr-34 md-pr-14 mr-8 ${
            index % 2 === 0 ? "order-last" : ""
          }`}
          style={{
            paddingLeft: index % 2 === 0 ? 16 : 0,
            paddingRight: index % 2 === 0 ? 0 : 16,
          }}
        >
          <div className="blog-details pr-14">
            <div className="blog-full">
              <motion.h3
                {...fadeUpAnimation}
                transition={{
                  duration: 1,
                  delay: 0.3,
                }}
              >
                {item.heading}
              </motion.h3>
              <motion.p
                className="mb-30"
                style={{
                  whiteSpace: "pre-line",
                  textAlign: "justify",
                }}
                {...fadeUpAnimation}
                transition={{
                  duration: 1,
                  delay: 0.3 * 2,
                }}
              >
                {item?.description}
              </motion.p>
            </div>
            <div className="bs-img mb-30"></div>
          </div>
        </div>
        <motion.div
          className={`col-lg-6 pr-34 md-pr-14 ${
            index % 2 === 0 ? "order-1" : ""
          }`}
          {...fadeUpAnimation}
          transition={{
            duration: 1,
            delay: 0.3,
          }}
        >
          <img src={item.side_image_url} alt="Middle" />
        </motion.div>
      </div>

      {index !== 2 && (
        <div className="mt-50">
          <Technology data={techData} />
        </div>
      )}
    </motion.div>
  );
}
