import {
  faCalendarAlt,
  faCheckCircle,
  faGlobe,
  faUserClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import dateFns from "date-fns";
import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { ThemeProvider } from "styled-components";
import DayTimePicker from "../Common/Calendar";
import { PopupClose } from "../Common/Calendar/Popup";
import SectionTitle from "../Common/SectionTitle";

const BACKEND_API = process.env.REACT_APP_BACKEND_BASEURL;

const ContactForm = (props) => {
  const { submitBtnClass, background } = props;

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [timezone, setTimezone] = React.useState(null);
  const [formData, setFormData] = React.useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    console.log(timezone);

    try {
      let payload = {
        name: e.target.name.value,
        email: e.target.email.value,
        phone_number: e.target.phone.value,
        message: e.target.message.value,
        date: selectedDate,
        timezone: timezone.value,
        timezone_name: timezone.altName,
      };

      const resp = await axios.post(`${BACKEND_API}/contact-us/`, payload);

      if (resp.status === 201) {
        setIsSubmitted(true);
        setIsSubmitting(false);
        setFormData(payload);
      }
    } catch (e) {
      console.log(e);
      setIsSubmitting(false);
    }
  };

  const theme = {
    primary: "#013493",
    secondary: "#4E95ED",
    background: background ?? "#fff", // This should match the container background
    buttons: {
      disabled: {
        color: "#333",
        background: "#f0f0f0",
      },
      confirm: {
        color: "#fff",
        // background: "#03228f",
        hover: {
          color: "",
          background: "#032280",
        },
      },
    },
    feedback: {
      success: {
        color: "#29aba4",
      },
      failed: {
        color: "#eb7260",
      },
    },
  };

  return (
    <form
      id="contact-form"
      action="#"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!isSubmitted && (
        <SectionTitle
          sectionClass="sec-title2 mb-sm-40"
          subtitleClass="sub-text contact mb-14"
          subtitle={
            !selectedDate ? "Select a Date and Time" : "Fill The Form Below"
          }
          titleClass="title testi-title"
          // title={!selectedDate ? "Select a Date and Time" : "Fill The Form Below"}
        />
      )}

      <AnimatePresence>
        {!selectedDate && (
          <motion.div
            initial={{
              x: 100,
              opacity: 0,
              width: 0,
              height: "auto",
            }}
            animate={{
              x: 0,
              opacity: 1,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            exit={{
              x: -100,
              opacity: 0,
              width: 0,
              height: 0,
              display: "none",
            }}
            transition={{
              duration: 0.7,
            }}
            className=" justify-content-center align-items-center w-100"
          >
            <div className="w-100">
              <DayTimePicker
                timeSlotSizeMinutes={30}
                onConfirm={(date, timezone) => {
                  // set the selected date
                  console.log(timezone);
                  setSelectedDate(date);
                  setTimezone(timezone);
                }}
                confirmText={"Fill Contact Details"}
                theme={theme}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {selectedDate && !isSubmitted && !isSubmitting && (
        <div className="row justify-content-between align-items-center px-3 w-100">
          <div
            style={{
              textAlign: "left",
            }}
          >
            <p>
              <FontAwesomeIcon icon={faCalendarAlt} />{" "}
              {dateFns.format(selectedDate, "hh:mm A, dddd, MMMM Do, YYYY")}
            </p>
            <p>
              <FontAwesomeIcon icon={faUserClock} /> Duration : 30 minutes
            </p>

            <p>
              <FontAwesomeIcon icon={faGlobe} /> Timezone: {timezone.altName}
            </p>
          </div>

          <p>
            <ThemeProvider theme={theme}>
              <PopupClose
                disabled={false}
                onClick={() => {
                  setSelectedDate(null);
                }}
              >
                &#8592;
              </PopupClose>
            </ThemeProvider>
          </p>
        </div>
      )}
      <AnimatePresence>
        {selectedDate && !isSubmitted && !isSubmitting && (
          <motion.div
            className="row h-100"
            initial={{
              x: 100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{
              x: -100,
              opacity: 0,
            }}
            transition={{
              duration: 0.7,
            }}
          >
            <div className="col-md-6 mb-30">
              <input
                className="from-control"
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                required
              />
            </div>

            <div className="col-md-6 mb-30">
              <input
                className="from-control"
                type="text"
                id="phone"
                name="phone"
                placeholder="Phone Number"
                required
              />
            </div>
            <div className="col-md-12 mb-30">
              <input
                className="from-control"
                type="text"
                id="email"
                name="email"
                placeholder="E-Mail"
                required
              />
            </div>

            <div className="col-12 mb-30">
              <textarea
                className="from-control"
                id="message"
                name="message"
                placeholder="Your message Here"
                required
              ></textarea>
            </div>
          </motion.div>
        )}
        {selectedDate && !isSubmitting && !isSubmitted && (
          <div className="btn-part">
            <button
              className={
                submitBtnClass ? submitBtnClass : "readon learn-more submit"
              }
              type="submit"
            >
              Submit Now
            </button>
          </div>
        )}
      </AnimatePresence>
      {selectedDate && isSubmitting && !isSubmitted && (
        <div
          className="row w-50 "
          style={{
            backgroundColor: "#fafafa",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            alignSelf: "center",
            marginInline: "auto",
          }}
        >
          <div className="col-12 mb-30 ">
            {/* show a loader */}

            <div
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p className="spinner-border text-primary" role="status"></p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Please wait...
              </p>
            </div>
            <p
              style={{
                textAlign: "center",
              }}
            >
              We are scheduling your meeting.
            </p>
          </div>
        </div>
      )}

      {selectedDate && isSubmitted && (
        <div
          className="row  "
          style={{
            backgroundColor: "#fafafa",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            alignSelf: "center",
            marginInline: "auto",
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="col-12 mb-30 ">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                textAlign: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="green"
                style={{
                  marginRight: "10px",
                }}
              />
              You are scheduled.
            </p>
            <p
              style={{
                textAlign: "center",
              }}
            >
              {/* show a message saying that you will get an email */}
              You will get an email with the meeting details.
            </p>

            <p>
              <FontAwesomeIcon
                style={{ fontSize: 16, fontWeight: "bold" }}
                icon={"user"}
              />{" "}
              {formData.name}
            </p>
            <p>
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faCalendarAlt} />{" "}
              {dateFns.format(selectedDate, "hh:mm A, dddd, MMMM Do, YYYY")}
            </p>
            <p>
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faUserClock} />{" "}
              Duration : 30 minutes
            </p>
            <p>
              <FontAwesomeIcon style={{ fontSize: 16 }} icon={faGlobe} />{" "}
              Timezone: {timezone.altName}
            </p>
          </div>
        </div>
      )}
    </form>
  );
};

export default ContactForm;
