import dateFns from "date-fns";
import moment from "moment";

function generateTimeSlots(selectedDate, slotSizeMinutes, timezoneOffset) {
  console.log("selectedDate ", selectedDate);
  const isToday = dateFns.isToday(selectedDate);

  let start;

  if (isToday) {
    const now = moment().utcOffset(timezoneOffset);
    const currentHour = now.hour();
    const currentMinute = now.minute();

    // Find the next time slot after the current time
    let nextHour = currentHour;
    let nextMinute = currentMinute;

    if (currentMinute >= 30) {
      nextMinute = 0;
      nextHour++;
    } else {
      nextMinute = 30;
    }

    start = moment(selectedDate).set({
      hour: nextHour,
      minute: nextMinute,
    });

    while (start <= now) {
      start.add(slotSizeMinutes, "minutes");
    }
  } else {
    start = moment(selectedDate).set({ hour: 7, minute: 30 });
  }

  const end = moment(selectedDate).set({ hour: 22 });

  let slot = start;
  let timeSlots = [];
  while (slot < end) {
    let offsetedSlot = moment(slot).utcOffset(timezoneOffset);
    console.log(offsetedSlot);
    timeSlots.push(offsetedSlot.format("YYYY-MM-DD HH:mm:ss"));
    slot.add(slotSizeMinutes, "minutes");
  }

  return timeSlots;
}

export default generateTimeSlots;
