import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

const SingleService = (props) => {
  return (
    <Link to={props.serviceURL}>
      <div className={props.itemClass}>
        <div className="services-icon">
          <div className="image-part">
            <img src={props.serviceImage} alt={props.Title} />
          </div>
        </div>
        <div className={props.shapeCall}>{props.shapeImg}</div>
        <div className="services-content">
          <motion.div
            className="services-text"
            initial={{
              opacity: 0,
              y: 50,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 1,
              delay: 0.3 * props.index,
            }}
          >
            <h3 className="services-title">
              <Link to={props.serviceURL}>{props.Title}</Link>
            </h3>
          </motion.div>
          <motion.div
            className="services-desc"
            initial={{
              opacity: 0,
              y: 50,
            }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 1.5,
              delay: 0.2 * props.index,
            }}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: props.Text,
              }}
            ></p>
          </motion.div>
        </div>
      </div>
    </Link>
  );
};

export default SingleService;
