import { motion } from "framer-motion";
import React from "react";

import BannerHomeEleven from "../../components/Banner/BannerHomeEleven";
import ScrollToTop from "../../components/Common/ScrollTop";
import Technology from "../../components/Technology";
import Process2 from "./ProcessSection2";
import Service from "./ServiceSection";

// Service Image Icons
import imgMiddle1 from "../../assets/img/blog/inner/3.jpg";
import Contact2 from "../contact/Contact2";

const ServiceMain = ({ data }) => {
  const fadeUpAnimation = {
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
  };

  return (
    <React.Fragment>
      {/* banner-start */}
      <BannerHomeEleven
        heading={data?.heading}
        small_description={data?.small_description}
        exponent_heading={data?.exponent_heading}
        banner_image_url={data?.banner_image_url}
      />
      {/* banner-start */}

      <div className="row gap-4 mt-40" id="rs-service">
        {data.servicesubcategory_service?.map((item, index) => (
          <div className="container gap-4 p-5 rounded mb-40 ">
            <div className="row ">
              <div className={"row  align-items-center "}>
                <div
                  className={`col-lg-6 pr-34 md-pr-14 ${
                    index % 2 === 0 ? "order-last" : ""
                  }`}
                >
                  <div className="blog-details">
                    <div className="blog-full">
                      <motion.h3
                        {...fadeUpAnimation}
                        transition={{
                          duration: 1,
                          delay: 0.3,
                        }}
                      >
                        {item.heading}
                      </motion.h3>
                      <motion.p
                        className="mb-30"
                        style={{
                          textAlign: "justify",
                        }}
                        {...fadeUpAnimation}
                        transition={{
                          duration: 1,
                          delay: 0.3 * 2,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item?.description,
                        }}
                      ></motion.p>
                    </div>
                    <div className="bs-img mb-30"></div>
                  </div>
                </div>
                <motion.div
                  className={`col-lg-6 pr-34 md-pr-14  ${
                    index % 2 === 0 ? "order-1" : ""
                  }`}
                  {...fadeUpAnimation}
                  transition={{
                    duration: 1,
                    delay: 0.3,
                  }}
                >
                  <img src={item.side_image_url || imgMiddle1} alt="Middle" />
                </motion.div>
              </div>
              <Process2 data={item.accordian_container_subservice[0]} />

              {item.logo_subcategory?.length > 0 && (
                <Technology data={item.logo_subcategory[0]} />
              )}
            </div>
          </div>
        ))}
      </div>

      {data.service_card_container_service.length > 0 && (
        <Service data={data.service_card_container_service[0]} />
      )}

      {/* About-area-start */}

      {/* {
        serviceAbout[service] &&
        <About {...serviceAbout[service]} />
      } */}

      {/* About-area-end */}

      <Contact2 />

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default ServiceMain;
