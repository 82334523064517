function Loader() {
  return (
    <div className="d-flex justify-content-center align-items-center vw-100 vh-100">
      <div className="d-flex flex-column justify-content-center align-items-center gap-3 ">
        <div
          className="position-relative"
          style={{
            width: "100px",
            height: "100px",
          }}
        >
          <div
            class="position-absolute text-primary spinner-grow "
            role="status"
            style={{
              width: "100px",
              height: "100px",
              top: "0px",
              left: "0px",
              animationDelay: "0s",
              animationDuration: "2s",
              borderColor: "transparent!important",
            }}
          ></div>
          <div
            class="position-absolute text-primary spinner-grow "
            role="status"
            style={{
              width: "75px",
              height: "75px",
              top: "12.5px",
              left: "12.5px",
              animationDelay: "0.33s",
              animationDuration: "2s",
              borderColor: "transparent!important",
            }}
          ></div>
          <div
            class="position-absolute text-primary spinner-grow "
            role="status"
            style={{
              width: "50px",
              height: "50px",
              top: "25px",
              left: "25px",
              animationDelay: "0.66s",
              animationDuration: "2s",
              borderColor: "transparent!important",
            }}
          ></div>
        </div>

        <h2>Loading wait...</h2>
      </div>
    </div>
  );
}
export default Loader;
