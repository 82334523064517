import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import bgImg from "../../assets/img/bg/home-11.jpg";

const bgStyle = (bgImg) => ({
  background: "blue",
  backgroundImage: `url(${bgImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "cover",
});

const BannerHomeEleven = ({
  heading,
  exponent_heading,
  small_description,
  banner_image_url,
}) => {
  const fadeUpAnimation = {
    initial: {
      opacity: 0,
      y: 50,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <React.Fragment>
      {/* dynamic banner section start */}

      <motion.div
        className="rs-banner style7 modify3 rs-rain-animate "
        style={bgStyle(banner_image_url || bgImg)}
        initial={{
          borderRadius: 100,
          scale: 0.5,
          opacity: 0,
          y: 50,
        }}
        whileInView={{
          borderRadius: 0,
          scale: 1,
          opacity: 1,
          y: 0,
        }}
        transition={{
          delay: 1 * 0.2,
        }}
        viewport={{
          once: true,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-content">
                <motion.span
                  className="sub-title zIndex1"
                  {...fadeUpAnimation}
                  transition={{
                    duration: 1,
                    delay: 0.3,
                  }}
                >
                  {exponent_heading}
                </motion.span>
                <motion.h2
                  className="title zIndex1"
                  data-animation="fadeInLeft"
                  data-delay=".3s"
                  {...fadeUpAnimation}
                  transition={{
                    duration: 1,
                    delay: 0.3 * 2,
                  }}
                >
                  {heading}
                </motion.h2>
                <motion.p
                  className="desc zIndex1"
                  data-animation="fadeInLeft"
                  data-delay=".5s"
                  {...fadeUpAnimation}
                  transition={{
                    duration: 1,
                    delay: 0.3 * 3,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: small_description,
                  }}
                ></motion.p>
                <ul className="banner-btn zIndex1">
                  <li>
                    <Link to="/about" className="readon started">
                      Free Consultation
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="line-inner style2 orange-dark">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </motion.div>
    </React.Fragment>
  );
};

export default BannerHomeEleven;
