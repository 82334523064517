import { motion } from "framer-motion";
import React from "react";

function MethodsSection() {
  const fadeFromdownAnimation = (delay = 0) => ({
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
    transition: {
      // delay:1
      delay,
      duration: 0.7,
    },
  });

  return (
    <section class="methods">
      <div class="container">
        <div class="methods__inner">
          <motion.h2
            class="methods__title block-title"
            {...fadeFromdownAnimation()}
          >
            Engagement Models
          </motion.h2>
          <motion.p class="methods__subtitle" {...fadeFromdownAnimation(0.2)}>
            At Urbano Infotech, there’re two ways we can help with development.
            Choose which one works best with your idea, or contact us if you
            need help with deciding.
          </motion.p>
          <div class="methods__wrapper">
            <motion.div class="methods__item" {...fadeFromdownAnimation()}>
              <svg
                class="methods__item-svg"
                width="130"
                height="116"
                viewBox="0 0 130 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M51.5891 112.523C58.6658 109.746 63.7307 104.025 65.8904 96.7611C66.7979 93.7379 66.2844 89.785 68.9391 85.081C71.6248 80.2931 80.2793 77.8787 82.931 77.0772C96.0378 73.0462 109.711 66.767 115.844 57.0136C117.725 53.9637 125.5 44.1099 117.214 24.7603C114.531 18.5789 109.584 14.0279 104.414 10.6547C102.48 9.23158 100.393 8.08026 98.2102 7.32822C92.1519 4.7635 85.5335 3.70868 78.8715 4.21417C69.0152 4.96405 59.4188 7.32128 50.9321 11.4471C33.135 20.0441 18.8176 34.1181 12.1212 52.8911C8.00992 64.4066 7.35273 77.2851 12.1083 89.9967C18.1713 106.252 35.757 118.852 51.5891 112.523Z"
                  fill="#EEEBFF"
                ></path>
                <path
                  d="M84.9014 86.9741H105.901V107.974H84.9014V86.9741Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M63.9014 86.9741H84.9014V107.974H63.9014V86.9741Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M42.9014 86.9741H63.9014V107.974H42.9014V86.9741Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M63.9014 65.9741H84.9014V86.9741H63.9014V65.9741Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M105.901 65.9742V86.9741H84.9014V65.9742H105.901Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M84.9014 44.9742V65.9741H63.9014V44.9742H84.9014Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M105.901 23.9742V44.9741H84.9014V23.9742H105.901Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M105.901 44.9742V65.9741H84.9014V44.9742H105.901Z"
                  fill="white"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M57.1628 63.4738L43.6631 49.9741L30.1634 63.4739L43.6631 76.9736L57.1628 63.4738Z"
                  fill="#5C3BFE"
                  stroke="#5C3BFE"
                  stroke-width="1.10526"
                  stroke-miterlimit="10"
                ></path>
              </svg>
              <div class="methods__item-column">
                <p class="methods__item-title">
                  Outstaffing to a dedicated development team
                </p>
                <p class="methods__item-text">
                  If you don’t have enough developers to make your business idea
                  real, we can provide you with our own. You won’t have to worry
                  about managing or onboarding them — they’re going to build a
                  solid product while you can focus on other affairs.
                </p>
                <a
                  class="methods__btn--first methods__btn rising-btn rising-btn--theme3 js-scroll-to"
                  href="/services/outstaffing/"
                >
                  <span class="rising-btn__inner">
                    <span class="rising-btn__wrapper">
                      <span class="rising-btn__text first-face__btn-text">
                        Learn more
                      </span>
                    </span>
                  </span>
                </a>
              </div>
            </motion.div>
            <motion.div class="methods__item" {...fadeFromdownAnimation(0.2)}>
              <svg
                class="methods__item-svg"
                width="130"
                height="116"
                viewBox="0 0 130 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1998 78.2874C16.9487 83.2616 24.368 85.0864 31.8036 83.6211C34.902 83.019 38.1631 80.7266 43.5619 80.8899C49.0496 81.0417 55.2109 87.5814 57.1533 89.5565C66.8158 99.2865 78.7321 108.473 90.2187 109.368C93.7932 109.615 106.132 111.918 119.411 95.5855C123.636 90.336 125.365 83.8404 125.948 77.695C126.309 75.3216 126.358 72.9378 126.009 70.656C125.463 64.0999 123.32 57.7499 119.775 52.0866C114.529 43.709 107.98 36.3087 100.382 30.7133C84.4962 18.9536 65.3793 12.821 45.6452 15.6203C33.5386 17.3342 21.8311 22.7399 12.7878 32.8602C1.21495 45.7855 -1.76434 67.213 11.1998 78.2874Z"
                  fill="#EEEBFF"
                ></path>
                <path
                  d="M33.333 15.0801C33.333 11.7219 36.0481 8.99951 39.3974 8.99951H83.3006C86.6499 8.99951 89.365 11.7219 89.365 15.0801V105.919C89.365 109.277 86.6499 112 83.3006 112H39.3974C36.0481 112 33.333 109.277 33.333 105.919V15.0801Z"
                  fill="white"
                  stroke="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M48.7786 15.5083C48.7786 14.644 48.0783 13.9434 47.2144 13.9434H42.97C40.3783 13.9434 38.2773 16.0452 38.2773 18.6381V102.361C38.2773 104.953 40.3783 107.055 42.97 107.055H79.7287C82.3204 107.055 84.4213 104.953 84.4213 102.361V18.6381C84.4213 16.0452 82.3204 13.9434 79.7287 13.9434H75.3701C74.5062 13.9434 73.8059 14.644 73.8059 15.5083V16.5171C73.8059 17.9202 72.6689 19.0576 71.2664 19.0576H51.3181C49.9156 19.0576 48.7786 17.9202 48.7786 16.5171V15.5083Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M46.6846 33.7959H76.2494"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M46.333 40.9995H67.333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M73.333 40.9995H75.333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <circle
                  cx="54.7576"
                  cy="13.9437"
                  r="1.64825"
                  fill="#5C3BFE"
                ></circle>
                <circle cx="84.333" cy="72.9995" r="23" fill="white"></circle>
                <path
                  d="M84.3482 73.0146H73.7679H66.5928H62.4883H61.333"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3178 73.0146H94.8981H102.073H106.178H107.333"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3482 73.0146H73.7679H66.5928H62.4883H61.333"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3178 73.0146H94.8981H102.073H106.178H107.333"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3482 96.0298C81.6727 96.0298 79.0884 95.5738 76.6866 94.7225C75.2881 94.236 73.9807 93.628 72.7342 92.8983C71.8829 92.3814 71.062 91.8342 70.2715 91.2261C68.721 90.0404 67.3528 88.6419 66.1671 87.1217C65.559 86.3312 65.0118 85.5103 64.4949 84.659C62.4883 81.2539 61.333 77.2711 61.333 73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3178 96.0298C86.9933 96.0298 89.5776 95.5738 91.9794 94.7225C93.378 94.236 94.6853 93.628 95.9318 92.8983C96.7831 92.3814 97.604 91.8342 98.3945 91.2261C99.945 90.0404 101.313 88.6419 102.499 87.1217C103.107 86.3312 103.654 85.5103 104.171 84.659C106.178 81.2539 107.333 77.2711 107.333 73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3482 49.9995C81.6727 49.9995 79.0884 50.4555 76.6866 51.3068C75.2881 51.7933 73.9807 52.4013 72.7342 53.131C71.8829 53.6479 71.062 54.1951 70.2715 54.8032C68.721 55.9889 67.3528 57.3874 66.1671 58.9076C65.559 59.6981 65.0118 60.519 64.4949 61.3703C62.4883 64.7754 61.333 68.7582 61.333 73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3178 49.9995C86.9933 49.9995 89.5776 50.4555 91.9794 51.3068C93.378 51.7933 94.6853 52.4013 95.9318 53.131C96.7831 53.6479 97.604 54.1951 98.3945 54.8032C99.945 55.9889 101.313 57.3874 102.499 58.9076C103.107 59.6981 103.654 60.519 104.171 61.3703C106.178 64.7754 107.333 68.7582 107.333 73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M73.7676 73.0146C73.7676 76.6934 74.0108 80.0682 74.3756 83.0173C74.6797 85.6319 75.0749 87.8818 75.4398 89.706C75.835 91.6214 76.1998 93.0807 76.4126 93.9016C76.5343 94.388 76.6255 94.6617 76.6559 94.7225"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M94.8984 73.0146C94.8984 76.6934 94.6552 80.0682 94.2904 83.0173C93.9863 85.6319 93.5911 87.8818 93.2263 89.706C92.831 91.6214 92.4662 93.0807 92.2534 93.9016C92.1318 94.388 92.0405 94.6617 92.0101 94.7225"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M73.7676 73.0146C73.7676 69.3359 74.0108 65.9611 74.3756 63.012C74.6797 60.3974 75.0749 58.1475 75.4398 56.3233C75.835 54.4079 76.1998 52.9486 76.4126 52.1277C76.5343 51.6413 76.6255 51.3676 76.6559 51.3068"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M94.8984 73.0146C94.8984 69.3359 94.6552 65.9611 94.2904 63.012C93.9863 60.3974 93.5911 58.1475 93.2263 56.3233C92.831 54.4079 92.4662 52.9486 92.2534 52.1277C92.1318 51.6413 92.0405 51.3676 92.0101 51.3068"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M66.0254 73.0146C66.0254 76.0239 66.3129 78.6855 66.9966 81.8712C67.5618 84.5046 68.0742 86.0185 68.6176 87.5514C69.161 89.0844 70.2105 91.0741 70.2409 91.1957"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M102.641 73.0146C102.641 76.0239 102.353 78.6855 101.669 81.8712C101.104 84.5046 100.592 86.0185 100.048 87.5514C99.505 89.0844 98.4555 91.0741 98.4251 91.1957"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M66.0254 73.0146C66.0254 70.0054 66.3129 67.3438 66.9966 64.1581C67.5618 61.5247 68.0742 60.0108 68.6176 58.4778C69.161 56.9449 70.2105 54.9552 70.2409 54.8336"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M102.641 73.0146C102.641 70.0054 102.353 67.3438 101.669 64.1581C101.104 61.5247 100.592 60.0108 100.048 58.4778C99.505 56.9449 98.4555 54.9552 98.4251 54.8336"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M62.6406 80.6763C62.6406 80.6763 62.9751 80.7979 63.6135 80.9803C64.4344 81.2235 65.8025 81.5884 67.6267 81.9532C69.4205 82.318 71.7008 82.7133 74.3762 83.0173C77.2341 83.3517 80.5785 83.5646 84.3485 83.5646"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M106.025 80.6763C106.025 80.6763 105.691 80.7979 105.052 80.9803C104.232 81.2235 102.863 81.5884 101.039 81.9532C99.2455 82.318 96.9653 82.7133 94.2898 83.0173C91.4319 83.3517 88.0875 83.5646 84.3176 83.5646"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M62.6406 65.353C62.6406 65.353 62.9751 65.2314 63.6135 65.049C64.4344 64.8058 65.8025 64.4409 67.6267 64.0761C69.4205 63.7113 71.7008 63.316 74.3762 63.012C77.2341 62.6775 80.5785 62.4647 84.3485 62.4647"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M106.025 65.353C106.025 65.353 105.691 65.2314 105.052 65.049C104.232 64.8058 102.863 64.4409 101.039 64.0761C99.2455 63.7113 96.9653 63.316 94.2898 63.012C91.4319 62.6775 88.0875 62.4647 84.3176 62.4647"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M66.2314 87.1365C66.2314 87.1365 67.238 87.6594 69.2077 88.4167C71.0234 89.1147 73.0385 89.79 75.4403 90.3069C78.2374 90.8845 81.3689 91.2106 84.318 91.2106"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M102.435 87.1362C102.435 87.1362 101.428 87.6592 99.4583 88.4164C97.6426 89.1145 95.6275 89.7898 93.2257 90.3066C90.4286 90.8843 87.2971 91.2104 84.348 91.2104"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M66.2314 58.8928C66.2314 58.8928 67.238 58.3699 69.2077 57.6126C71.0234 56.9146 73.0385 56.2393 75.4403 55.7224C78.2374 55.1448 81.3689 54.8187 84.318 54.8187"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M102.435 58.8931C102.435 58.8931 101.428 58.3701 99.4583 57.6129C97.6426 56.9148 95.6275 56.2395 93.2257 55.7227C90.4286 55.145 87.2971 54.8189 84.348 54.8189"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3477 96.0298V73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3174 96.0298V73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3477 49.9995V73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3174 49.9995V73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3174 96.0298V73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3486 96.0298V73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3174 49.9995V73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M84.3486 49.9995V73.0146"
                  stroke="#5C3BFE"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div class="methods__item-column">
                <p class="methods__item-title">
                  Product development services from scratch
                </p>
                <p class="methods__item-text">
                  Mobile games, Smart TV apps, or apps for startups — we can do
                  it all from the ground up. We’ll keep track of ideation,
                  business analytics, and development. Most importantly, we’ll
                  deliver a product with great performance on an appropriate
                  budget.
                </p>
                <a
                  class="methods__btn--second methods__btn rising-btn rising-btn--theme3 js-scroll-to"
                  href="/services/mvp/#footer"
                >
                  <span class="rising-btn__inner">
                    <span class="rising-btn__wrapper">
                      <span class="rising-btn__text first-face__btn-text">
                        Contact us
                      </span>
                    </span>
                  </span>
                </a>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MethodsSection;
