import React from "react";
import Footer from "../../components/Layout/Footer";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import HomeTwoMain from "./HomeMain";
const HomeTwo = () => {
  const counters = [
    {
      countNum: 7,
      countTitle: "Years of Expertise",
      counterPrefix: "+",
    },
    {
      countNum: 30,
      countTitle: "Projects Completed",
      counterPrefix: "+",
    },
    {
      countNum: 25,
      countTitle: "Happy Clients",
      counterPrefix: "+",
    },
    {
      countNum: 7,
      countTitle: "Products Designed",
      counterPrefix: "+",
    },
  ];

  return (
    <React.Fragment>
      {/* <OnepageHeader /> */}
      <HeaderStyleThree parentMenu="home" />

      <HomeTwoMain counters={counters} />
      <Footer footerClass="rs-footer" />
      {/* <SearchModal /> */}
    </React.Fragment>
  );
};

export default HomeTwo;
