import SectionTitle from "../../components/Common/SectionTitle";

// WhyChooseUs Images
import normalLogo1 from "../../assets/img/technology/style4/main-img/1.png";
import normalLogo2 from "../../assets/img/technology/style4/main-img/2.png";
import normalLogo3 from "../../assets/img/technology/style4/main-img/3.png";
import normalLogo4 from "../../assets/img/technology/style4/main-img/4.png";
import normalLogo5 from "../../assets/img/technology/style4/main-img/5.png";
import normalLogo6 from "../../assets/img/technology/style4/main-img/6.png";

import hoverLogo1 from "../../assets/img/technology/style4/hover-img/1.png";
import hoverLogo2 from "../../assets/img/technology/style4/hover-img/2.png";
import hoverLogo3 from "../../assets/img/technology/style4/hover-img/3.png";
import hoverLogo4 from "../../assets/img/technology/style4/hover-img/4.png";
import hoverLogo5 from "../../assets/img/technology/style4/hover-img/5.png";
import hoverLogo6 from "../../assets/img/technology/style4/hover-img/6.png";

import bgImg from "../../assets/img/bg/index-bg.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Technology = () => {
  const data = [
    {
      name: "Node Js",
      icon_image_url: normalLogo1,
      hover_icon_image_url: hoverLogo1,
    },
    {
      name: "Python",
      icon_image_url: normalLogo2,
      hover_icon_image_url: hoverLogo2,
    },
    {
      name: "My Sql",
      icon_image_url: normalLogo3,
      hover_icon_image_url: hoverLogo3,
    },
    {
      name: "Firebase",
      icon_image_url: normalLogo4,
      hover_icon_image_url: hoverLogo4,
    },
    {
      name: "Tensorflow",
      icon_image_url: normalLogo5,
      hover_icon_image_url: hoverLogo5,
    },
    {
      name: "Docker",
      icon_image_url: normalLogo6,
      hover_icon_image_url: hoverLogo6,
    },
  ];

  return (
    <div
      className="rs-technology style2 pt-110 pb-114 md-pt-74 md-pb-80"
      style={bgStyle}
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title2 text-center mb-44"
          subtitleClass="sub-text white-color"
          subtitle="Technology Index"
          titleClass="title title2 white-color"
          title="What Technology We Are Using For Our Valued Customers "
        />
        <div className="row ">
          {data.map((value, index) => (
            <div className="col-lg-2 col-md-4 col-sm-6 md-pb-30 pb-30">
              <div className="technology-wrap">
                <div className="rs-grid-figure">
                  <div className="logo-img">
                    <a href>
                      <img
                        className="hover-img"
                        src={value.hover_icon_image_url}
                        alt="hover-image"
                      />
                      <img
                        className="main-img"
                        src={value.icon_image_url}
                        alt="main-image"
                      />
                    </a>
                  </div>
                </div>
                {/* <div className="logo-title">
                                        <h4 className="title">Node Js</h4>
                                    </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Technology;
