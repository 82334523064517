import { motion } from "framer-motion";
import React from "react";

function TechnologiesSection() {
  const fadeFromdownAnimation = (delay = 0) => ({
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
    transition: {
      // delay:1
      delay,
      duration: 0.7,
    },
  });

  return (
    <section class="technologies">
      <div class="container">
        <div class="technologies__inner">
          <motion.h2
            class="technologies__title block-title"
            {...fadeFromdownAnimation()}
          >
            <span class="technologies__title--stroke-text">Tech stacks </span>
            <br />
            we use for MVP software development
          </motion.h2>
          <motion.p
            class="technologies__subtitle"
            {...fadeFromdownAnimation(0.2)}
          >
            At Urbano Infotech, we stick to frameworks based on JavaScript. With
            them, we can add new features like payments without spending a lot
            of time or money. We know how these frameworks operate, and we can
            use them to their fullest to deliver an app you want.
            <br class="technologies__subtitle-br" />
            <a
              class="technologies__subtitle-link js-scroll-to"
              href="/services/mvp/#footer"
            >
              Write to us
            </a>
            in the form below if you want to get a robust product in 4 months.
          </motion.p>
          <div class="technologies__wrapper">
            <svg
              class="technologies__image technologies__image--desktop"
              width="1200"
              height="231"
              viewBox="0 0 1200 231"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3593_65742)">
                <rect y="26" width="1200" height="160" fill="#5C3BFE"></rect>
                <path
                  d="M889.872 70.678L887.696 76.9796"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M881.169 95.8845L878.993 102.186"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M877.14 71.4485L880.059 77.4462"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M888.807 95.4182L891.726 101.416"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M868.679 80.9929L874.98 83.1686"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M893.885 89.6956L900.186 91.8713"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M869.449 93.725L875.447 90.8059"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <circle cx="127.5" cy="149.5" r="5.5" fill="white"></circle>
                <circle cx="6" cy="27" r="10" fill="white"></circle>
                <circle cx="1135.5" cy="158.5" r="8.5" fill="white"></circle>
                <rect
                  x="0.5"
                  y="26.5"
                  width="1199"
                  height="159"
                  stroke="#5C3BFE"
                ></rect>
                <circle cx="839.5" cy="54.5" r="2.5" fill="white"></circle>
                <circle cx="1186.5" cy="108.5" r="2.5" fill="white"></circle>
                <path
                  d="M0 76.4991C70.8333 66.1657 238.5 64.7032 364 116.703C554 195.428 749.888 330.5 855 121C927.5 -23.5001 1275 145.703 1512 378.499"
                  stroke="white"
                  stroke-dasharray="10 10"
                ></path>
                <g filter="url(#filter0_d_3593_65742)">
                  <path
                    d="M60.5 86C67.9558 86 74 79.9558 74 72.5C74 65.0442 67.9558 59 60.5 59C53.0442 59 47 65.0442 47 72.5C47 79.9558 53.0442 86 60.5 86Z"
                    fill="white"
                  ></path>
                  <path
                    d="M66.2644 73.1197L57.9484 77.9209C57.4847 78.1901 56.9014 77.8461 56.9014 77.3076V67.6903C56.9014 67.1519 57.4847 66.8078 57.9484 67.0771L66.2644 71.8782C66.7431 72.1624 66.7431 72.8355 66.2644 73.1197Z"
                    fill="#5C3BFE"
                  ></path>
                </g>
                <path
                  d="M1213.85 36.3237C1213.85 33.3225 1213.29 30.4464 1212.29 27.8204L1213.67 27.3202L1216.93 26.1323C1215.73 22.881 1213.98 19.8799 1211.72 17.1914L1209.09 19.3797L1207.96 20.3176C1206.08 18.1292 1203.82 16.2535 1201.32 14.7529L1202.07 13.5025L1203.76 10.5013C1200.75 8.75067 1197.43 7.56271 1193.98 7L1193.35 10.4388L1193.1 11.8769C1191.72 11.6268 1190.28 11.5017 1188.84 11.5017C1187.34 11.5017 1185.83 11.6268 1184.45 11.8769L1184.2 10.4388L1183.57 7.06252C1180.13 7.68776 1176.87 8.87571 1173.86 10.6264L1175.61 13.6275L1176.37 14.878C1173.86 16.316 1171.6 18.2543 1169.72 20.4426L1168.59 19.5048L1165.96 17.3164C1163.7 20.0049 1161.95 23.0061 1160.76 26.2573L1164.02 27.4453L1165.39 27.9455C1164.45 30.5715 1163.89 33.4476 1163.89 36.3862V36.4487H1162.45H1159C1159 39.95 1159.63 43.3888 1160.82 46.6401L1164.08 45.4521L1165.46 44.9519C1166.46 47.703 1167.96 50.2039 1169.85 52.3923L1168.72 53.3301L1166.08 55.5185C1168.34 58.207 1171.04 60.3953 1174.05 62.146L1175.8 59.1448L1176.55 57.8944C1179.06 59.3324 1181.76 60.3328 1184.7 60.833L1184.45 62.271L1183.89 65.6473C1185.58 65.9599 1187.34 66.085 1189.03 66.085C1190.78 66.085 1192.54 65.9599 1194.23 65.6473L1193.6 62.271L1193.35 60.833C1196.24 60.3328 1199 59.2699 1201.5 57.8318L1202.26 59.0823L1204.01 62.0835C1207.02 60.3328 1209.72 58.0819 1211.91 55.4559L1209.28 53.2676L1208.15 52.3297C1210.03 50.1414 1211.47 47.5779 1212.47 44.8269L1213.85 45.3271L1217.11 46.515C1218.3 43.2638 1218.93 39.825 1218.93 36.3237V36.1986H1213.85V36.3237ZM1188.84 54.4556C1178.87 54.4556 1170.79 46.39 1170.79 36.4487C1170.79 26.5074 1178.87 18.4419 1188.84 18.4419C1198.81 18.3793 1206.96 26.4449 1206.96 36.3862C1206.96 46.3275 1198.81 54.4556 1188.84 54.4556Z"
                  fill="white"
                  stroke="white"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M78.0347 170.889C78.8645 164.082 78.3796 157.403 76.8297 151.17L80.0976 150.417L87.8232 148.624C86.0194 140.921 82.8663 133.628 78.4886 126.906L71.9089 131.141L69.0891 132.956C65.4266 127.473 60.8242 122.594 55.5491 118.497L57.6019 115.869L62.2726 109.53C55.9286 104.727 48.7177 101.114 41.0495 98.8836L38.6761 106.51L37.7094 109.702C34.649 108.753 31.4118 108.07 28.14 107.672C24.7259 107.255 21.2773 107.123 18.0786 107.308L17.9073 103.977L17.4184 96.1463C9.42161 96.6105 1.696 98.403 -5.6162 101.541L-2.46304 108.833L-1.10179 111.878C-7.18954 114.446 -12.8466 118.217 -17.7193 122.66L-20.0205 120.221L-25.3899 114.529C-31.2544 120.002 -36.0674 126.324 -39.6692 133.368L-32.6006 136.964L-29.6094 138.48C-32.4693 144.176 -34.5449 150.543 -35.3575 157.208L-35.3748 157.35L-38.6466 156.951L-46.4705 155.997C-47.4387 163.938 -46.9671 171.911 -45.1633 179.614L-37.4377 177.822L-34.1699 177.069C-32.6546 183.586 -29.9321 189.675 -26.2697 195.158L-29.0895 196.973L-35.6693 201.208C-31.2916 207.93 -25.7799 213.639 -19.4359 218.442L-14.623 212.121L-12.5702 209.493C-7.27774 213.448 -1.43753 216.463 5.11 218.413L4.14333 221.605L1.92944 229.106C5.6838 230.284 9.63228 231.053 13.4731 231.521C17.4561 232.007 21.4738 232.209 25.401 231.968L24.9121 224.137L24.7408 220.806C31.4227 220.469 37.9757 218.822 44.0634 216.254L45.4247 219.298L48.5778 226.591C55.8901 223.452 62.6293 219.093 68.3343 213.744L62.9648 208.053L60.6636 205.613C65.5363 201.17 69.517 195.755 72.5538 189.793L75.545 191.309L82.6136 194.905C86.2154 187.861 88.5889 180.235 89.5571 172.294L89.5916 172.01L78.0692 170.605L78.0347 170.889ZM16.2622 205.093C-6.35592 202.335 -22.4761 181.805 -19.7271 159.258C-16.9781 136.71 3.60278 120.654 26.2209 123.412C48.8562 126.028 65.1187 146.576 62.3696 169.123C59.6206 191.67 38.8802 207.851 16.2622 205.093Z"
                  fill="white"
                  stroke="white"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M617.172 34.9237C617.172 34.9237 614.752 38.0822 608.078 41.4193C605.48 42.7184 598.334 45.1833 594.437 45.9661C590.54 46.749 584.694 47.7256 577.282 47.7256"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M537.111 34.9237C537.111 34.9237 539.53 38.0822 546.204 41.4193C548.803 42.7184 555.948 45.1833 559.845 45.9661C563.742 46.749 569.588 47.7256 577 47.7256"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M605.48 48.5643C605.103 48.6588 600.283 52.4289 592.992 54.0562C585.701 55.6835 581.446 55.9458 577.188 55.9458"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M548.804 48.5643C549.18 48.6588 554 52.4289 561.291 54.0562C568.582 55.6835 572.837 55.9458 577.095 55.9458"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M577.093 58.4087C571.731 58.4087 566.558 57.4639 561.76 55.7632C558.938 54.8184 556.304 53.5901 553.858 52.0785C552.165 51.0392 550.472 49.9999 548.967 48.7716C545.862 46.4096 543.134 43.5752 540.783 40.5518C539.56 38.9456 538.431 37.3394 537.49 35.6388C533.445 28.7417 531.188 20.8053 531.188 12.2075"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M555.929 12.2954C555.929 19.6649 556.399 26.4675 557.152 32.4198C557.81 37.6163 558.563 42.2459 559.315 45.8361C560.068 49.7098 560.82 52.6388 561.291 54.2449C561.573 55.1897 561.761 55.7566 561.761 55.8511"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M541.63 12.2954C541.63 18.4367 542.477 24.6724 543.699 30.3413C544.64 35.1598 545.863 39.4114 546.898 42.7183C548.027 46.403 548.873 48.765 548.967 48.954"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M533.634 27.6958C533.634 27.6958 534.292 27.8887 535.609 28.2745C537.303 28.7567 540.031 29.5283 543.699 30.2998C547.274 31.0714 551.883 31.8429 557.245 32.5181C562.983 33.1932 569.662 33.6754 577.188 33.6754"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M622.924 12.1187C622.924 17.5041 622.248 22.8777 620.555 27.6962C619.614 30.4362 618.391 33.1761 616.886 35.6326C615.851 37.3333 614.816 39.0339 613.593 40.5456C611.242 43.6635 608.42 46.4034 605.409 48.7655C603.81 49.9937 602.211 51.1275 600.518 52.0723C593.651 56.135 585.749 58.4025 577.188 58.4025"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M577.094 33.6752C584.431 33.6752 591.204 33.2028 597.131 32.4469C602.304 31.7856 606.914 31.0297 610.488 30.2739C614.345 29.518 617.261 28.7622 618.861 28.2898C619.801 28.0063 620.366 27.8174 620.46 27.8174"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M592.428 55.9456C592.428 55.9456 592.616 55.2842 592.992 54.056C593.462 52.3553 594.215 49.6154 594.968 45.9306C595.72 42.3403 596.473 37.7108 597.131 32.3254C597.79 26.562 598.26 19.8539 598.26 12.2954"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M605.314 48.8595C605.314 48.8595 606.161 46.4975 607.384 42.7183C608.419 39.4114 609.548 35.0653 610.582 30.1523C611.711 24.4834 612.652 18.2477 612.652 12.2954"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M577.094 58.2976V12.2954"
                  stroke="white"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
              <path
                d="M1078.92 12.047C1081.54 16.1282 1081.83 21.0045 1080.17 25.1684C1079.73 26.2752 1079.85 27.468 1080.48 28.4719L1082.21 31.2445C1083.1 32.6547 1082.8 34.47 1081.61 35.5477C1081.46 35.6765 1081.31 35.8052 1081.14 35.9101L1075.06 39.7144C1074.9 39.8192 1074.69 39.9152 1074.51 39.9961C1073.02 40.5958 1071.28 40.0537 1070.4 38.6435L1068.66 35.8709C1068.05 34.8909 1067.01 34.2433 1065.84 34.1789C1061.39 33.8342 1057.16 31.4921 1054.63 27.4527C1050.41 20.7123 1052.45 11.8478 1059.2 7.62409C1065.92 3.41538 1074.64 5.37553 1078.92 12.047Z"
                fill="white"
                stroke="#5C3BFE"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M1081.49 35.8579L1084.95 41.384C1085.44 42.1735 1085.21 43.2189 1084.43 43.7071L1080.25 46.3256C1079.47 46.8138 1078.43 46.5673 1077.93 45.7779L1074.47 40.2517C1074.44 40.2038 1074.41 40.156 1074.4 40.1321C1074.58 40.0524 1074.76 39.9728 1074.95 39.8545L1080.95 36.0968C1081.11 35.9932 1081.29 35.8509 1081.41 35.7383C1081.44 35.7861 1081.46 35.81 1081.49 35.8579Z"
                fill="white"
                stroke="#5C3BFE"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M1075.33 41.623C1075.33 41.623 1081.52 38.8349 1083.75 39.4653L1075.33 41.623Z"
                fill="white"
              ></path>
              <path
                d="M1075.33 41.623C1075.33 41.623 1081.52 38.8349 1083.75 39.4653"
                stroke="#5C3BFE"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M1076.96 44.2317C1076.96 44.2317 1083.07 41.3136 1085.15 41.7013L1076.96 44.2317Z"
                fill="white"
              ></path>
              <path
                d="M1076.96 44.2317C1076.96 44.2317 1083.07 41.3136 1085.15 41.7013"
                stroke="#5C3BFE"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M315.593 194.855C309.805 199.769 302.299 201.229 295.495 199.525C293.687 199.073 291.86 199.498 290.432 200.681L286.49 203.949C284.485 205.612 281.607 205.53 279.689 203.907C279.457 203.698 279.226 203.488 279.029 203.25L271.867 194.612C271.669 194.374 271.478 194.073 271.315 193.807C270.075 191.621 270.556 188.812 272.561 187.15L276.504 183.881C277.897 182.726 278.687 180.981 278.544 179.148C278.157 172.181 280.915 165.132 286.658 160.37C296.242 152.424 310.419 153.754 318.37 163.344C326.293 172.9 325.058 186.835 315.593 194.855Z"
                fill="white"
                stroke="#5C3BFE"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M279.181 203.781L271.324 210.295C270.202 211.226 268.531 211.081 267.612 209.972L262.683 204.027C261.764 202.918 261.93 201.25 263.053 200.319L270.91 193.805C270.978 193.748 271.046 193.692 271.08 193.664C271.241 193.927 271.402 194.19 271.625 194.459L278.699 202.991C278.894 203.226 279.15 203.467 279.352 203.64C279.283 203.696 279.249 203.725 279.181 203.781Z"
                fill="white"
                stroke="#5C3BFE"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M268.961 195.421C268.961 195.421 274.569 204.442 274.053 208.033L268.961 195.421Z"
                fill="white"
              ></path>
              <path
                d="M268.961 195.421C268.961 195.421 274.569 204.442 274.053 208.033"
                stroke="#5C3BFE"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M265.252 198.496C265.252 198.496 271.045 207.364 270.874 210.669L265.252 198.496Z"
                fill="white"
              ></path>
              <path
                d="M265.252 198.496C265.252 198.496 271.045 207.364 270.874 210.669"
                stroke="#5C3BFE"
                stroke-miterlimit="10"
              ></path>
              <g filter="url(#filter1_d_3593_65742)">
                <path
                  d="M777.838 206.791L760.428 162.665L715.151 141.848L887.594 85.2529L836.473 196.84L810.527 186.244L777.838 206.791Z"
                  fill="white"
                ></path>
                <path
                  d="M777.838 206.791L760.428 162.665M777.838 206.791L784.58 175.648M777.838 206.791L810.527 186.244M760.428 162.665L715.151 141.848L887.594 85.2529M760.428 162.665L887.594 85.2529M887.594 85.2529L836.473 196.84L810.527 186.244M887.594 85.2529L784.58 175.648M784.58 175.648L810.527 186.244"
                  stroke="#5C3BFE"
                  stroke-width="0.757693"
                ></path>
              </g>
              <path
                d="M831.025 150.631L839.705 156.377L838.971 157.204L831.605 152.329L834.119 158.591L834.014 158.71L826.359 158.242L833.725 163.117L832.99 163.945L824.31 158.199L825.222 157.17L832.504 157.616L830.112 151.66L831.025 150.631Z"
                fill="#5C3BFE"
              ></path>
              <path
                d="M843.355 152.264L831.967 149.569L832.764 148.67L842.579 151.075L837.339 143.514L838.136 142.616L844.11 151.412L843.355 152.264Z"
                fill="#5C3BFE"
              ></path>
              <path
                d="M841.708 138.59C842.365 137.849 843.175 137.403 844.136 137.251C845.098 137.099 845.967 137.28 846.744 137.794C847.521 138.309 847.95 138.987 848.03 139.828C848.11 140.669 847.822 141.461 847.164 142.202L845.276 144.33L848.5 146.465L847.765 147.292L839.085 141.547L841.708 138.59ZM846.346 141.66C846.8 141.148 846.997 140.608 846.935 140.042C846.872 139.462 846.564 138.989 846.01 138.622C845.456 138.255 844.851 138.124 844.194 138.228C843.537 138.318 842.981 138.619 842.526 139.132L840.637 141.26L844.457 143.789L846.346 141.66Z"
                fill="#5C3BFE"
              ></path>
              <defs>
                <filter
                  id="filter0_d_3593_65742"
                  x="28.6575"
                  y="49.8288"
                  width="63.6849"
                  height="63.6849"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feflood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feflood>
                  <fecolormatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></fecolormatrix>
                  <feoffset dy="9.17123"></feoffset>
                  <fegaussianblur stdDeviation="9.17123"></fegaussianblur>
                  <fecolormatrix
                    type="matrix"
                    values="0 0 0 0 0.360784 0 0 0 0 0.231373 0 0 0 0 0.996078 0 0 0 0.15 0"
                  ></fecolormatrix>
                  <feblend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_3593_65742"
                  ></feblend>
                  <feblend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_3593_65742"
                    result="shape"
                  ></feblend>
                </filter>
                <filter
                  id="filter1_d_3593_65742"
                  x="695.161"
                  y="69.4592"
                  width="212.093"
                  height="160.64"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feflood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feflood>
                  <fecolormatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></fecolormatrix>
                  <feoffset dy="3.79083"></feoffset>
                  <fegaussianblur stdDeviation="9.47707"></fegaussianblur>
                  <fecomposite in2="hardAlpha" operator="out"></fecomposite>
                  <fecolormatrix
                    type="matrix"
                    values="0 0 0 0 0.360784 0 0 0 0 0.231373 0 0 0 0 0.996078 0 0 0 0.12 0"
                  ></fecolormatrix>
                  <feblend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_3593_65742"
                  ></feblend>
                  <feblend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_3593_65742"
                    result="shape"
                  ></feblend>
                </filter>
                <clippath id="clip0_3593_65742">
                  <rect
                    width="1200"
                    height="159"
                    fill="white"
                    transform="translate(0 26)"
                  ></rect>
                </clippath>
              </defs>
            </svg>
            <svg
              class="technologies__image technologies__image--mobile"
              width="343"
              height="110"
              viewBox="0 0 343 110"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_3766_72680)">
                <rect y="8.6748" width="345" height="77" fill="#5C3BFE"></rect>
                <path
                  d="M255.838 35.3027L255.212 37.3849"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M253.336 43.6318L252.71 45.714"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M252.177 35.5576L253.017 37.5393"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M255.532 43.4775L256.371 45.4592"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M249.745 38.7109L251.557 39.4298"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M256.992 41.5869L258.803 42.3058"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M249.966 42.9184L251.691 41.9539"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <ellipse
                  cx="36.6562"
                  cy="61.3466"
                  rx="1.58125"
                  ry="1.81726"
                  fill="white"
                ></ellipse>
                <ellipse
                  cx="0.875"
                  cy="10.3041"
                  rx="2.875"
                  ry="3.3041"
                  fill="white"
                ></ellipse>
                <ellipse
                  cx="326.456"
                  cy="64.3202"
                  rx="2.44375"
                  ry="2.80849"
                  fill="white"
                ></ellipse>
                <ellipse
                  cx="241.356"
                  cy="29.9579"
                  rx="0.71875"
                  ry="0.826026"
                  fill="white"
                ></ellipse>
                <ellipse
                  cx="341.119"
                  cy="47.8006"
                  rx="0.71875"
                  ry="0.826026"
                  fill="white"
                ></ellipse>
                <path
                  d="M0 38.5187C20.3646 35.5478 68.5687 35.1273 104.65 50.0773C159.275 72.7108 215.593 111.544 245.812 51.3127C266.656 9.76888 366.562 58.4148 434.7 125.344"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-dasharray="2.88 2.88"
                ></path>
                <g filter="url(#filter0_d_3766_72680)">
                  <path
                    d="M27.7437 40.4875C30.3636 40.4875 32.4875 38.3636 32.4875 35.7437C32.4875 33.1238 30.3636 31 27.7437 31C25.1238 31 23 33.1238 23 35.7437C23 38.3636 25.1238 40.4875 27.7437 40.4875Z"
                    fill="white"
                  ></path>
                  <path
                    d="M29.7696 35.9609L26.8474 37.648C26.6845 37.7426 26.4795 37.6217 26.4795 37.4325V34.0531C26.4795 33.8639 26.6845 33.743 26.8474 33.8376L29.7696 35.5247C29.9377 35.6245 29.9377 35.8611 29.7696 35.9609Z"
                    fill="#5C3BFE"
                  ></path>
                </g>
                <path
                  d="M351.136 13.9185C351.136 12.8011 350.929 11.7302 350.561 10.7524L351.067 10.5661L352.264 10.1238C351.826 8.91323 351.182 7.79577 350.354 6.79471L349.387 7.60952L348.973 7.95873C348.282 7.14392 347.454 6.4455 346.533 5.88677L346.81 5.42116L347.431 4.3037C346.326 3.65185 345.107 3.20952 343.841 3L343.611 4.28042L343.519 4.81587C343.013 4.72275 342.483 4.67619 341.954 4.67619C341.402 4.67619 340.849 4.72275 340.343 4.81587L340.251 4.28042L340.021 3.02328C338.755 3.25608 337.559 3.69841 336.454 4.35026L337.098 5.46773L337.374 5.93333C336.454 6.46878 335.626 7.19048 334.935 8.00529L334.521 7.65609L333.554 6.84127C332.726 7.84233 332.082 8.95979 331.644 10.1704L332.841 10.6127L333.347 10.7989C333.002 11.7767 332.795 12.8476 332.795 13.9418V13.9651H332.266H331C331 15.2688 331.23 16.5492 331.667 17.7598L332.864 17.3175L333.37 17.1312C333.738 18.1556 334.291 19.0868 334.981 19.9016L334.567 20.2508L333.6 21.0656C334.429 22.0667 335.418 22.8815 336.523 23.5333L337.167 22.4159L337.444 21.9503C338.364 22.4857 339.354 22.8582 340.435 23.0444L340.343 23.5799L340.136 24.837C340.757 24.9534 341.402 25 342.023 25C342.667 25 343.312 24.9534 343.933 24.837L343.703 23.5799L343.611 23.0444C344.669 22.8582 345.682 22.4624 346.603 21.927L346.879 22.3926L347.523 23.5101C348.628 22.8582 349.617 22.0201 350.423 21.0423L349.456 20.2275L349.042 19.8783C349.732 19.0635 350.262 18.109 350.63 17.0847L351.136 17.2709L352.333 17.7132C352.77 16.5026 353 15.2222 353 13.9185V13.872H351.136V13.9185ZM341.954 20.6698C338.295 20.6698 335.326 17.6667 335.326 13.9651C335.326 10.2635 338.295 7.26032 341.954 7.26032C345.613 7.23704 348.605 10.2402 348.605 13.9418C348.605 17.6434 345.613 20.6698 341.954 20.6698Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M22.435 81.4839C22.6736 79.527 22.5341 77.6067 22.0886 75.8146L23.0281 75.5982L25.2492 75.0828C24.7306 72.8681 23.8241 70.7715 22.5655 68.8389L20.6738 70.0564L19.8631 70.5782C18.8102 69.0017 17.487 67.5991 15.9704 66.4212L16.5606 65.6656L17.9034 63.8433C16.0795 62.4624 14.0064 61.4235 11.8017 60.7824L11.1194 62.9748L10.8415 63.8926C9.9616 63.6198 9.0309 63.4236 8.09026 63.3089C7.10872 63.1892 6.11724 63.1511 5.19761 63.2045L5.14835 62.2468L5.00779 59.9954C2.70872 60.1289 0.487614 60.6442 -1.61464 61.5464L-0.70811 63.6429L-0.316755 64.5182C-2.06698 65.2564 -3.69338 66.3408 -5.09428 67.6181L-5.75587 66.9168L-7.29959 65.2805C-8.98564 66.854 -10.3694 68.6714 -11.4049 70.6966L-9.37267 71.7305L-8.51269 72.1664C-9.33492 73.8039 -9.93165 75.6344 -10.1653 77.5506L-10.1702 77.5914L-11.1109 77.4767L-13.3602 77.2024C-13.6386 79.4855 -13.503 81.7777 -12.9844 83.9925L-10.7633 83.4772L-9.82382 83.2607C-9.38818 85.1343 -8.60547 86.8848 -7.55252 88.4613L-8.36323 88.9831L-10.2549 90.2006C-8.99633 92.1332 -7.41171 93.7745 -5.58782 95.1554L-4.20409 93.3381L-3.61391 92.5826C-2.09234 93.7197 -0.413278 94.5864 1.46914 95.1469L1.19122 96.0647L0.554726 98.2214C1.6341 98.5599 2.76929 98.781 3.87352 98.9156C5.01865 99.0553 6.17372 99.1133 7.30281 99.0441L7.16225 96.7927L7.11299 95.8351C9.03404 95.7383 10.918 95.2646 12.6683 94.5263L13.0596 95.4016L13.9661 97.4981C16.0684 96.5959 18.0059 95.3426 19.6461 93.8048L18.1024 92.1685L17.4408 91.4672C18.8417 90.1898 19.9861 88.633 20.8592 86.9189L21.7192 87.3547L23.7514 88.3886C24.7869 86.3634 25.4693 84.1709 25.7477 81.8878L25.7576 81.8063L22.4449 81.4024L22.435 81.4839ZM4.67538 91.3176C-1.82732 90.5247 -6.46187 84.6222 -5.67152 78.1399C-4.88118 71.6575 1.03581 67.0415 7.53851 67.8343C14.0462 68.5864 18.7216 74.4938 17.9313 80.9762C17.1409 87.4586 11.1781 92.1104 4.67538 91.3176Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                ></path>
                <path
                  d="M163.915 3C165.456 3 166.944 3.27163 168.323 3.76057C169.107 4.0322 169.891 4.38533 170.595 4.81994C171.081 5.11873 171.568 5.44469 172.001 5.77065C172.893 6.44973 173.678 7.26463 174.354 8.13386C174.706 8.59563 175.03 9.05741 175.3 9.54634C176.463 11.5293 177.113 13.811 177.113 16.2828"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M170 16.3103C170 14.1916 169.865 12.2359 169.648 10.5246C169.459 9.03059 169.243 7.69959 169.026 6.66739C168.81 5.5537 168.593 4.71164 168.458 4.24986C168.377 3.97823 168.323 3.81525 168.323 3.78809"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M173.994 16.31C173.994 16.3894 174.058 16.4538 174.138 16.4538C174.217 16.4538 174.281 16.3894 174.281 16.31H173.994ZM173.543 11.149L173.401 11.1766L173.402 11.1798L173.543 11.149ZM172.623 7.59063L172.486 7.63274L172.486 7.6347L172.623 7.59063ZM174.281 16.31C174.281 14.5319 174.036 12.7286 173.683 11.1182L173.402 11.1798C173.752 12.7747 173.994 14.557 173.994 16.31H174.281ZM173.684 11.1215C173.412 9.72944 173.059 8.47432 172.76 7.54655L172.486 7.6347C172.782 8.55403 173.132 9.79794 173.402 11.1766L173.684 11.1215ZM172.761 7.54852C172.598 7.01748 172.455 6.58151 172.35 6.27118C172.298 6.11604 172.254 5.99206 172.222 5.90314C172.192 5.81945 172.168 5.75677 172.157 5.73379L171.899 5.86191C171.899 5.86124 171.903 5.87077 171.914 5.89837C171.923 5.92305 171.936 5.95725 171.952 6.0007C171.983 6.08749 172.026 6.20968 172.078 6.36352C172.182 6.67115 172.324 7.1044 172.486 7.63273L172.761 7.54852Z"
                  fill="white"
                ></path>
                <path
                  d="M163.915 16.3096H170H174.138H177.09"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M176.343 11.9639C175.783 11.7771 174.795 11.4195 173.516 11.149C172.488 10.9317 171.163 10.7143 169.621 10.5242C167.998 10.3341 166.078 10.1982 163.915 10.1982"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M175.41 9.72254C175.41 9.72254 174.714 8.81448 172.795 7.85506C172.048 7.48157 169.994 6.77291 168.874 6.54783C167.753 6.32275 166.072 6.04199 163.942 6.04199"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M175.437 22.8155C175.437 22.8155 174.741 23.7236 172.822 24.683C172.075 25.0565 170.021 25.7652 168.901 25.9903C167.78 26.2153 166.1 26.4961 163.969 26.4961"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M152.42 22.8155C152.42 22.8155 153.115 23.7236 155.034 24.683C155.781 25.0565 157.835 25.7652 158.956 25.9903C160.076 26.2153 161.757 26.4961 163.888 26.4961"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M152.44 9.72254C152.44 9.72254 153.136 8.81448 155.054 7.85506C155.801 7.48157 157.856 6.77291 158.976 6.54783C160.097 6.32275 161.777 6.04199 163.908 6.04199"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M172.075 26.7372C171.967 26.7644 170.581 27.8483 168.485 28.3161C166.389 28.784 165.166 28.8594 163.942 28.8594"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M155.781 26.7372C155.889 26.7644 157.275 27.8483 159.371 28.3161C161.467 28.784 162.691 28.8594 163.915 28.8594"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M150.663 16.3101C150.663 14.7618 150.933 13.2678 151.42 11.8825C151.69 11.0676 152.042 10.307 152.475 9.60075C152.772 9.11181 153.07 8.62287 153.421 8.18826C154.097 7.29187 154.909 6.50414 155.774 5.82506C156.234 5.47193 156.694 5.14597 157.18 4.87434C159.155 3.70632 161.557 3 164.018 3"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M163.915 16.3096H157.83H153.719H151.339H150.663"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M163.915 10.1982C161.805 10.1982 159.858 10.3341 158.154 10.5514C156.666 10.7415 155.341 10.9588 154.314 11.1761C153.205 11.3934 152.366 11.6107 151.907 11.7465C151.636 11.828 151.474 11.8824 151.447 11.8824"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M158.79 6.54828L158.93 6.57777L158.93 6.57705L158.79 6.54828ZM158.154 10.5514L158.011 10.534L158.011 10.5351L158.154 10.5514ZM157.686 16.3101C157.686 16.3894 157.75 16.4538 157.83 16.4538C157.909 16.4538 157.973 16.3894 157.973 16.3101H157.686ZM159.408 3.68278C159.307 3.88549 159.171 4.33647 159.035 4.85409C158.898 5.37799 158.757 5.98747 158.649 6.51952L158.93 6.57705C159.038 6.04972 159.178 5.44534 159.313 4.92686C159.451 4.40208 159.58 3.98214 159.665 3.81136L159.408 3.68278ZM158.649 6.51879C158.431 7.55603 158.201 8.98287 158.011 10.534L158.297 10.5689C158.486 9.0234 158.715 7.60494 158.93 6.57777L158.649 6.51879ZM158.011 10.5351C157.821 12.1969 157.686 14.131 157.686 16.3101H157.973C157.973 14.143 158.108 12.2199 158.297 10.5678L158.011 10.5351Z"
                  fill="white"
                ></path>
                <path
                  d="M155.813 5.83594C155.813 5.83594 155.558 6.4766 155.206 7.56313C154.909 8.51384 154.584 9.76335 154.287 11.1758C153.962 12.8056 153.692 14.5984 153.692 16.3097"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M163.915 3V16.31"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M163.915 29.568C162.373 29.568 160.885 29.2964 159.506 28.8074C158.695 28.5358 157.938 28.1827 157.234 27.7481C156.748 27.4493 156.261 27.1505 155.828 26.7973C154.936 26.1183 154.151 25.3034 153.475 24.4341C153.124 23.9724 152.799 23.5106 152.529 23.0216C151.366 21.0387 150.717 18.757 150.717 16.2852"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M157.83 16.3096C157.83 18.4283 157.965 20.3841 158.181 22.0953C158.37 23.5893 158.587 24.9203 158.803 25.9525C159.02 27.0662 159.236 27.9083 159.371 28.3701C159.452 28.6417 159.506 28.8047 159.506 28.8318"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M153.719 16.3096C153.719 18.0752 153.962 19.868 154.314 21.4978C154.584 22.8831 154.936 24.1054 155.233 25.0561C155.558 26.1155 155.801 26.7946 155.828 26.8489"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M151.42 20.7373C151.42 20.7373 151.609 20.7928 151.988 20.9037C152.475 21.0423 153.259 21.2641 154.314 21.486C155.341 21.7078 156.667 21.9296 158.208 22.1237C159.858 22.3178 161.778 22.4564 163.942 22.4564"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M177.091 16.2588C177.091 17.8071 176.896 19.352 176.409 20.7373C176.139 21.5251 175.787 22.3128 175.355 23.0191C175.057 23.508 174.76 23.9969 174.408 24.4315C173.732 25.3279 172.921 26.1157 172.055 26.7947C171.595 27.1479 171.136 27.4738 170.649 27.7455C168.675 28.9135 166.403 29.5654 163.942 29.5654"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M163.915 22.4566C166.024 22.4566 167.971 22.3208 169.675 22.1035C171.163 21.9133 172.488 21.696 173.516 21.4787C174.624 21.2614 175.463 21.0441 175.923 20.9083C176.193 20.8268 176.355 20.7725 176.382 20.7725"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M168.323 28.859C168.323 28.859 168.377 28.6688 168.485 28.3157C168.62 27.8268 168.837 27.0391 169.053 25.9797C169.27 24.9475 169.486 23.6165 169.675 22.0682C169.865 20.4112 170 18.4826 170 16.3096"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M172.028 26.8218C172.028 26.8218 172.271 26.1427 172.623 25.0561C172.921 24.1054 173.245 22.8559 173.543 21.4434C173.867 19.8136 174.138 18.0209 174.138 16.3096"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M163.915 29.5352V16.3096"
                  stroke="white"
                  stroke-width="0.2875"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
              <g filter="url(#filter1_d_3766_72680)">
                <path
                  d="M200.921 98.6068L192.441 77.1129L170.385 66.9728L254.385 39.4045L229.483 93.7597L216.844 88.5983L200.921 98.6068Z"
                  fill="white"
                ></path>
                <path
                  d="M200.921 98.6068L192.441 77.1129M200.921 98.6068L204.205 83.437M200.921 98.6068L216.844 88.5983M192.441 77.1129L170.385 66.9728L254.385 39.4045M192.441 77.1129L254.385 39.4045M254.385 39.4045L229.483 93.7597L216.844 88.5983M254.385 39.4045L204.205 83.437M204.205 83.437L216.844 88.5983"
                  stroke="#5C3BFE"
                  stroke-width="0.369081"
                ></path>
              </g>
              <path
                d="M226.828 71.2499L231.057 74.0488L230.699 74.452L227.111 72.077L228.335 75.1276L228.284 75.1852L224.555 74.9571L228.144 77.3321L227.786 77.7354L223.557 74.9365L224.002 74.4354L227.549 74.6526L226.384 71.7511L226.828 71.2499Z"
                fill="#5C3BFE"
              ></path>
              <path
                d="M232.834 72.0453L227.287 70.7326L227.676 70.2948L232.457 71.4661L229.904 67.7833L230.292 67.3455L233.202 71.6305L232.834 72.0453Z"
                fill="#5C3BFE"
              ></path>
              <path
                d="M232.032 65.3847C232.352 65.0237 232.747 64.8062 233.215 64.7322C233.683 64.6582 234.107 64.7465 234.486 64.9971C234.864 65.2476 235.073 65.5779 235.112 65.9877C235.151 66.3976 235.01 66.783 234.69 67.144L233.77 68.1809L235.341 69.2204L234.983 69.6236L230.754 66.8248L232.032 65.3847ZM234.291 66.8801C234.513 66.6305 234.609 66.3677 234.579 66.0918C234.548 65.8094 234.398 65.5789 234.128 65.4003C233.858 65.2217 233.563 65.1577 233.243 65.2082C232.923 65.2522 232.652 65.399 232.431 65.6486L231.511 66.6855L233.371 67.917L234.291 66.8801Z"
                fill="#5C3BFE"
              ></path>
              <path
                d="M280.287 4.00166C281.594 6.03467 281.738 8.46373 280.914 10.538C280.695 11.0893 280.752 11.6835 281.065 12.1836L281.93 13.5647C282.37 14.2672 282.223 15.1715 281.63 15.7084C281.554 15.7725 281.478 15.8366 281.395 15.8888L278.369 17.7839C278.285 17.8362 278.182 17.8839 278.092 17.9243C277.35 18.223 276.484 17.953 276.044 17.2505L275.179 15.8693C274.874 15.3811 274.356 15.0585 273.772 15.0265C271.558 14.8548 269.451 13.6881 268.191 11.6759C266.088 8.31821 267.105 3.90241 270.465 1.79841C273.813 -0.298135 278.156 0.678303 280.287 4.00166Z"
                fill="white"
                stroke="#5C3BFE"
                stroke-width="0.498142"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M281.569 15.863L283.293 18.6158C283.539 19.0091 283.423 19.5298 283.034 19.773L280.951 21.0774C280.563 21.3206 280.044 21.1979 279.798 20.8046L278.074 18.0518C278.059 18.0279 278.044 18.0041 278.036 17.9922C278.126 17.9525 278.216 17.9128 278.31 17.8539L281.299 15.982C281.382 15.9304 281.469 15.8596 281.532 15.8034C281.547 15.8273 281.554 15.8392 281.569 15.863Z"
                fill="white"
                stroke="#5C3BFE"
                stroke-width="0.498142"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M278.501 18.7344C278.501 18.7344 281.583 17.3455 282.694 17.6595L278.501 18.7344Z"
                fill="white"
              ></path>
              <path
                d="M278.501 18.7344C278.501 18.7344 281.583 17.3455 282.694 17.6595"
                stroke="#5C3BFE"
                stroke-width="0.498142"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M279.315 20.0342C279.315 20.0342 282.356 18.5806 283.392 18.7737L279.315 20.0342Z"
                fill="white"
              ></path>
              <path
                d="M279.315 20.0342C279.315 20.0342 282.356 18.5806 283.392 18.7737"
                stroke="#5C3BFE"
                stroke-width="0.498142"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M79.3356 87.6942C77.4759 89.2729 75.0648 89.7419 72.8792 89.1944C72.2983 89.0492 71.7113 89.1856 71.2528 89.5658L69.9864 90.6157C69.3423 91.1498 68.4179 91.1235 67.8015 90.6023C67.7271 90.5348 67.6528 90.4674 67.5894 90.3909L65.2888 87.6161C65.2254 87.5396 65.1638 87.4432 65.1114 87.3576C64.7133 86.6553 64.8678 85.753 65.5119 85.2189L66.7783 84.169C67.2259 83.7979 67.4796 83.2373 67.4337 82.6486C67.3096 80.4104 68.1954 78.1459 70.0404 76.6162C73.119 74.0637 77.6733 74.4908 80.2275 77.5716C82.7727 80.6414 82.3762 85.118 79.3356 87.6942Z"
                fill="white"
                stroke="#5C3BFE"
                stroke-width="0.321241"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M67.6386 90.5617L65.1145 92.6544C64.754 92.9533 64.2174 92.9066 63.9221 92.5505L62.3386 90.6405C62.0434 90.2844 62.0968 89.7485 62.4574 89.4495L64.9815 87.3568C65.0034 87.3387 65.0252 87.3206 65.0361 87.3115C65.0878 87.3961 65.1395 87.4807 65.2111 87.567L67.4835 90.3079C67.5462 90.3834 67.6287 90.4607 67.6933 90.5164C67.6714 90.5345 67.6605 90.5436 67.6386 90.5617Z"
                fill="white"
                stroke="#5C3BFE"
                stroke-width="0.321241"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M64.3552 87.876C64.3552 87.876 66.1567 90.774 65.991 91.9276L64.3552 87.876Z"
                fill="white"
              ></path>
              <path
                d="M64.3552 87.876C64.3552 87.876 66.1567 90.774 65.991 91.9276"
                stroke="#5C3BFE"
                stroke-width="0.321241"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M63.1636 88.8643C63.1636 88.8643 65.0245 91.713 64.9696 92.7748L63.1636 88.8643Z"
                fill="white"
              ></path>
              <path
                d="M63.1636 88.8643C63.1636 88.8643 65.0245 91.713 64.9696 92.7748"
                stroke="#5C3BFE"
                stroke-width="0.321241"
                stroke-miterlimit="10"
              ></path>
              <defs>
                <filter
                  id="filter0_d_3766_72680"
                  x="16.5547"
                  y="27.7773"
                  width="22.3782"
                  height="22.378"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feflood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feflood>
                  <fecolormatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></fecolormatrix>
                  <feoffset dy="3.22266"></feoffset>
                  <fegaussianblur stdDeviation="3.22266"></fegaussianblur>
                  <fecolormatrix
                    type="matrix"
                    values="0 0 0 0 0.360784 0 0 0 0 0.231373 0 0 0 0 0.996078 0 0 0 0.15 0"
                  ></fecolormatrix>
                  <feblend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_3766_72680"
                  ></feblend>
                  <feblend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_3766_72680"
                    result="shape"
                  ></feblend>
                </filter>
                <filter
                  id="filter1_d_3766_72680"
                  x="160.648"
                  y="31.7114"
                  width="103.313"
                  height="78.2497"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feflood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                  ></feflood>
                  <fecolormatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></fecolormatrix>
                  <feoffset dy="1.84655"></feoffset>
                  <fegaussianblur stdDeviation="4.61639"></fegaussianblur>
                  <fecomposite in2="hardAlpha" operator="out"></fecomposite>
                  <fecolormatrix
                    type="matrix"
                    values="0 0 0 0 0.360784 0 0 0 0 0.231373 0 0 0 0 0.996078 0 0 0 0.12 0"
                  ></fecolormatrix>
                  <feblend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_3766_72680"
                  ></feblend>
                  <feblend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_3766_72680"
                    result="shape"
                  ></feblend>
                </filter>
                <clippath id="clip0_3766_72680">
                  <rect
                    width="343"
                    height="77"
                    fill="white"
                    transform="translate(0 9)"
                  ></rect>
                </clippath>
              </defs>
            </svg>
          </div>
          <div class="technologies__list">
            <motion.div
              class="technologies__list-item"
              {...fadeFromdownAnimation()}
            >
              <svg
                class="technologies__list-item-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="35"
                viewBox="0 0 34 35"
                fill="none"
              >
                <g clip-path="url(#clip0_4129_7033)">
                  <rect
                    x="1.3125"
                    y="1.03101"
                    width="31.375"
                    height="27.246"
                    rx="2.6875"
                    fill="#5C3BFE"
                    stroke="#5C3BFE"
                    stroke-width="0.625"
                  ></rect>
                  <rect
                    x="1.5"
                    y="1.0896"
                    width="25"
                    height="27"
                    rx="2.5"
                    fill="white"
                    stroke="#5C3BFE"
                  ></rect>
                  <rect
                    x="4.5"
                    y="4.0896"
                    width="19"
                    height="21"
                    rx="1.5"
                    fill="white"
                    stroke="#AD9DFF"
                  ></rect>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.5492 7.04279L14.7402 21.7536L12.2516 21.7536L8.88032 12.4443L11.3423 11.4684L13.2358 17.5015L16.7861 6.4234L19.5492 7.04279Z"
                    fill="#5C3BFE"
                  ></path>
                  <path
                    d="M14.7402 21.7536L14.7402 22.2536L15.1028 22.2536L15.2154 21.9089L14.7402 21.7536ZM19.5492 7.04279L20.0245 7.19815L20.1954 6.67523L19.6586 6.55489L19.5492 7.04279ZM12.2516 21.7536L11.7815 21.9238L11.9009 22.2536L12.2516 22.2536L12.2516 21.7536ZM8.88032 12.4443L8.69608 11.9795L8.24497 12.1583L8.4102 12.6146L8.88032 12.4443ZM11.3423 11.4684L11.8194 11.3187L11.6583 10.8054L11.1581 11.0036L11.3423 11.4684ZM13.2358 17.5015L12.7588 17.6512L13.712 17.6541L13.2358 17.5015ZM16.7861 6.4234L16.8954 5.9355L16.4494 5.83552L16.3099 6.2708L16.7861 6.4234ZM15.2154 21.9089L20.0245 7.19815L19.074 6.88742L14.2649 21.5982L15.2154 21.9089ZM12.2516 22.2536L14.7402 22.2536L14.7402 21.2536L12.2516 21.2536L12.2516 22.2536ZM8.4102 12.6146L11.7815 21.9238L12.7217 21.5833L9.35044 12.2741L8.4102 12.6146ZM11.1581 11.0036L8.69608 11.9795L9.06456 12.9091L11.5266 11.9333L11.1581 11.0036ZM13.7129 17.3518L11.8194 11.3187L10.8653 11.6182L12.7588 17.6512L13.7129 17.3518ZM16.3099 6.2708L12.7597 17.3489L13.712 17.6541L17.2622 6.57599L16.3099 6.2708ZM19.6586 6.55489L16.8954 5.9355L16.6767 6.91129L19.4399 7.53068L19.6586 6.55489Z"
                    fill="#5C3BFE"
                  ></path>
                </g>
                <defs>
                  <clippath id="clip0_4129_7033">
                    <rect
                      width="34"
                      height="34"
                      fill="white"
                      transform="translate(0 0.5896)"
                    ></rect>
                  </clippath>
                </defs>
              </svg>
              <div class="technologies__item-column">
                <p class="technologies__item-title">
                  <a href="/services/react-native/">React Native</a>
                </p>
                <p class="technologies__item-text">
                  It’s a great framework for mobile app development. With it,
                  you can make apps for both iOS and Android with a single
                  codebase — no need to hire two separate developer teams.
                </p>
              </div>
            </motion.div>
            <motion.div
              class="technologies__list-item"
              {...fadeFromdownAnimation(0.2)}
            >
              <svg
                class="technologies__list-item-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="35"
                viewBox="0 0 34 35"
                fill="none"
              >
                <g clip-path="url(#clip0_4129_7033)">
                  <rect
                    x="1.3125"
                    y="1.03101"
                    width="31.375"
                    height="27.246"
                    rx="2.6875"
                    fill="#5C3BFE"
                    stroke="#5C3BFE"
                    stroke-width="0.625"
                  ></rect>
                  <rect
                    x="1.5"
                    y="1.0896"
                    width="25"
                    height="27"
                    rx="2.5"
                    fill="white"
                    stroke="#5C3BFE"
                  ></rect>
                  <rect
                    x="4.5"
                    y="4.0896"
                    width="19"
                    height="21"
                    rx="1.5"
                    fill="white"
                    stroke="#AD9DFF"
                  ></rect>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.5492 7.04279L14.7402 21.7536L12.2516 21.7536L8.88032 12.4443L11.3423 11.4684L13.2358 17.5015L16.7861 6.4234L19.5492 7.04279Z"
                    fill="#5C3BFE"
                  ></path>
                  <path
                    d="M14.7402 21.7536L14.7402 22.2536L15.1028 22.2536L15.2154 21.9089L14.7402 21.7536ZM19.5492 7.04279L20.0245 7.19815L20.1954 6.67523L19.6586 6.55489L19.5492 7.04279ZM12.2516 21.7536L11.7815 21.9238L11.9009 22.2536L12.2516 22.2536L12.2516 21.7536ZM8.88032 12.4443L8.69608 11.9795L8.24497 12.1583L8.4102 12.6146L8.88032 12.4443ZM11.3423 11.4684L11.8194 11.3187L11.6583 10.8054L11.1581 11.0036L11.3423 11.4684ZM13.2358 17.5015L12.7588 17.6512L13.712 17.6541L13.2358 17.5015ZM16.7861 6.4234L16.8954 5.9355L16.4494 5.83552L16.3099 6.2708L16.7861 6.4234ZM15.2154 21.9089L20.0245 7.19815L19.074 6.88742L14.2649 21.5982L15.2154 21.9089ZM12.2516 22.2536L14.7402 22.2536L14.7402 21.2536L12.2516 21.2536L12.2516 22.2536ZM8.4102 12.6146L11.7815 21.9238L12.7217 21.5833L9.35044 12.2741L8.4102 12.6146ZM11.1581 11.0036L8.69608 11.9795L9.06456 12.9091L11.5266 11.9333L11.1581 11.0036ZM13.7129 17.3518L11.8194 11.3187L10.8653 11.6182L12.7588 17.6512L13.7129 17.3518ZM16.3099 6.2708L12.7597 17.3489L13.712 17.6541L17.2622 6.57599L16.3099 6.2708ZM19.6586 6.55489L16.8954 5.9355L16.6767 6.91129L19.4399 7.53068L19.6586 6.55489Z"
                    fill="#5C3BFE"
                  ></path>
                </g>
                <defs>
                  <clippath id="clip0_4129_7033">
                    <rect
                      width="34"
                      height="34"
                      fill="white"
                      transform="translate(0 0.5896)"
                    ></rect>
                  </clippath>
                </defs>
              </svg>
              <div class="technologies__item-column">
                <p class="technologies__item-title">
                  <a href="/services/react-js/">React.js</a>
                </p>
                <p class="technologies__item-text">
                  A framework for web-based software development, it can help
                  minimize bugs and shorten loading times.
                </p>
              </div>
            </motion.div>
            <motion.div
              class="technologies__list-item"
              {...fadeFromdownAnimation(0.4)}
            >
              <svg
                class="technologies__list-item-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="35"
                viewBox="0 0 34 35"
                fill="none"
              >
                <g clip-path="url(#clip0_4129_7033)">
                  <rect
                    x="1.3125"
                    y="1.03101"
                    width="31.375"
                    height="27.246"
                    rx="2.6875"
                    fill="#5C3BFE"
                    stroke="#5C3BFE"
                    stroke-width="0.625"
                  ></rect>
                  <rect
                    x="1.5"
                    y="1.0896"
                    width="25"
                    height="27"
                    rx="2.5"
                    fill="white"
                    stroke="#5C3BFE"
                  ></rect>
                  <rect
                    x="4.5"
                    y="4.0896"
                    width="19"
                    height="21"
                    rx="1.5"
                    fill="white"
                    stroke="#AD9DFF"
                  ></rect>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.5492 7.04279L14.7402 21.7536L12.2516 21.7536L8.88032 12.4443L11.3423 11.4684L13.2358 17.5015L16.7861 6.4234L19.5492 7.04279Z"
                    fill="#5C3BFE"
                  ></path>
                  <path
                    d="M14.7402 21.7536L14.7402 22.2536L15.1028 22.2536L15.2154 21.9089L14.7402 21.7536ZM19.5492 7.04279L20.0245 7.19815L20.1954 6.67523L19.6586 6.55489L19.5492 7.04279ZM12.2516 21.7536L11.7815 21.9238L11.9009 22.2536L12.2516 22.2536L12.2516 21.7536ZM8.88032 12.4443L8.69608 11.9795L8.24497 12.1583L8.4102 12.6146L8.88032 12.4443ZM11.3423 11.4684L11.8194 11.3187L11.6583 10.8054L11.1581 11.0036L11.3423 11.4684ZM13.2358 17.5015L12.7588 17.6512L13.712 17.6541L13.2358 17.5015ZM16.7861 6.4234L16.8954 5.9355L16.4494 5.83552L16.3099 6.2708L16.7861 6.4234ZM15.2154 21.9089L20.0245 7.19815L19.074 6.88742L14.2649 21.5982L15.2154 21.9089ZM12.2516 22.2536L14.7402 22.2536L14.7402 21.2536L12.2516 21.2536L12.2516 22.2536ZM8.4102 12.6146L11.7815 21.9238L12.7217 21.5833L9.35044 12.2741L8.4102 12.6146ZM11.1581 11.0036L8.69608 11.9795L9.06456 12.9091L11.5266 11.9333L11.1581 11.0036ZM13.7129 17.3518L11.8194 11.3187L10.8653 11.6182L12.7588 17.6512L13.7129 17.3518ZM16.3099 6.2708L12.7597 17.3489L13.712 17.6541L17.2622 6.57599L16.3099 6.2708ZM19.6586 6.55489L16.8954 5.9355L16.6767 6.91129L19.4399 7.53068L19.6586 6.55489Z"
                    fill="#5C3BFE"
                  ></path>
                </g>
                <defs>
                  <clippath id="clip0_4129_7033">
                    <rect
                      width="34"
                      height="34"
                      fill="white"
                      transform="translate(0 0.5896)"
                    ></rect>
                  </clippath>
                </defs>
              </svg>
              <div class="technologies__item-column">
                <p class="technologies__item-title">
                  <a href="/services/electron/">Electron.js</a>
                </p>
                <p class="technologies__item-text">
                  It’s perfect for desktop apps. You can create software that’s
                  compatible with MacOS and Windows in half the time — save
                  money on hiring multiple teams of developers.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechnologiesSection;
