import { motion } from "framer-motion";
import React from "react";

function MainSection() {
  const fadeFromLeftAnimation = {
    initial: {
      opacity: 0.4,
      x: -300,
    },
    whileInView: {
      opacity: 1,
      x: 0,
    },
    viewport: {
      once: true,
    },
    transition: {
      // duration:2,
      type: "spring",
      damping: 4,
      stiffness: 50,
      restDelta: 0.001,
    },
  };

  return (
    <section class="first-face">
      <div class="first-face__container">
        <div class="first-face__wrapper">
          <motion.h1
            class="first-face__title"
            {...fadeFromLeftAnimation}
            style={{
              lineBreak: "anywhere",
            }}
          >
            MVP
            <br class="first-face__title--br" />
            development
            <br class="first-face__title--br" />
            services
          </motion.h1>
          <motion.p
            class="first-face__subtitle"
            {...fadeFromLeftAnimation}
            transition={{
              ...fadeFromLeftAnimation.transition,
              delay: 0.2,
            }}
          >
            We’re an MVP development company with a proven track record. We’ll
            build an MVP in under 4 months with a limited budget. Tell us what
            you want — we’ll deliver a product that users love
          </motion.p>
          <a class="rising-btn rising-btn--theme2 js-scroll-to" href="/contact">
            <span class="rising-btn__inner">
              <span class="rising-btn__wrapper">
                <span class="rising-btn__text first-face__btn-text">
                  I WANT AN MVP
                </span>
              </span>
            </span>
          </a>
        </div>
        <div class="first-face__svg js-active">
          <svg
            class="first-face__img js-hide-img intro__svg"
            width="404"
            height="455"
            viewBox="0 0 404 455"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g class="intro__svg-lamp">
              <path
                d="M397.571 245.64C396.564 250.386 393.341 254.056 389.237 255.853C388.146 256.331 387.388 257.259 387.129 258.415L386.414 261.607C386.05 263.231 384.563 264.314 382.96 264.243C382.761 264.228 382.562 264.212 382.369 264.169L375.374 262.601C375.182 262.557 374.968 262.481 374.781 262.41C373.301 261.789 372.446 260.181 372.81 258.558L373.526 255.366C373.779 254.237 373.496 253.047 372.708 252.176C369.791 248.806 368.43 244.166 369.473 239.515C371.213 231.755 378.898 226.888 386.663 228.629C394.401 230.364 399.221 237.889 397.571 245.64Z"
                stroke="#AE9DFF"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M382.654 264.377L381.228 270.739C381.024 271.648 380.123 272.227 379.225 272.026L374.411 270.947C373.513 270.745 372.946 269.837 373.149 268.928L374.576 262.566C374.588 262.511 374.6 262.456 374.607 262.428C374.791 262.498 374.975 262.569 375.193 262.617L382.101 264.166C382.292 264.209 382.515 264.23 382.685 264.239C382.672 264.294 382.666 264.322 382.654 264.377Z"
                stroke="#AE9DFF"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M374.222 264.145C374.222 264.145 380.581 266.514 381.723 268.53"
                stroke="#AE9DFF"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M373.548 267.148C373.548 267.148 379.941 269.368 381.146 271.105"
                stroke="#AE9DFF"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
            </g>
            <circle
              class="intro__svg-scale"
              cx="9"
              cy="316"
              r="8"
              stroke="#AE9DFF"
              stroke-width="2"
            ></circle>
            <circle
              class="intro__svg-scale"
              cx="318.009"
              cy="6.00919"
              r="5.00919"
              stroke="#AE9DFF"
              stroke-width="2"
            ></circle>
            <circle
              class="intro__svg-scale"
              cx="397.5"
              cy="156.5"
              r="5.5"
              stroke="#AE9DFF"
              stroke-width="2"
            ></circle>
            <g class="intro__svg-rocket">
              <path
                d="M239.113 136.494C239.05 132.852 239.05 129.273 238.987 125.819C238.925 121.549 238.862 117.405 238.799 113.512C238.548 99.5719 238.172 88.5204 237.607 82.304C235.976 61.9593 209.126 17 196.704 17"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M237.67 82.3668C240.054 73.0108 242.689 65.978 245.261 65.978C252.538 65.978 259.439 108.3 260.944 125.819C261.007 126.573 261.07 127.263 261.07 127.891C262.325 147.106 262.575 181.767 262.45 216.428"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M261.258 310.429C261.321 305.97 261.446 300.256 261.572 293.663"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M196.704 17C184.283 17 157.495 61.9593 155.801 82.3668C155.3 88.5832 154.923 99.6347 154.609 113.575"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M132.213 310.428C131.523 275.83 129.767 168.141 132.401 127.891C132.464 127.263 132.527 126.573 132.527 125.819C133.97 108.3 140.871 65.978 148.21 65.978C150.783 65.978 153.417 73.0108 155.801 82.3668"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M240.242 139.695L239.113 136.493L231.02 113.511L226.817 101.643C225.876 98.9431 222.049 98.9431 221.108 101.643L216.905 113.511L207.683 139.695C202.789 153.51 200.28 168.077 200.28 182.771V293.662H247.519V182.771C247.645 168.14 245.135 153.51 240.242 139.695ZM223.994 173.666C219.916 173.666 216.654 170.401 216.654 166.319C216.654 162.238 219.916 158.973 223.994 158.973C228.071 158.973 231.334 162.301 231.334 166.319C231.396 170.338 228.071 173.666 223.994 173.666Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M283.905 293.726H247.582V182.772C247.582 182.772 251.66 194.828 262.325 216.429C264.834 221.515 267.72 227.103 270.982 233.194C279.2 248.327 283.905 267.981 283.905 293.726Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M132.59 125.819H154.484"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M238.987 125.819H260.882"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M196.704 97.374C202.837 97.374 207.808 92.398 207.808 86.2597C207.808 80.1215 202.837 75.1455 196.704 75.1455C190.572 75.1455 185.6 80.1215 185.6 86.2597C185.6 92.398 190.572 97.374 196.704 97.374Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M154.672 321.355V375.796H119.666V318.466C119.666 314.071 123.242 310.492 127.634 310.492H146.579V321.355H154.672Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M274.432 318.465V375.795H239.426V321.354H246.892V310.491H266.465C270.856 310.428 274.432 314.007 274.432 318.465Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M246.892 293.726V321.355H229.389V304.275H164.082V321.355H146.579V292.722C146.579 288.389 149.967 284.873 154.296 284.747C154.108 249.772 153.794 175.551 154.484 125.882C154.547 121.613 154.609 117.468 154.672 113.575H216.968L207.746 139.76C202.852 153.574 200.343 168.142 200.343 182.835C200.343 182.835 194.132 201.233 176.943 233.257C169.791 246.569 165.274 263.398 164.271 284.747C164.145 287.635 164.082 290.65 164.082 293.726H246.892V293.726Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M200.343 182.772V293.663H164.02C164.02 290.586 164.082 287.572 164.208 284.684C165.211 263.334 169.728 246.506 176.88 233.194C194.195 201.17 200.343 182.772 200.343 182.772Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M181.585 304.211V349.987H154.672V321.354H164.082V304.211H181.585Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M239.427 321.354V349.987H211.886V304.211H229.389V321.354H239.427Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M231.02 113.575H238.799"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M231.397 201.849C231.397 205.931 228.134 209.196 224.057 209.196C219.979 209.196 216.717 205.931 216.717 201.849C216.717 197.768 219.979 194.502 224.057 194.502C228.072 194.502 231.397 197.768 231.397 201.849Z"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
              ></path>
              <path
                d="M323.723 353.26H274.375"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
              ></path>
              <path
                d="M323.723 353.259C323.723 309.828 315.695 276.782 302 251.29C272.722 197.237 262.333 166.315 262.333 166.315"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
              ></path>
              <path
                d="M70 353.259C70 309.828 78.0279 276.782 91.7225 251.29C120.765 197.237 131.154 166.315 131.154 166.315"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
              ></path>
              <path
                d="M119.82 353.26H70"
                stroke="white"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
              ></path>
            </g>
            <g opacity="0.5" class="intro__svg-scale">
              <path
                d="M48 110.333V117"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M48 137V143.667"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M36.2167 115.217L40.9333 119.933"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M55.0667 134.067L59.7833 138.784"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M31.3333 127H38"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M58 127H64.6667"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M36.2167 138.784L40.9333 134.067"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M55.0667 119.933L59.7833 115.217"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <path
              class="intro__svg-ellipse"
              d="M352 437.5C352 437.784 351.877 438.154 351.458 438.622C351.033 439.095 350.354 439.611 349.377 440.152C347.425 441.233 344.481 442.306 340.6 443.346C332.853 445.42 321.598 447.299 307.635 448.881C279.724 452.042 241.139 454 198.5 454C155.861 454 117.276 452.042 89.3646 448.881C75.4024 447.299 64.1468 445.42 56.4001 443.346C52.5188 442.306 49.5753 441.233 47.6232 440.152C46.6465 439.611 45.967 439.095 45.5422 438.622C45.1227 438.154 45 437.784 45 437.5C45 437.216 45.1227 436.846 45.5422 436.378C45.967 435.905 46.6465 435.389 47.6232 434.848C49.5753 433.767 52.5188 432.694 56.4001 431.654C64.1468 429.58 75.4024 427.701 89.3646 426.119C117.276 422.958 155.861 421 198.5 421C241.139 421 279.724 422.958 307.635 426.119C321.598 427.701 332.853 429.58 340.6 431.654C344.481 432.694 347.425 433.767 349.377 434.848C350.354 435.389 351.033 435.905 351.458 436.378C351.877 436.846 352 437.216 352 437.5Z"
              stroke="white"
              stroke-width="2"
            ></path>
          </svg>
          <svg
            class="first-face__svg--mobile"
            width="377"
            height="463"
            viewBox="0 0 377 463"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M365.657 243.886C364.748 248.173 361.836 251.488 358.129 253.111C357.144 253.543 356.459 254.382 356.225 255.426L355.579 258.309C355.25 259.776 353.907 260.754 352.459 260.69C352.279 260.676 352.099 260.662 351.925 260.623L345.607 259.206C345.433 259.167 345.239 259.098 345.071 259.034C343.734 258.473 342.962 257.021 343.291 255.554L343.937 252.671C344.166 251.652 343.91 250.576 343.198 249.79C340.563 246.745 339.334 242.554 340.276 238.353C341.848 231.343 348.79 226.947 355.804 228.52C362.794 230.087 367.148 236.885 365.657 243.886Z"
              stroke="#AE9DFF"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M352.182 260.811L350.894 266.558C350.71 267.379 349.896 267.902 349.085 267.72L344.736 266.745C343.926 266.564 343.413 265.743 343.597 264.922L344.885 259.175C344.896 259.125 344.908 259.076 344.913 259.051C345.079 259.114 345.246 259.178 345.442 259.222L351.683 260.621C351.855 260.659 352.057 260.678 352.21 260.687C352.199 260.736 352.193 260.761 352.182 260.811Z"
              stroke="#AE9DFF"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M344.565 260.602C344.565 260.602 350.309 262.741 351.341 264.563"
              stroke="#AE9DFF"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M343.958 263.314C343.958 263.314 349.732 265.319 350.82 266.888"
              stroke="#AE9DFF"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <circle
              cx="14.6629"
              cy="307.442"
              r="7.22637"
              stroke="#AE9DFF"
              stroke-width="1.80659"
            ></circle>
            <circle
              cx="293.789"
              cy="27.4281"
              r="4.52479"
              stroke="#AE9DFF"
              stroke-width="1.80659"
            ></circle>
            <circle
              cx="365.594"
              cy="163.366"
              r="4.96813"
              stroke="#AE9DFF"
              stroke-width="1.80659"
            ></circle>
            <path
              d="M222.522 145.294C222.465 142.005 222.465 138.771 222.408 135.652C222.352 131.795 222.295 128.051 222.238 124.535C222.012 111.943 221.672 101.96 221.162 96.3448C219.688 77.9675 195.435 37.356 184.214 37.356"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M221.218 96.4016C223.372 87.9503 225.752 81.5977 228.075 81.5977C234.649 81.5977 240.882 119.827 242.242 135.652C242.299 136.333 242.355 136.956 242.355 137.524C243.489 154.88 243.715 186.189 243.602 217.499"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M242.527 302.409C242.584 298.382 242.697 293.22 242.81 287.265"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M184.215 37.356C172.995 37.356 148.798 77.9675 147.268 96.4016C146.814 102.017 146.474 112 146.191 124.591"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M125.961 302.409C125.337 271.156 123.751 173.881 126.131 137.524C126.188 136.956 126.244 136.333 126.244 135.652C127.548 119.827 133.781 81.5977 140.411 81.5977C142.735 81.5977 145.115 87.9503 147.268 96.4016"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M223.542 148.186L222.522 145.294L215.212 124.534L211.415 113.814C210.565 111.375 207.108 111.375 206.258 113.814L202.461 124.534L194.131 148.186C189.711 160.665 187.444 173.824 187.444 187.096V287.264H230.115V187.096C230.229 173.881 227.962 160.665 223.542 148.186ZM208.865 178.872C205.181 178.872 202.235 175.923 202.235 172.236C202.235 168.549 205.181 165.6 208.865 165.6C212.548 165.6 215.495 168.606 215.495 172.236C215.552 175.866 212.548 178.872 208.865 178.872Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M262.983 287.322H230.172V187.097C230.172 187.097 233.856 197.987 243.489 217.499C245.756 222.093 248.363 227.142 251.309 232.643C258.733 246.313 262.983 264.066 262.983 287.322Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M126.3 135.652H146.077"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M222.409 135.652H242.186"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M184.215 109.957C189.754 109.957 194.245 105.463 194.245 99.9179C194.245 94.3732 189.754 89.8784 184.215 89.8784C178.675 89.8784 174.185 94.3732 174.185 99.9179C174.185 105.463 178.675 109.957 184.215 109.957Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M146.247 312.278V361.455H114.626V309.669C114.626 305.699 117.857 302.466 121.823 302.466H138.937V312.278H146.247Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M254.425 309.669V361.454H222.805V312.278H229.548V302.465H247.229C251.195 302.408 254.425 305.641 254.425 309.669Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M229.549 287.322V312.279H213.739V296.851H154.747V312.279H138.937V286.414C138.937 282.5 141.997 279.324 145.907 279.211C145.737 247.618 145.454 180.575 146.077 135.709C146.134 131.852 146.191 128.108 146.247 124.592H202.518L194.188 148.244C189.768 160.722 187.501 173.882 187.501 187.154C187.501 187.154 181.891 203.773 166.364 232.7C159.904 244.725 155.824 259.926 154.917 279.211C154.804 281.82 154.747 284.542 154.747 287.322H229.549V287.322Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M187.501 187.097V287.265H154.69C154.69 284.486 154.747 281.763 154.86 279.154C155.767 259.869 159.847 244.668 166.307 232.643C181.948 203.716 187.501 187.097 187.501 187.097Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M170.559 296.793V338.142H146.248V312.278H154.748V296.793H170.559Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M222.805 312.278V338.142H197.928V296.793H213.739V312.278H222.805Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M215.212 124.592H222.239"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M215.552 204.33C215.552 208.016 212.606 210.966 208.922 210.966C205.239 210.966 202.292 208.016 202.292 204.33C202.292 200.643 205.239 197.693 208.922 197.693C212.549 197.693 215.552 200.643 215.552 204.33Z"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M298.951 341.098H254.375"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
              stroke-linecap="round"
            ></path>
            <path
              d="M298.951 341.098C298.951 301.866 291.699 272.016 279.329 248.989C252.882 200.163 243.498 172.232 243.498 172.232"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
              stroke-linecap="round"
            ></path>
            <path
              d="M69.7637 341.098C69.7637 301.866 77.0152 272.016 89.3855 248.989C115.619 200.163 125.003 172.232 125.003 172.232"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
              stroke-linecap="round"
            ></path>
            <path
              d="M114.766 341.098H69.7637"
              stroke="white"
              stroke-width="1.80659"
              stroke-miterlimit="10"
              stroke-linecap="round"
            ></path>
            <g opacity="0.5">
              <path
                d="M49.8921 121.664V127.686"
                stroke="white"
                stroke-width="1.80659"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M49.8921 145.752V151.774"
                stroke="white"
                stroke-width="1.80659"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M39.2485 126.075L43.5091 130.335"
                stroke="white"
                stroke-width="1.80659"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M56.2754 143.102L60.5359 147.363"
                stroke="white"
                stroke-width="1.80659"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M34.8369 136.719H40.8589"
                stroke="white"
                stroke-width="1.80659"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M58.9253 136.719H64.9473"
                stroke="white"
                stroke-width="1.80659"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M39.2485 147.363L43.5091 143.102"
                stroke="white"
                stroke-width="1.80659"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M56.2754 130.335L60.5359 126.075"
                stroke="white"
                stroke-width="1.80659"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <path
              d="M324.494 417.192C324.494 417.449 324.383 417.783 324.004 418.206C323.621 418.634 323.007 419.1 322.125 419.588C320.361 420.564 317.702 421.534 314.197 422.473C307.199 424.347 297.032 426.044 284.42 427.473C259.208 430.328 224.353 432.097 185.838 432.097C147.323 432.097 112.469 430.328 87.2565 427.473C74.6445 426.044 64.4774 424.347 57.4797 422.473C53.9738 421.534 51.315 420.564 49.5516 419.588C48.6694 419.1 48.0555 418.634 47.6719 418.206C47.293 417.783 47.1821 417.449 47.1821 417.192C47.1821 416.936 47.293 416.602 47.6719 416.179C48.0555 415.751 48.6694 415.285 49.5516 414.797C51.315 413.821 53.9738 412.851 57.4797 411.912C64.4774 410.038 74.6445 408.341 87.2565 406.912C112.469 404.057 147.323 402.288 185.838 402.288C224.353 402.288 259.208 404.057 284.42 406.912C297.032 408.341 307.199 410.038 314.197 411.912C317.702 412.851 320.361 413.821 322.125 414.797C323.007 415.285 323.621 415.751 324.004 416.179C324.383 416.602 324.494 416.936 324.494 417.192Z"
              stroke="white"
              stroke-width="1.80659"
            ></path>
          </svg>
        </div>
      </div>
    </section>
  );
}

export default MainSection;
