import React from "react";
import { Link } from "react-router-dom";
import { CASE_DATA } from "../../../pages/case-study/data";

const MenuItems = (props) => {
  const { parentMenu, secondParentMenu, activeMenu, data } = props;

  return (
    <React.Fragment>
      <li className={parentMenu === "home" ? " current-menu-item" : ""}>
        <Link to="" as="/">
          Home
        </Link>
      </li>
      <li className={parentMenu === "services" ? "current-menu-item" : ""}>
        <Link
          to="#"
          className={activeMenu === "/services" ? "active-menu" : ""}
        >
          Services
        </Link>
        <ul className="sub-menu">
          {data &&
            data.map((item, i) => (
              <li key={i}>
                <Link to={item.service_url} as={item.service_url}>
                  {item.heading}
                </Link>
              </li>
            ))}
        </ul>
      </li>
      <li className={parentMenu === "case-study" ? "current-menu-item" : ""}>
        <Link
          to="#"
          className={activeMenu === "/case-study" ? "active-menu" : ""}
        >
          Case-Study
        </Link>
        <ul className="sub-menu">
          {CASE_DATA &&
            Object.keys(CASE_DATA).map((item, i) => (
              <li key={i}>
                <Link to={`/case-study/${item}`} as={item}>
                  {item.split("_").join(" ")}
                </Link>
              </li>
            ))}
        </ul>
      </li>

      <li className={parentMenu === "contact" ? "current-menu-item" : ""}>
        <Link
          to="/contact"
          className={activeMenu === "/contact" ? "active-menu" : ""}
        >
          Contact
        </Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
