import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CASE_DATA } from "../../../pages/case-study/data";

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu, data }) => {
  const [services, setServices] = useState(false);
  const [menu, setMenu] = useState("");

  const openMobileMenu = (menu) => {
    setMenu(menu);
  };

  return (
    <div className="container relative">
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li>
              <Link
                to="/home"
                className={activeMenu === "/home" ? "active-menu" : ""}
              >
                Home
              </Link>
            </li>
            <li
              className={
                menu === "services"
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("services");
                }}
              >
                Service
              </Link>
              <ul
                className={
                  menu === "services" ? "sub-menu current-menu" : "sub-menu"
                }
              >
                {data &&
                  data.map((item, i) => (
                    <li key={i}>
                      <Link
                        to={item.service_url}
                        as={item.service_url}
                        className={
                          activeMenu === item.service_url ? "active-menu" : ""
                        }
                      >
                        {item.heading}
                      </Link>
                    </li>
                  ))}
              </ul>
            </li>
            <li
              className={
                menu === "case-study"
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("case-study");
                }}
              >
                Case Study
              </Link>
              <ul
                className={
                  menu === "case-study" ? "sub-menu current-menu" : "sub-menu"
                }
              >
                {CASE_DATA &&
                  Object.keys(CASE_DATA).map((item, i) => (
                    <li key={i}>
                      <Link
                        to={`/case-study/${item}`}
                        as={item}
                        className={activeMenu === item ? "active-menu" : ""}
                      >
                        {item.split("_").join(" ")}
                      </Link>
                    </li>
                  ))}
              </ul>
            </li>

            <li>
              <Link
                to="/contact"
                className={activeMenu === "/contact" ? "active-menu" : ""}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
