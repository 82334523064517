import dateFns from "date-fns";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";

import { ConfirmButton } from "./Confirm";
import { Failed, Success } from "./Feedback";
import { FailedIcon, SuccessIcon } from "./Icons";
import { Popup, PopupClose, PopupHeader, PopupWrapper } from "./Popup";

import Calendar from "./calendar";
import TimeSlots from "./time-slots";

import moment from "moment-timezone";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
import CustomSelect from "../../Contact/Timezone";
import { preventPastDays } from "./validators";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCalendarAlt, faGlobe } from "@fortawesome/free-solid-svg-icons";

function DayTimePicker({
  timeSlotValidator,
  timeSlotSizeMinutes,
  isLoading,
  isDone,
  err,
  onConfirm,
  confirmText,
  loadingText,
  doneText,
  theme,
}) {
  const { parseTimezone } = useTimezoneSelect({
    labelStyle: "original",
    allTimezones,
  });

  const [pickedDay, setPickedDay] = useState(null);
  const [pickedTime, setPickedTime] = useState(null);
  const [showPickTime, setShowPickTime] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [timezoneOffset, setTimezoneOffset] = useState(
    parseTimezone(moment.tz.guess()).value,
  );
  const [timezone, setTimezone] = useState({
    altName: parseTimezone(moment.tz.guess()).altName,
    value: parseTimezone(moment.tz.guess()).value,
  });

  const handlePickDay = (day) => {
    const timezonedDate = moment(day).tz(timezone.altName);

    console.log("timezonedDate", timezonedDate);
    setPickedDay(day);
    setShowPickTime(true);
  };

  const handlePickTime = (time) => {
    setPickedTime(time);
    setShowPickTime(false);
    setShowConfirm(true);
    handleConfirm(time);
  };

  const handleClosePickTime = () => {
    setShowPickTime(false);
  };

  const handleConfirm = (time) => {
    onConfirm(time, timezone);
  };

  const handleCloseConfirm = () => {
    setShowConfirm(false);
    setShowPickTime(true);
  };

  const handleTimeZoneOffset = (offset) => {
    setTimezoneOffset(offset.offset);
    setTimezone({ altName: offset.altName, value: offset.value });
  };

  return (
    <ThemeProvider theme={theme}>
      <PopupWrapper>
        <Calendar validator={preventPastDays} pickDay={handlePickDay} />
        <div>
          <p className="mb-0">Select your timezone</p>
          <CustomSelect onChange={handleTimeZoneOffset} />
        </div>
        {showPickTime && (
          <Popup
            style={{
              background: theme.background,
            }}
          >
            <PopupHeader>
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <p>
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                  {dateFns.format(pickedDay, "dddd, MMMM Do, YYYY")}
                </p>
                <p>
                  <FontAwesomeIcon icon={faGlobe} /> Timezone:{" "}
                  {timezone.altName}
                </p>
              </div>
              <p>
                <PopupClose onClick={handleClosePickTime}> &#8592; </PopupClose>
              </p>
            </PopupHeader>

            <TimeSlots
              pickedDay={pickedDay}
              slotSizeMinutes={timeSlotSizeMinutes}
              validator={timeSlotValidator}
              pickTime={handlePickTime}
              timezoneOffset={timezoneOffset}
            />
          </Popup>
        )}

        {showConfirm && (
          <Popup>
            <PopupHeader>
              <p>{dateFns.format(pickedTime, "dddd, MMMM Do, YYYY")}</p>

              <p>{dateFns.format(pickedTime, "hh:mm A")}</p>

              {!isDone && (
                <p>
                  <PopupClose disabled={isLoading} onClick={handleCloseConfirm}>
                    &#8592;
                  </PopupClose>
                </p>
              )}
            </PopupHeader>

            {!isDone ? (
              <ConfirmButton disabled={isLoading} onClick={handleConfirm}>
                {isLoading ? loadingText : confirmText}
              </ConfirmButton>
            ) : doneText ? (
              <Success>
                <p>
                  <SuccessIcon /> {doneText}
                </p>
              </Success>
            ) : null}

            {err && (
              <Failed>
                <p>
                  <FailedIcon /> {err}
                </p>
              </Failed>
            )}
          </Popup>
        )}
      </PopupWrapper>
    </ThemeProvider>
  );
}

DayTimePicker.propTypes = {
  timeSlotValidator: PropTypes.func,
  timeSlotSizeMinutes: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDone: PropTypes.bool.isRequired,
  err: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  loadingText: PropTypes.string,
  doneText: PropTypes.string,
  theme: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    background: PropTypes.string,
    buttons: PropTypes.shape({
      disabled: PropTypes.shape({
        color: PropTypes.string,
        background: PropTypes.string,
      }),
      confirm: PropTypes.shape({
        color: PropTypes.string,
        background: PropTypes.string,
        hover: PropTypes.shape({
          color: PropTypes.string,
          background: PropTypes.string,
        }),
      }),
    }),
  }),
};

DayTimePicker.defaultProps = {
  confirmText: "Schedule",
  loadingText: "Scheduling..",
  doneText: "Your event has been scheduled!",
  theme: {
    primary: "#3a9ad9",
    secondary: "#f0f0f0",
    background: "#fff",
    buttons: {
      disabled: {
        color: "#333",
        background: "#dfdfdf",
      },
      confirm: {
        color: "#fff",
        background: "#3a9ad9",
        hover: {
          color: "",
          background: "#3a9ad9d6",
        },
      },
    },
    feedback: {
      success: {
        color: "#29aba4",
      },
      failed: {
        color: "#eb7260",
      },
    },
  },
};

export default DayTimePicker;
