import axios from "axios";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Loader from "../../components/Common/Loader";
import Footer from "../../components/Layout/Footer";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ServiceMain from "./ServiceMain";

const BACKEND_API = process.env.REACT_APP_BACKEND_BASEURL;

const HomeEleven = () => {
  const searchParamas = new URLSearchParams(window.location.search);
  const layout = searchParamas.get("lay") ? searchParamas.get("lay") : "1";

  const { service } = useParams();

  const [data, setData] = React.useState(null);

  const getServiceData = async () => {
    try {
      const resp = await axios.get(`${BACKEND_API}/services/` + service + "/");
      setData(resp.data);
    } catch (e) {
      setData(null);
    }
  };

  useEffect(() => {
    getServiceData();
    // refresh page

    return () => {
      setData(null);
    };
  }, [service]);

  if (!data) {
    return <Loader />;
  }

  if (layout === "1" || layout === "")
    return (
      <React.Fragment>
        <div className="">
          <OffWrap />
          <HeaderStyleThree parentMenu="service" />

          <ServiceMain data={data} />
          <Footer />
          <SearchModal />
        </div>
      </React.Fragment>
    );
  else {
    return (
      <div>
        <h1>Wrong Layout</h1>
      </div>
    );
  }
};

export default HomeEleven;
