import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import footerLogo1 from "../../../assets/img/logo/logo-2.png";

const BACKEND_API = process.env.REACT_APP_BACKEND_BASEURL;

const Footer = (props) => {
  const [data, setData] = React.useState(null);

  const getServiceData = async () => {
    try {
      const resp = await axios.get(`${BACKEND_API}/services/`);
      setData(resp.data);
    } catch (e) {
      setData(null);
    }
  };

  useEffect(() => {
    getServiceData();
  }, []);

  const { footerLogo, footerClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="footer-logo">
                <Link to="/">
                  <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
              <h3 className="widget-title">IT Services</h3>
              <ul className="site-map">
                {data?.map((item, i) => (
                  <li key={i}>
                    <Link to={item.service_url} as={item.service_url}>
                      {item.heading}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
              <h3 className="widget-title">Contact Info</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc">
                    15, Pallavi Row House, opp. Memnagar, Navrangpura, Ahmedabad
                  </div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <Link to="#">(+91) 8107115738</Link>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <Link to="#">connect@urbanoinfotech.com</Link>
                  </div>
                </li>
                <li>
                  <i className="flaticon-clock"></i>
                  <div className="desc">Opening Hours: 10:00 - 18:00</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
