import { motion } from "framer-motion";
import React from "react";

function AdvantagesSection() {
  const fadeFromdownAnimation = (delay = 0) => ({
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
    transition: {
      // delay:1
      delay,
      duration: 0.7,
    },
  });

  const fadeFromUpAnimation = (delay = 0) => ({
    initial: {
      opacity: 0,
      y: -150,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
    transition: {
      // delay:1
      delay,
      duration: 0.7,
    },
  });

  return (
    <section class="advantages">
      <div class="container">
        <div class="advantages__inner">
          <motion.h2
            class="advantages__title block-title"
            {...fadeFromUpAnimation()}
          >
            <span class="advantages__title--stroke-text">Why choose us</span>
            <br />
            to develop an MVP
          </motion.h2>
          <div class="advantages__wrapper">
            <motion.div class="advantages__item" {...fadeFromdownAnimation(0)}>
              <svg
                class="advantages__item-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="125"
                height="126"
                viewBox="0 0 125 126"
                fill="none"
              >
                <path
                  d="M109.914 50.3895C104.097 45.9868 96.2642 44.197 89.2781 44.029C79.3677 43.8057 77.2429 42.6519 74.8407 41.3887C69.9217 38.7919 67.4971 35.5885 67.1688 35.2949C62.0315 30.668 58.3746 29.0614 51.982 26.8427C37.11 21.7335 16.805 24.3525 7.25209 40.9834C-0.612805 54.7077 0.541825 68.872 9.46667 82.1882C18.2592 95.3008 38.6131 101.121 53.3491 100.092C58.4775 99.729 63.0972 97.5246 68.1392 96.7111C68.1392 96.7111 75.9471 97.7209 77.5783 98.3265C83.8767 100.824 90.2796 102.529 96.9681 102.239C105.069 101.9 113.016 98.0412 117.418 91.055C125.692 77.6437 122.581 60.0442 109.914 50.3895Z"
                  fill="#EEEBFF"
                ></path>
                <path
                  d="M62.9238 52.9814C62.9238 40.0028 52.4025 29.4814 39.4238 29.4814H37.9238C25.7736 29.4814 15.9238 39.3312 15.9238 51.4814C15.9238 63.6317 25.7736 73.4814 37.9238 73.4814H62.9238V52.9814Z"
                  fill="white"
                ></path>
                <path
                  d="M58.6465 54.3704C58.6465 41.8826 48.5231 31.7593 36.0354 31.7593C23.5476 31.7593 13.4243 41.8826 13.4243 54.3704C13.4243 66.8582 23.5476 76.9815 36.0354 76.9815H58.6465V54.3704Z"
                  stroke="#5C3BFE"
                ></path>
                <path
                  d="M36.0356 55.9464C35.1653 55.9464 34.4598 55.2409 34.4598 54.3707C34.4598 53.5004 35.1653 52.7949 36.0356 52.7949C36.9058 52.7949 37.6113 53.5004 37.6113 54.3707C37.6113 55.2409 36.9058 55.9464 36.0356 55.9464Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M27.1059 55.9464C26.2356 55.9464 25.5301 55.2409 25.5301 54.3707C25.5301 53.5004 26.2356 52.7949 27.1059 52.7949C27.9762 52.7949 28.6816 53.5004 28.6816 54.3707C28.6816 55.2409 27.9762 55.9464 27.1059 55.9464Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M44.9643 55.9464C44.094 55.9464 43.3885 55.2409 43.3885 54.3707C43.3885 53.5004 44.094 52.7949 44.9643 52.7949C45.8345 52.7949 46.54 53.5004 46.54 54.3707C46.54 55.2409 45.8345 55.9464 44.9643 55.9464Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M53.8682 57.2592C53.8682 39.985 67.8717 25.9814 85.1459 25.9814C102.42 25.9814 116.424 39.985 116.424 57.2592C116.424 74.5335 102.42 88.537 85.1459 88.537H53.8682V57.2592Z"
                  fill="white"
                  stroke="#5C3BFE"
                ></path>
                <path
                  d="M53.8682 57.2592C53.8682 39.985 67.8717 25.9814 85.1459 25.9814C102.42 25.9814 116.424 39.985 116.424 57.2592C116.424 74.5335 102.42 88.537 85.1459 88.537H53.8682V57.2592Z"
                  fill="white"
                  stroke="#5C3BFE"
                ></path>
                <path
                  d="M85.1471 59.4261C86.3438 59.4261 87.3138 58.4561 87.3138 57.2594C87.3138 56.0628 86.3438 55.0928 85.1471 55.0928C83.9505 55.0928 82.9805 56.0628 82.9805 57.2594C82.9805 58.4561 83.9505 59.4261 85.1471 59.4261Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M97.4245 59.4261C98.6211 59.4261 99.5911 58.4561 99.5911 57.2594C99.5911 56.0628 98.6211 55.0928 97.4245 55.0928C96.2279 55.0928 95.2578 56.0628 95.2578 57.2594C95.2578 58.4561 96.2279 59.4261 97.4245 59.4261Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M72.8688 59.4261C74.0654 59.4261 75.0355 58.4561 75.0355 57.2594C75.0355 56.0628 74.0654 55.0928 72.8688 55.0928C71.6722 55.0928 70.7021 56.0628 70.7021 57.2594C70.7021 58.4561 71.6722 59.4261 72.8688 59.4261Z"
                  fill="#5C3BFE"
                ></path>
              </svg>
              <div class="advantages__item-column">
                <p class="advantages__item-title">
                  Stay up to date with the development process
                </p>
                <p class="advantages__item-text">
                  You’ll be the first to find out how the process goes. And If
                  you’ve thought of new ways to make the product better, just
                  tell us — we’ll integrate them.
                </p>
              </div>
            </motion.div>
            <motion.div
              class="advantages__item"
              {...fadeFromdownAnimation(0.2)}
            >
              <svg
                class="advantages__item-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="125"
                height="126"
                viewBox="0 0 125 126"
                fill="none"
              >
                <path
                  d="M0.00649173 55.0712C-0.394558 38.6358 17.8866 36.0487 27.0773 36.8096C39.443 34.9834 72.6966 40.3706 106.786 76.5286C149.397 121.726 30.5864 133.596 27.0773 112.139C23.5683 90.6813 0.507804 75.6155 0.00649173 55.0712Z"
                  fill="#EEEBFF"
                ></path>
                <path
                  d="M4.52352 114.078L11.5814 81.4186C11.7803 80.4981 12.5945 79.8411 13.5363 79.8411H24.5857C25.5396 79.8411 26.3608 79.1674 26.5472 78.2319L32.948 46.1092C33.1344 45.1737 33.9555 44.5 34.9094 44.5H71.2544C72.2016 44.5 73.0189 45.1644 73.2123 46.0916L76.5717 62.1963C76.7651 63.1235 77.5824 63.7879 78.5296 63.7879H82.2495C83.2003 63.7879 84.0197 64.4573 84.2093 65.389L87.6709 82.3988C87.8605 83.3306 88.6799 84 89.6307 84H97.3849C98.3272 84 99.1417 84.6577 99.3401 85.5789L105.479 114.079C105.747 115.324 104.798 116.5 103.523 116.5H44.822H6.47839C5.20367 116.5 4.25426 115.323 4.52352 114.078Z"
                  fill="white"
                ></path>
                <path
                  d="M42 6.91579H65.1388V15.1608H81.6666L77.2985 24.5836L81.6666 34.9488H57.8194V27.0571H42V6.91579Z"
                  fill="#EEEBFF"
                ></path>
                <path
                  d="M42 44.5V27.0571M42 3.5V6.91579M42 6.91579H65.1388V15.1608M42 6.91579V27.0571M42 27.0571H57.8194M65.1388 15.1608V27.0571H57.8194M65.1388 15.1608H81.6666L77.2985 24.5836L81.6666 34.9488H57.8194V27.0571"
                  stroke="#5C3BFE"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M31.0974 79.7792H17.1753C16.2463 79.7792 15.4395 80.419 15.2279 81.3237L7.57436 114.044C7.28087 115.299 8.23318 116.5 9.5218 116.5H51.2581H115.096C116.374 116.5 117.324 115.317 117.049 114.068L109.82 81.3478C109.618 80.4315 108.806 79.7792 107.867 79.7792H97.5443M31.0974 79.7792L38.3918 45.957C38.5905 45.0361 39.4048 44.3787 40.3469 44.3787H80.0794C81.0142 44.3787 81.8244 45.0263 82.0302 45.9382L86.0403 63.699M31.0974 79.7792H40.4148C41.2957 79.7792 42.0728 80.3555 42.3286 81.1984L45.9769 93.2202M97.5443 79.7792L93.9808 65.2234C93.7617 64.3284 92.9595 63.699 92.0381 63.699H86.0403M97.5443 79.7792L99.9101 92.3072M86.0403 63.699H70.4508"
                  stroke="#5C3BFE"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div class="advantages__item-column">
                <p class="advantages__item-title">
                  Get custom software development that suits your needs
                </p>
                <p class="advantages__item-text">
                  Doesn’t matter if it’s an app for meditations, an admin panel
                  for a major company, or load planning software. We have a
                  sizable portfolio of
                  <a href="/portfolio/" class="advantages__link">
                    300 projects
                  </a>
                  — rest assured, we have experience in your field.
                </p>
              </div>
            </motion.div>
            <motion.div
              class="advantages__item"
              {...fadeFromdownAnimation(0.4)}
            >
              <svg
                class="advantages__item-svg"
                width="121"
                height="120"
                viewBox="0 0 121 120"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4129_3317)">
                  <rect
                    x="34.0744"
                    y="3.90203"
                    width="54.775"
                    height="107.528"
                    rx="6.73722"
                    transform="rotate(-0.344546 34.0744 3.90203)"
                    stroke="#5C3BFE"
                    stroke-width="0.85"
                  ></rect>
                  <path
                    d="M36.5762 16.6978L36.6463 103.962C36.6485 106.753 38.9139 109.012 41.7045 109.008L82.2075 108.94C85.0053 108.935 87.266 106.657 87.2488 103.859L86.7103 16.4275C86.6931 13.6392 84.4193 11.3925 81.631 11.4089L41.5963 11.644C38.8175 11.6604 36.574 13.9189 36.5762 16.6978Z"
                    stroke="#5C3BFE"
                    stroke-width="0.85"
                    stroke-miterlimit="10"
                  ></path>
                  <path
                    d="M67.0746 8.24526C67.5699 8.24443 67.9708 7.84224 67.9699 7.34695C67.9691 6.85165 67.5669 6.4508 67.0716 6.45163C66.5763 6.45245 66.1755 6.85464 66.1763 7.34994C66.1771 7.84524 66.5793 8.24609 67.0746 8.24526Z"
                    stroke="#5C3BFE"
                    stroke-width="0.909802"
                    stroke-miterlimit="10"
                  ></path>
                  <rect
                    x="56.9762"
                    y="6.39074"
                    width="7.65021"
                    height="1.91255"
                    rx="0.956276"
                    stroke="#5C3BFE"
                    stroke-width="0.956276"
                  ></rect>
                  <rect
                    x="53.868"
                    y="104.648"
                    width="15.7786"
                    height="0.478138"
                    rx="0.239069"
                    fill="#5C3BFE"
                    stroke="#5C3BFE"
                    stroke-width="0.478138"
                  ></rect>
                  <path
                    d="M55.2647 51.0401H55.0859L54.9927 51.1927L52.4179 55.4072L49.8431 51.1927L49.7499 51.0401H49.5711H47.1738H46.8551V51.3589V61.847V62.1657H47.1738H49.5711H49.8899V61.847V56.8844L52.0109 60.3647L52.104 60.5176H52.2831H52.5528H52.7318L52.8249 60.3647L54.9459 56.8844V61.847V62.1657H55.2647H57.662H57.9807V61.847V51.3589V51.0401H57.662H55.2647ZM65.1882 62.1657H65.4196L65.4913 61.9457L68.9074 51.4576L69.0434 51.0401H68.6043H65.9823H65.7454L65.6771 51.267L63.6599 57.9691L61.6428 51.267L61.5745 51.0401H61.3375H58.7155H58.2764L58.4124 51.4576L61.8286 61.9457L61.9002 62.1657H62.1316H65.1882ZM69.6555 51.0401H69.3367V51.3589V61.847V62.1657H69.6555H72.0528H72.3715V61.847V58.8695H73.626C74.7446 58.8695 75.6962 58.4896 76.4585 57.7273C77.2265 56.9593 77.6156 56.0296 77.6156 54.9548C77.6156 53.88 77.2265 52.9503 76.4585 52.1823C75.6962 51.42 74.7446 51.0401 73.626 51.0401H69.6555ZM73.626 55.9845H72.3715V53.9251H73.626C73.9112 53.9251 74.1294 54.0211 74.3058 54.2121L74.54 53.9959L74.3058 54.2121C74.4869 54.4083 74.5808 54.6489 74.5808 54.9548C74.5808 55.2607 74.4869 55.5013 74.3058 55.6975L74.54 55.9137L74.3058 55.6975C74.1294 55.8886 73.9112 55.9845 73.626 55.9845Z"
                    stroke="#5C3BFE"
                    stroke-width="0.637505"
                  ></path>
                  <path
                    d="M71.0694 35.2619C70.9353 34.8186 71.3303 34.4012 71.7656 34.5599L78.68 36.4662C79.6542 36.7076 80.6156 36.1933 80.9138 35.2096L82.8114 28.2397C83.0529 27.2657 82.5387 26.3045 81.5548 26.0065L74.7634 24.1374C74.3378 24.0354 74.1746 23.4221 74.5323 23.1277C77.3281 20.7257 81.2448 19.7641 85.1138 20.9091C91.1301 22.6776 94.6443 29.2461 92.8085 35.2145C92.5849 35.9522 92.2853 36.5864 91.929 37.2303L119.809 74.7793C119.875 74.8262 119.951 74.9298 119.97 75.0431C121.748 77.5952 121.177 81.0737 118.634 82.9079C116.081 84.6854 112.602 84.1149 110.768 81.5725C110.692 81.4689 110.682 81.4123 110.606 81.3087L84.7917 42.8817C82.8948 43.3812 80.9107 43.3709 78.8961 42.8413C75.0368 41.7529 72.1993 38.7992 71.0694 35.2619ZM116.552 79.9415C117.494 79.3139 117.687 78.0568 117.06 77.1149C116.432 76.1731 115.175 75.9802 114.233 76.6077C113.291 77.2353 113.098 78.4924 113.725 79.4343C114.353 80.3761 115.61 80.569 116.552 79.9415Z"
                    fill="white"
                  ></path>
                  <path
                    d="M68.1437 37.3701C68.0095 36.9267 68.4045 36.5094 68.8398 36.6681L75.7542 38.5744C76.7284 38.8157 77.6899 38.3014 77.988 37.3178L79.8857 30.3479C80.1271 29.3739 79.6129 28.4126 78.629 28.1146L71.8376 26.2456C71.412 26.1435 71.2488 25.5303 71.6065 25.2359C74.4023 22.8339 78.319 21.8722 82.188 23.0172C88.2043 24.7858 91.7185 31.3543 89.8827 37.3226C89.6591 38.0604 89.3595 38.6946 89.0032 39.3385L116.883 76.8874C116.949 76.9344 117.025 77.0379 117.045 77.1512C118.822 79.7033 118.251 83.1819 115.708 85.016C113.155 86.7935 109.676 86.2231 107.842 83.6806C107.766 83.5771 107.756 83.5204 107.68 83.4169L81.8659 44.9899C79.969 45.4893 77.985 45.4791 75.9703 44.9494C72.111 43.8611 69.2736 40.9074 68.1437 37.3701ZM113.626 82.0496C114.569 81.4221 114.762 80.165 114.134 79.2231C113.507 78.2812 112.249 78.0883 111.307 78.7159C110.365 79.3434 110.172 80.6006 110.799 81.5424C111.427 82.4843 112.684 82.6772 113.626 82.0496Z"
                    fill="white"
                    stroke="#5C3BFE"
                    stroke-width="0.85"
                    stroke-miterlimit="10"
                  ></path>
                  <path
                    d="M87.773 41.3544L108.923 71.2541"
                    stroke="#5C3BFE"
                    stroke-width="0.85"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M84.2521 43.9405L97.4938 62.7186"
                    stroke="#5C3BFE"
                    stroke-width="0.85"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M99.0337 64.9007L101.192 67.9708"
                    stroke="white"
                    stroke-width="0.85"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
                <defs>
                  <clippath id="clip0_4129_3317">
                    <rect
                      width="120"
                      height="120"
                      fill="white"
                      transform="translate(0.5)"
                    ></rect>
                  </clippath>
                </defs>
              </svg>
              <div class="advantages__item-column">
                <p class="advantages__item-title">
                  Integrate changes on the fly
                </p>
                <p class="advantages__item-text">
                  If you’ve thought of new features along the way, or the
                  requirements of your app have to change constantly, we’ll do
                  our best to accommodate. We use Agile methodology and split
                  the development process into 2-week sprints. You can change
                  features and strategy on the fly if the market has shifted.
                </p>
              </div>
            </motion.div>
            <motion.div
              class="advantages__item"
              {...fadeFromdownAnimation(0.6)}
            >
              <svg
                class="advantages__item-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="125"
                height="126"
                viewBox="0 0 125 126"
                fill="none"
              >
                <path
                  d="M115.61 68.2576C111.532 61.9691 104.553 57.3633 97.8153 54.5884C88.252 50.666 86.5991 48.793 84.7155 46.714C80.8622 42.4473 79.6541 38.5492 79.4402 38.1523C76.1041 31.9092 73.1231 29.0395 67.6999 24.5733C55.0645 14.2321 34.3648 9.06666 19.092 21.0062C6.50634 30.8661 2.5393 44.5168 6.43812 60.2879C10.2814 75.8195 27.9957 88.8784 42.7048 93.4407C47.8255 95.0241 53.1132 94.6982 58.3118 95.8286C58.3118 95.8286 65.5466 99.697 66.9163 100.873C72.1477 105.564 77.7654 109.555 84.3781 111.791C92.3828 114.51 101.502 113.888 108.297 109.018C121.168 99.6038 124.466 82.0143 115.61 68.2576Z"
                  fill="#EEEBFF"
                ></path>
                <rect
                  x="21.7422"
                  y="26.5"
                  width="86"
                  height="80"
                  rx="11"
                  fill="white"
                ></rect>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M59.3085 51.1594C59.3085 51.7057 58.8656 52.1486 58.3193 52.1486C57.773 52.1486 57.3301 51.7057 57.3301 51.1594C57.3301 50.6131 57.773 50.1702 58.3193 50.1702C58.8656 50.1702 59.3085 50.6131 59.3085 51.1594Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M70.1708 51.1594C70.1708 51.7057 69.7279 52.1486 69.1816 52.1486C68.6353 52.1486 68.1924 51.7057 68.1924 51.1594C68.1924 50.6131 68.6353 50.1702 69.1816 50.1702C69.7279 50.1702 70.1708 50.6131 70.1708 51.1594Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M81.0312 51.1594C81.0312 51.7057 80.5883 52.1486 80.042 52.1486C79.4956 52.1486 79.0527 51.7057 79.0527 51.1594C79.0527 50.6131 79.4956 50.1702 80.042 50.1702C80.5883 50.1702 81.0312 50.6131 81.0312 51.1594Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M91.8935 51.1594C91.8935 51.7057 91.4506 52.1486 90.9043 52.1486C90.3579 52.1486 89.915 51.7057 89.915 51.1594C89.915 50.6131 90.3579 50.1702 90.9043 50.1702C91.4506 50.1702 91.8935 50.6131 91.8935 51.1594Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M102.756 51.1594C102.756 51.7057 102.313 52.1486 101.767 52.1486C101.22 52.1486 100.777 51.7057 100.777 51.1594C100.777 50.6131 101.22 50.1702 101.767 50.1702C102.313 50.1702 102.756 50.6131 102.756 51.1594Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37.5839 63.5727C37.5839 64.1191 37.141 64.5619 36.5947 64.5619C36.0484 64.5619 35.6055 64.1191 35.6055 63.5727C35.6055 63.0264 36.0484 62.5835 36.5947 62.5835C37.141 62.5835 37.5839 63.0264 37.5839 63.5727Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M48.4462 63.5727C48.4462 64.1191 48.0033 64.5619 47.457 64.5619C46.9107 64.5619 46.4678 64.1191 46.4678 63.5727C46.4678 63.0264 46.9107 62.5835 47.457 62.5835C48.0033 62.5835 48.4462 63.0264 48.4462 63.5727Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M59.3085 63.5727C59.3085 64.1191 58.8656 64.5619 58.3193 64.5619C57.773 64.5619 57.3301 64.1191 57.3301 63.5727C57.3301 63.0264 57.773 62.5835 58.3193 62.5835C58.8656 62.5835 59.3085 63.0264 59.3085 63.5727Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M70.1708 63.5727C70.1708 64.1191 69.7279 64.5619 69.1816 64.5619C68.6353 64.5619 68.1924 64.1191 68.1924 63.5727C68.1924 63.0264 68.6353 62.5835 69.1816 62.5835C69.7279 62.5835 70.1708 63.0264 70.1708 63.5727Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M81.0312 63.5727C81.0312 64.1191 80.5883 64.5619 80.042 64.5619C79.4956 64.5619 79.0527 64.1191 79.0527 63.5727C79.0527 63.0264 79.4956 62.5835 80.042 62.5835C80.5883 62.5835 81.0312 63.0264 81.0312 63.5727Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M91.8935 63.5727C91.8935 64.1191 91.4506 64.5619 90.9043 64.5619C90.3579 64.5619 89.915 64.1191 89.915 63.5727C89.915 63.0264 90.3579 62.5835 90.9043 62.5835C91.4506 62.5835 91.8935 63.0264 91.8935 63.5727Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M102.756 63.5727C102.756 64.1191 102.313 64.5619 101.767 64.5619C101.22 64.5619 100.777 64.1191 100.777 63.5727C100.777 63.0264 101.22 62.5835 101.767 62.5835C102.313 62.5835 102.756 63.0264 102.756 63.5727Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37.5839 74.435C37.5839 74.9814 37.141 75.4243 36.5947 75.4243C36.0484 75.4243 35.6055 74.9814 35.6055 74.435C35.6055 73.8887 36.0484 73.4458 36.5947 73.4458C37.141 73.4458 37.5839 73.8887 37.5839 74.435Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M48.4462 74.435C48.4462 74.9814 48.0033 75.4243 47.457 75.4243C46.9107 75.4243 46.4678 74.9814 46.4678 74.435C46.4678 73.8887 46.9107 73.4458 47.457 73.4458C48.0033 73.4458 48.4462 73.8887 48.4462 74.435Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M59.3085 74.435C59.3085 74.9814 58.8656 75.4243 58.3193 75.4243C57.773 75.4243 57.3301 74.9814 57.3301 74.435C57.3301 73.8887 57.773 73.4458 58.3193 73.4458C58.8656 73.4458 59.3085 73.8887 59.3085 74.435Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M70.1708 74.435C70.1708 74.9814 69.7279 75.4243 69.1816 75.4243C68.6353 75.4243 68.1924 74.9814 68.1924 74.435C68.1924 73.8887 68.6353 73.4458 69.1816 73.4458C69.7279 73.4458 70.1708 73.8887 70.1708 74.435Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M81.0312 74.435C81.0312 74.9814 80.5883 75.4243 80.042 75.4243C79.4956 75.4243 79.0527 74.9814 79.0527 74.435C79.0527 73.8887 79.4956 73.4458 80.042 73.4458C80.5883 73.4458 81.0312 73.8887 81.0312 74.435Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M91.8935 74.435C91.8935 74.9814 91.4506 75.4243 90.9043 75.4243C90.3579 75.4243 89.915 74.9814 89.915 74.435C89.915 73.8887 90.3579 73.4458 90.9043 73.4458C91.4506 73.4458 91.8935 73.8887 91.8935 74.435Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M102.756 74.435C102.756 74.9814 102.313 75.4243 101.767 75.4243C101.22 75.4243 100.777 74.9814 100.777 74.435C100.777 73.8887 101.22 73.4458 101.767 73.4458C102.313 73.4458 102.756 73.8887 102.756 74.435Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M37.5839 86.8486C37.5839 87.3949 37.141 87.8378 36.5947 87.8378C36.0484 87.8378 35.6055 87.3949 35.6055 86.8486C35.6055 86.3023 36.0484 85.8594 36.5947 85.8594C37.141 85.8594 37.5839 86.3023 37.5839 86.8486Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M48.4462 86.8486C48.4462 87.3949 48.0033 87.8378 47.457 87.8378C46.9107 87.8378 46.4678 87.3949 46.4678 86.8486C46.4678 86.3023 46.9107 85.8594 47.457 85.8594C48.0033 85.8594 48.4462 86.3023 48.4462 86.8486Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M59.3085 86.8486C59.3085 87.3949 58.8656 87.8378 58.3193 87.8378C57.773 87.8378 57.3301 87.3949 57.3301 86.8486C57.3301 86.3023 57.773 85.8594 58.3193 85.8594C58.8656 85.8594 59.3085 86.3023 59.3085 86.8486Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M70.1708 86.8486C70.1708 87.3949 69.7279 87.8378 69.1816 87.8378C68.6353 87.8378 68.1924 87.3949 68.1924 86.8486C68.1924 86.3023 68.6353 85.8594 69.1816 85.8594C69.7279 85.8594 70.1708 86.3023 70.1708 86.8486Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M81.0312 86.8486C81.0312 87.3949 80.5883 87.8378 80.042 87.8378C79.4956 87.8378 79.0527 87.3949 79.0527 86.8486C79.0527 86.3023 79.4956 85.8594 80.042 85.8594C80.5883 85.8594 81.0312 86.3023 81.0312 86.8486Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M53.7422 21.98C54.0183 21.98 54.2422 21.7561 54.2422 21.48C54.2422 21.2038 54.0183 20.98 53.7422 20.98V21.98ZM25.7422 21.48V20.98H25.2422V21.48H25.7422ZM25.7422 100.98H25.2422V101.48H25.7422V100.98ZM112.242 100.98V101.48H112.742V100.98H112.242ZM112.242 21.48H112.742V20.98H112.242V21.48ZM53.7422 20.98H25.7422V21.98H53.7422V20.98ZM25.7422 101.48H112.242V100.48H25.7422V101.48ZM112.242 20.98H89.7422V21.98H112.242V20.98ZM25.2422 21.48V38.48H26.2422V21.48H25.2422ZM25.2422 38.48V100.98H26.2422V38.48H25.2422ZM112.742 100.98V38.48H111.742V100.98H112.742ZM112.742 38.48V21.48H111.742V38.48H112.742ZM25.7422 38.98H112.242V37.98H25.7422V38.98Z"
                  fill="#5C3BFE"
                ></path>
                <path
                  d="M82.7422 28.48C86.6082 28.48 89.7422 25.346 89.7422 21.48C89.7422 17.614 86.6082 14.48 82.7422 14.48"
                  stroke="#5C3BFE"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M72.7422 28.48C76.6082 28.48 79.7422 25.346 79.7422 21.48C79.7422 17.614 76.6082 14.48 72.7422 14.48"
                  stroke="#5C3BFE"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M62.7422 28.48C66.6082 28.48 69.7422 25.346 69.7422 21.48C69.7422 17.614 66.6082 14.48 62.7422 14.48"
                  stroke="#5C3BFE"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M52.3358 28.0531C53.7264 28.5623 55.2427 28.6174 56.6665 28.2104C58.0903 27.8034 59.3484 26.9553 60.2598 25.7881C61.1712 24.6209 61.689 23.1948 61.7387 21.7148C61.7884 20.2347 61.3674 18.7771 60.5363 17.5514C59.7052 16.3257 58.5069 15.3952 57.1135 14.8937C55.7202 14.3921 54.2037 14.3453 52.7821 14.7601C51.3605 15.1748 50.1071 16.0297 49.202 17.2019C48.297 18.374 47.787 19.803 47.7454 21.2832"
                  stroke="#5C3BFE"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M34.0053 103.983C47.1978 103.983 57.8924 93.2417 57.8924 79.9916C57.8924 66.7414 47.1978 56 34.0053 56C20.8128 56 10.1182 66.7414 10.1182 79.9916C10.1182 93.2417 20.8128 103.983 34.0053 103.983Z"
                  fill="white"
                ></path>
                <path
                  d="M31.3871 104.197C44.5796 104.197 55.2742 93.4558 55.2742 80.2057C55.2742 66.9555 44.5796 56.2141 31.3871 56.2141C18.1946 56.2141 7.5 66.9555 7.5 80.2057C7.5 93.4558 18.1946 104.197 31.3871 104.197Z"
                  stroke="#5C3BFE"
                  stroke-width="0.863892"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M31.8025 98.7229C41.9241 98.7229 50.1293 90.5176 50.1293 80.396C50.1293 70.2743 41.9241 62.0691 31.8025 62.0691C21.6808 62.0691 13.4756 70.2743 13.4756 80.396C13.4756 90.5176 21.6808 98.7229 31.8025 98.7229Z"
                  stroke="#5C3BFE"
                  stroke-width="0.863892"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M31.3877 80.2058V68.4956"
                  stroke="#5C3BFE"
                  stroke-width="0.863892"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M31.3877 80.2056H43.0469"
                  stroke="#5C3BFE"
                  stroke-width="0.863892"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
              <div class="advantages__item-column">
                <p class="advantages__item-title">
                  Release your product
                  <br />
                  in the shortest time
                </p>
                <p class="advantages__item-text">
                  We typically release MVPs in about 4 months. It costs around
                  $40,000. Other MVP development companies may have a different
                  estimate. For this price, you’ll get a product that’s ready to
                  be adored by customers.
                </p>
              </div>
            </motion.div>
          </div>
          <a
            class="advantages__button rising-btn rising-btn--theme3 js-scroll-to"
            href="/services/mvp/#footer"
          >
            <span class="rising-btn__inner">
              <span class="rising-btn__wrapper">
                <span class="rising-btn__text first-face__btn-text">
                  Discuss MVP pricing and timeline
                </span>
              </span>
            </span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default AdvantagesSection;
