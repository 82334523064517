import moment from "moment-timezone";
import Select from "react-select";
import { allTimezones, useTimezoneSelect } from "react-timezone-select";
const labelStyle = "original";

const CustomSelect = ({ onChange }) => {
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    allTimezones,
  });

  const getCurrentTime = (timezoneOffset) => {
    const date = new Date();
    const utc = date.getTime() + date.getTimezoneOffset() * 60000;
    const newDate = new Date(utc + 3600000 * timezoneOffset);
    return newDate;
  };

  return (
    <Select
      className="w-100"
      defaultValue={parseTimezone(moment.tz.guess())}
      options={options}
      onChange={onChange}
      getOptionLabel={(option) =>
        `${option.altName} ${getCurrentTime(option.offset).toLocaleTimeString(
          [],
          { hour: "2-digit", minute: "2-digit" },
        )}`
      }
      formatOptionLabel={(option) => (
        <div className="d-flex justify-content-between align-items-center">
          <span>{option.label}</span>
          <span>
            {getCurrentTime(option.offset).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </div>
      )}
      getOptionValue={parseTimezone.value}
    />
  );
};

export default CustomSelect;
