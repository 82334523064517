import styled from "styled-components";

export const PopupWrapper = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  margin: auto;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Popup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: ${(props) => props.theme.background};
  font-size: 1.1em;
`;

export const PopupHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
`;

export const PopupClose = styled.button`
  width: 50px;
  height: 50px;
  margin: 0;
  border: 0;
  background: none;
  font-size: 1.4em;
  color: ${(props) => props.theme.primary};

  :hover {
    cursor: pointer;
    background-color: aliceblue;
    border-radius: 100%;
  }

  :disabled {
    cursor: not-allowed;
    color: ${(props) => props.theme.buttons.disabled.color};
  }
`;
