import bannerbg from "../../assets/img/breadcrumbs/4.jpg";

export const CASE_DATA = {
  mcommerce: {
    title: "AI-powered Voice and Text Commerce",
    category: "IT Services",
    bannerImage: bannerbg,
    content: [
      {
        type: "heading",
        content: "About Client",
      },
      {
        type: "paragraph",
        content:
          "The client sought Urbano Infotech's expertise to create an advanced AI-powered voice and text commerce platform for wholesale distributors of US.",
      },
      {
        type: "heading",
        content: "Solution and Approach",
      },
      {
        type: "paragraph",
        content:
          "Urbano Infotech created a solution that optimizes the efficiency of inside sales and customer support teams. It allows customers, whether on a job site, warehouse, or any remote location, to effortlessly find information, get answers, and place orders using voice or text. The system integrates with popular communication platforms like Facebook, WhatsApp, and Microsoft Teams, facilitating direct interactions with agents for a seamless and responsive customer experience.",
      },
      {
        type: "heading",
        content: "Impact and Achivement",
      },
      {
        type: "list",
        content: [
          "ChatBot Integration",
          "Agent based chat",
          "Advance Searching",
          "Website based Q&A",
          "Integration with 4+ channels including Whatsapp, teams, facebook, sms",
        ],
      },
      {
        type: "heading",
        content: "Technology Used",
      },
      {
        type: "paragraph",
        content:
          "Rasa, LLM, OpenAI, GCP, Azure, Elastic Search, Java, MySQL, Kafka",
      },
    ],
  },
  calibration: {
    title: "Calibration in Pharma Industry",
    category: "IT Services",
    bannerImage: bannerbg,
    content: [
      {
        type: "heading",
        content: "About Client",
      },
      {
        type: "paragraph",
        content:
          "Client is a leading calibration company in Pharma sector. Client wanted a specialized calibration software designed specifically for the pharmaceutical sector. This software should automate the entire calibration process to reduce human errors.",
      },
      {
        type: "heading",
        content: "Solution and Approach",
      },
      {
        type: "paragraph",
        content:
          "Urbano Infotech created a calibration software designed specifically for the pharmaceutical sector. This software automates the entire calibration process, from data entry to calculations, certificate generation & management, dramatically reducing the likelihood of human error and ensuring accuracy and consistency for each device's specific needs. The software can run in offline mode.",
      },
      {
        type: "heading",
        content: "Impact and Achivement",
      },
      {
        type: "list",
        content: [
          "Centralized platform for data management",
          "integrity and accessibility of important calibration data",
          "Equipment, Instrument & Certificate Managemnt",
          "Company Management",
          "Dashboarding & Offline Working",
        ],
      },
      {
        type: "heading",
        content: "Technology Used",
      },
      {
        type: "paragraph",
        content: "Django, GCP, Postgres, Mongodb, PWA",
      },
    ],
  },
  cybersecurity: {
    title: "CyberSecurity Posture Management",
    category: "IT Services",
    bannerImage: bannerbg,
    content: [
      {
        type: "heading",
        content: "About Client",
      },
      {
        type: "paragraph",
        content:
          "Client required to create a SAAS platform for cloud based cybersecurity posture management based product. Product will comprise of multiple cloud like AWS, GCP, Azure.",
      },
      {
        type: "heading",
        content: "Solution and Approach",
      },
      {
        type: "paragraph",
        content:
          "Created a multitenant SAAS based platform will comprise of multiple cloud like AWS, GCP with 13 compliances including IS0,CCM and where client has  important modules like inventory management, customizable compliance, real time scan, notifications, RBAC, anomaly detection, malware detection etc. 43 cloud services were integrated with the portal for realtime monitoring & remediation.",
      },
      {
        type: "heading",
        content: "Impact and Achivement",
      },
      {
        type: "list",
        content: [
          "Realtime cloud monitoring across different cloud platforms.",
          "Threat detection any anomaly tracking.",
          "Auto Remediation of resources.",
          "Malware Detection",
        ],
      },
      {
        type: "heading",
        content: "Technology Used",
      },
      {
        type: "paragraph",
        content:
          "Django, React, AWS, GCP, Docker, Terraform, Kubernetees, Jenkins, Postgres, Mongodb, GraphQL, elastic search",
      },
    ],
  },
  agriculture: {
    title: "Reinventing the Indian agriculture",
    category: "IT Services",
    bannerImage: bannerbg,
    content: [
      {
        type: "heading",
        content: "About Client",
      },
      {
        type: "paragraph",
        content:
          "Client wanted to create a mobile application & web-based ERP where data for agriculture like Seeds, Fertilizers, Pesticides etc. can be stored and managed and easily accessible to both farmers & vendors. This will help farmers to easily get details of their location, buy respective items & easily sell their yield by Mobile Applications to Vendors.",
      },
      {
        type: "heading",
        content: "Solution and Approach",
      },
      {
        type: "paragraph",
        content:
          "Urbano Infotech created an Django based ERP system which has RBAC and client's team can login & manage their informations, connect with farmers and vendors and manage their inventory. Urbano Infotech created a mobile app on React Native for farmers where they can login and buy items, connect with farmer support, get information about correct facilities, buy crops, seeds, fertilizer etc. and sell their items with complete transparency along with market rates.",
      },
      {
        type: "heading",
        content: "Impact and Achivement",
      },
      {
        type: "list",
        content: [
          "Inventory Management",
          "Invoice & Order Management",
          "Android & IOS application",
          "RBAC",
        ],
      },
      {
        type: "heading",
        content: "Technology Used",
      },
      {
        type: "paragraph",
        content: "Django, React Native, Postgres, GCP",
      },
    ],
  },
  online_events: {
    title: "Customizable Event Portal for Online Events",
    category: "IT Services",
    bannerImage: bannerbg,
    content: [
      {
        type: "heading",
        content: "About Client",
      },
      {
        type: "paragraph",
        content:
          "Client is a event based company which organized large scale event for different enterprises. Due to covid-19 panedamic physical events were paused. Client wanted to give physical event like interaction in online events.",
      },
      {
        type: "heading",
        content: "Solution and Approach",
      },
      {
        type: "paragraph",
        content:
          "Urbano Infotech developed an event portal to facilitate virtual gatherings and events. This platform provided a comprehensive solution for event management, including features for online registrations, live streaming from auditorium, audience interaction with chat, stalls for vendors and analytics.",
      },
      {
        type: "heading",
        content: "Impact and Achivement",
      },
      {
        type: "list",
        content: [
          "User Interaction with Chat",
          "Sponsors Stall with video connect functionality",
          "Auditorium Streaming",
          "Gaming Zone for Attendees",
        ],
      },
      {
        type: "heading",
        content: "Technology Used",
      },
      {
        type: "paragraph",
        content: "Django, React Native, Postgres, GCP",
      },
    ],
  },
};
