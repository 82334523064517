import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

//Custom Components

import LoadTop from "../components/Common/ScrollTop/LoadTop";
import Error from "../pages/404";
import Appointment from "../pages/appointment";
import Contact from "../pages/contact";
import Home from "../pages/home";
import Landing from "../pages/landing";
import Landing2 from "../pages/landing2";
import Service from "../pages/service";
import Team from "../pages/team";
import CaseStudy from "../pages/case-study";

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services/:service" component={Service} />
          <Route path="/about" component={Contact} />
          <Route path="/contact" component={Contact} />
          <Route path="/team" component={Team} />
          <Route path="/landing" component={Landing} />
          <Route path="/landing2" component={Landing2} />
          <Route path="/appointment" component={Appointment} />
          <Route path="/case-study/:study" component={CaseStudy} />

          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
