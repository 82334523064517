import React from "react";
import Footer from "../../components/Layout/Footer";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import Contact2 from "../contact/Contact2";
import AdvantagesSection from "./AdvantagesSection";
import CompanySection from "./CompanySection";
import ComparisonSection from "./ComparisonSection";
import MainSection from "./MainSection";
import MethodsSection from "./MethodsSection";
import ServicesSection from "./ServicesSection";
import StagesSection from "./StagesSection";
import TechnologiesSection from "./TechnologiesSection";
import "./index.css";

function index() {
  return (
    <>
      <HeaderStyleThree parentMenu="home" />
      <div class="landing-page">
        <MainSection />

        <CompanySection />
        <ComparisonSection />
        {/* <WaysSection /> */}
        <ServicesSection />
        <AdvantagesSection />
        <StagesSection />
        <MethodsSection />
        <TechnologiesSection />
      </div>
      <Contact2 />
      <Footer />
    </>
  );
}

export default index;
