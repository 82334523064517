import React from "react";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import ScrollToTop from "../../components/Common/ScrollTop";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SingleTeam from "../../components/Team/SingleTeam";

// Breadcrumbs Bg
import breadcrumbsImg1 from "../../assets/img/breadcrumbs/4.jpg";

// Team Images
import teamImg1 from "../../assets/img/team/1.jpg";
import teamImg2 from "../../assets/img/team/2.jpg";
import teamImg3 from "../../assets/img/team/3.jpg";
import teamImg4 from "../../assets/img/team/4.jpg";
import teamImg5 from "../../assets/img/team/5.jpg";
import teamImg6 from "../../assets/img/team/6.jpg";
import teamImg7 from "../../assets/img/team/7.jpg";
import teamImg8 from "../../assets/img/team/8.jpg";
import teamImg9 from "../../assets/img/team/9.jpg";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";

const Team = () => {
  const data = [
    {
      name: "Makhaia Antitni",
      designation: "President & CEO",
      image: teamImg1,
    },
    {
      name: "Corey Anderson",
      designation: "CEO & Founder",
      image: teamImg2,
    },
    {
      name: "Masud Rana",
      designation: "Web Developer",
      image: teamImg3,
    },
    {
      name: "Rafian Sagar",
      designation: "Digital Marketer",
      image: teamImg4,
    },
    {
      name: "Israt Jahan",
      designation: "Design Lead",
      image: teamImg5,
    },
    {
      name: "Abu Sayed",
      designation: "App Developer",
      image: teamImg6,
    },
    {
      name: "Sonia Akhter",
      designation: "Graphic Artist",
      image: teamImg7,
    },
    {
      name: "Rayhan Ali",
      designation: "Manager",
      image: teamImg8,
    },
    {
      name: "Benjir Akther",
      designation: "Graphic Designer",
      image: teamImg9,
    },
  ];

  return (
    <React.Fragment>
      <HeaderStyleThree parentMenu="team" />

      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Our Team"
        breadcrumbsImg={breadcrumbsImg1}
        pageName="Our Team"
      />
      {/* breadcrumb-area-start */}
      <div className="rs-team modify1 pt-120 pb-94 md-pt-80 md-pb-74">
        <div className="container">
          <div className="row">
            {data.map((item, index) => (
              <div className="col-lg-4 col-md-6 mb-50">
                <SingleTeam
                  itemClass="team-item-wrap"
                  teamImage={item.image}
                  teamURL={item.name}
                  Title={item.name}
                  Designation={item.designation}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default Team;
