import SectionTitle from "../../components/Common/SectionTitle";
import SingleServiceTwo from "../../components/Service/SingleServiceTwo";

// Service Image Icons

const Service = ({ data }) => {
  return (
    <div
      id="rs-service"
      className="rs-services style3 modify3 rs-rain-animate  pb-120  md-pb-80"
    >
      <div className="container">
        <div className="row md-mb-60">
          <div className="col-lg-6 mb-60 md-mb-20">
            <SectionTitle
              sectionClass="sec-title2 zIndex1"
              subtitleClass="sub-text "
              subtitle={data.exponent_heading}
              titleClass="title testi-title "
              title={data.heading}
            />
          </div>
          <div className="col-lg-6 mb-60 md-mb-0">
            <div className="btn-part text-right mt-60 md-mt-0 md-text-left">
              <a className="readon started" href="#">
                Get Started
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          {/* dynamic sections */}
          {data.service_card_container?.map((service, index) => (
            <div className="col-lg-4 col-md-6 mb-20" key={index}>
              <SingleServiceTwo
                itemClass={service.itemClass || "services-item"}
                prefix={index + 1}
                mainIcon={service.icon_image_url}
                hoverIcon={service.icon_image_url}
                Title={service.heading}
                Text={service.description}
                isNavigate={false}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="line-inner orange-dark">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
    </div>
  );
};

export default Service;
