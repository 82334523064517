import React from "react";

import Footer from "../../components/Layout/Footer";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import Contact2 from "../contact/Contact2";
import "./index.css";

import bnrAnimImg from "../../assets/img/banner/b4-dot-6.png";
import bannerimg from "../../assets/img/banner/bannerhome-6.png";

function Index() {
  const data = {
    title: "Confused About Tech Choices for Your MVP? ",
    desc: "Overwhelmed by Tech Options? Urbano Infotech is Here to Make MVP Development Easier for You.",
    btnText: "Free Consultation",
    img: bannerimg,
    bnrAnimImg: bnrAnimImg,
  };

  return (
    <>
      <HeaderStyleThree parentMenu="services" />
      <div
        className="page-wrapper overflow-hidden "
        style={{
          width: "100%",
        }}
      >
        <div className="container-large">
          <section className="hero-section padding-vertical padding-custom1 display-flex-horizontal is-mobile-vertical">
            <div className="column _50 is-tablet-full">
              <div
                data-w-id="ea5c988f-3038-9dbb-6c2a-f6187dcea450"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="main-text text-size-small yext-color-secondary text-weight-xbold margin-bottom margin-xsmall"
              >
                MVP Development Service
              </div>
              <h1
                data-w-id="68bf3c75-9e3d-74b4-9ebe-498cbef89723"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="main-heading heading-xlarge primary-font margin-bottom margin-small text-weight-xbold"
              >
                <span className="text-span-gradient">Launching a startup?</span>
                <br />
                Tech choices can be overwhelming.
              </h1>
              <p
                data-w-id="209ea2bb-7046-42a4-40b6-6a3745faafb9"
                style={{
                  opacity: 1,
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  transformStyle: "preserve-3d",
                }}
                className="main-paragraph primary-font text-size-medium margin-bottom margin-medium text-weight-light"
              >
                If you're feeling lost, Urbano Infotech understands and is here
                to help.
                <br />
                At Urbano Infotech, we simplify MVP development with 'Low Code'
                and 'No Code' tools. Focus on your vision, not tech hassles.
              </p>
              <div className="column min-height max-width-50 is-tablet-full">
                <img
                  className="image-hero border-radius"
                  src="https://assets.website-files.com/6213b7f6a9920d6592a74693/62416057edb3e3b5612c2159_dsfdzsd.jpg"
                  alt=""
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  sizes="(max-width: 479px) 140px, (max-width: 767px) 61vw, (max-width: 991px) 425px, 325px"
                  data-w-id="6900a3a9-965a-744e-ef5f-c46bc1ecebd7"
                  loading="lazy"
                  srcset="https://assets.website-files.com/6213b7f6a9920d6592a74693/62416057edb3e3b5612c2159_dsfdzsd-p-500.jpeg 500w, https://assets.website-files.com/6213b7f6a9920d6592a74693/62416057edb3e3b5612c2159_dsfdzsd-p-800.jpeg 800w, https://assets.website-files.com/6213b7f6a9920d6592a74693/62416057edb3e3b5612c2159_dsfdzsd-p-1080.jpeg 1080w, https://assets.website-files.com/6213b7f6a9920d6592a74693/62416057edb3e3b5612c2159_dsfdzsd-p-1600.jpeg 1600w, https://assets.website-files.com/6213b7f6a9920d6592a74693/62416057edb3e3b5612c2159_dsfdzsd.jpg 2000w"
                />
                <img
                  className="image-hero absolute border-radius"
                  src="https://assets.website-files.com/6213b7f6a9920d6592a74693/6241606648a09be4b43c1b2d_5143730.jpg"
                  alt=""
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  sizes="(max-width: 479px) 73vw, (max-width: 767px) 69vw, (max-width: 991px) 450px, 410px"
                  data-w-id="10e438fb-c904-0cbf-106d-131b4d9b511b"
                  loading="lazy"
                  srcset="https://assets.website-files.com/6213b7f6a9920d6592a74693/6241606648a09be4b43c1b2d_5143730-p-500.jpeg 500w, https://assets.website-files.com/6213b7f6a9920d6592a74693/6241606648a09be4b43c1b2d_5143730-p-800.jpeg 800w, https://assets.website-files.com/6213b7f6a9920d6592a74693/6241606648a09be4b43c1b2d_5143730.jpg 1000w"
                />
                <div
                  data-w-id="82ccf66f-d1f2-e640-7f9e-fcb6f67fe24a"
                  style={{ opacity: 1 }}
                  className="avatar-wrap display-flex-horizontal border-radius position-absolute box-shadow"
                />
                <img
                  src="https://assets.website-files.com/6213b7f6a9920d6592a74693/62416019e14a9f92b23a0656_jack.jpg"
                  loading="lazy"
                  alt=""
                  sizes="(max-width: 991px) 24px, (max-width: 1439px) 2vw, 24px"
                  srcset="https://assets.website-files.com/6213b7f6a9920d6592a74693/62416019e14a9f92b23a0656_jack-p-500.jpeg 500w, https://assets.website-files.com/6213b7f6a9920d6592a74693/62416019e14a9f92b23a0656_jack-p-800.jpeg 800w, https://assets.website-files.com/6213b7f6a9920d6592a74693/62416019e14a9f92b23a0656_jack.jpg 1000w"
                  className="avatar-image"
                />
                <div className="wrap-text-avatar">
                  <div className="main-text avatar text-weight-bold primary-font">
                    Lee Stone
                  </div>
                  <div className="main-text avatar primary-font">Lee Stone</div>
                </div>
              </div>
              <div
                data-w-id="54ab0c68-38b8-43c2-564a-8e3d110355d5"
                style={{ opacity: 1 }}
                className="avatar-wrap display-flex-horizontal border-radius position-absolute box-shadow second"
              >
                <img
                  src="https://assets.website-files.com/6213b7f6a9920d6592a74693/6213b7f6a9920d1790a7469c_suni.jpg"
                  loading="lazy"
                  alt=""
                  className="avatar-image second"
                />
                <div className="wrap-text-avatar second">
                  <div className="main-text avatar text-weight-bold primary-font second">
                    Mia Potter
                  </div>
                  <div className="main-text avatar second">Digital creator</div>
                </div>
              </div>
            </div>
          </section>{" "}
        </div>
      </div>
      <Contact2 />
      <Footer />
    </>
  );
}

export default Index;
