import React from "react";
import CategoriesWidget from "../Widget/CategoriesWidget";
import RecentPostWidget from "../Widget/RecentPostWidget";
import SearchWidget from "../Widget/SearchWidget";

const BlogDetailsMain = ({ content }) => {
  return (
    <div className="rs-inner-blog pt-120 pb-120 md-pt-90 md-pb-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 pr-34 md-pr-14">
            <div className="blog-details">
              <div className="blog-full">
                {content.map((item, index) => {
                  if (item.type === "heading") {
                    return <Heading key={index} heading={item.content} />;
                  } else if (item.type === "list") {
                    return <List key={index} list={item.content} />;
                  } else if (item.type === "paragraph") {
                    return <Paragraph key={index} content={item.content} />;
                  } else if (item.type === "image") {
                    return <Image key={index} image={item.content} />;
                  }
                })}
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-12 order-last">
            <div className="widget-area">
              <SearchWidget />
              <RecentPostWidget />
              <CategoriesWidget />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BlogDetailsMain;

const Heading = ({ heading }) => {
  return <h3>{heading}</h3>;
};

const List = ({ list }) => {
  return (
    <ul className="dots">
      {list.map((item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
};

const Paragraph = ({ content }) => {
  return <p>{content}</p>;
};

const Image = ({ image }) => {
  return (
    <div className="bs-img mb-34">
      <img src={image} alt="Post Image" />
    </div>
  );
};
