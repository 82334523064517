import { motion } from "framer-motion";

const SectionTitle = (props) => {
  const fadeUpAnimation = {
    initial: {
      opacity: 0,
      y: 50,
    },
    whileInView: {
      opacity: 1,
      y: 0,
    },
    viewport: {
      once: true,
    },
  };

  const { titleClass, subtitleClass, descClass, secondDescClass, effectClass } =
    props;
  return (
    <motion.div className={props.sectionClass} {...fadeUpAnimation}>
      {subtitleClass ? (
        <div className={props.subtitleClass}>{props.subtitle}</div>
      ) : (
        ""
      )}
      {titleClass ? <div className={props.titleClass}>{props.title}</div> : ""}
      {descClass ? (
        <div className={props.descClass}>{props.description}</div>
      ) : (
        ""
      )}
      {secondDescClass ? (
        <div className={props.secondDescClass}>{props.secondDescription}</div>
      ) : (
        ""
      )}
      {effectClass ? <div className={props.effectClass}></div> : ""}
    </motion.div>
  );
};

export default SectionTitle;
