import React, { useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const PortfolioTabTwo = ({ accordians }) => {
  const tabStyle = "nav nav-tabs";

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div className="tab-area">
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <div className="row">
          <div className="col-lg-8 pr-40 md-pr-14 md-mb-30 align-items-center justify-content-center d-flex">
            <div className="tab-content ">
              {accordians.map((val, i) => (
                <TabPanel key={i}>
                  <div className="image-wrap">
                    <img
                      src={val.side_image_url}
                      alt="Process Tab"
                      style={{ height: "100%", aspectRatio: "16/9" }}
                    />
                  </div>
                </TabPanel>
              ))}
            </div>
          </div>
          <div className="col-lg-4">
            <TabList className={tabStyle}>
              {accordians.map((val, i) => (
                <Tab key={i}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h3>{val.heading}</h3>
                    <span>
                      {i === tabIndex ? (
                        <i className="fa fa-chevron-up"></i>
                      ) : (
                        <i className="fa fa-chevron-down"></i>
                      )}
                    </span>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: val.description }}></p>
                </Tab>
              ))}
            </TabList>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

export default PortfolioTabTwo;
