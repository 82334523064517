import React from "react";
import ErrorContent from "../components/Error";

class Error extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ErrorContent />
      </React.Fragment>
    );
  }
}

export default Error;
