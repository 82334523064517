import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

const SliderStyleTwo = () => {
  const HeroData = {
    title: "Business Success",
    desc: "Leveraging Modern Technology & Expertise",
    btnText: "Get in touch",
    btnLink: "/about",
    subTitle: "We Accelerate Your",
  };

  const fadeUpAnimation = {
    initial: {
      opacity: 0,
      y: 50,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <React.Fragment>
      <motion.div
        className="rs-slider style1"
        initial={{
          borderRadius: 100,
          scale: 0.5,
          opacity: 0,
          y: 50,
        }}
        whileInView={{
          borderRadius: 0,
          scale: 1,
          opacity: 1,
          y: 0,
        }}
        transition={{
          delay: 1 * 0.2,
        }}
        viewport={{
          once: true,
        }}
      >
        {/* <div className="slider-content slide1">
          <div className="container">
            <div className="content-part">
              <motion.div
                className="sl-sub-title"
                initial={{
                  opacity: 0,
                  y: 50,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  duration: 1,
                }}
                viewport={{
                  once: true,
                }}
              >
                {HeroData.subTitle}
              </motion.div>

              <motion.h1
                className="sl-title mb-mb-10"
                initial={{
                  opacity: 0,
                  y: 50,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  duration: 1,
                  delay: 0.3,
                }}
              >
                {HeroData.title}
              </motion.h1>

              <motion.div
                className="sl-desc"
                transition={{
                  delay: 0.5,
                  duration: 1,
                }}
                {...fadeUpAnimation}
              >
                {HeroData.desc}
              </motion.div>
              <motion.div
                className="sl-btn"
                transition={{
                  delay: 0.7,
                  duration: 1,
                }}
                {...fadeUpAnimation}
              >
                <Link
                  className="readon learn-more slider-btn"
                  to={HeroData.btnLink || "/about"}
                >
                  {HeroData.btnText}
                </Link>
              </motion.div>
            </div>
          </div>
        </div> */}
        <div
          className="slider-content slide2"
          // style={{
          //   backgroundImage: 'url(https://t4.ftcdn.net/jpg/06/30/55/59/240_F_630555907_6Ybo1ARFzsQWQr17YqyymPeCGc4zDsfK.jpg)',
          // }}
        >
          <div className="container">
            <div className="content-part text-center">
              <div
                className="sl-sub-title"
                style={{
                  overflow: "visible",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                }}
              >
                <motion.div
                  initial={{
                    opacity: 0,
                    y: -50,
                  }}
                  animate={{
                    opacity: 1,
                    y: 0,
                  }}
                  transition={{
                    duration: 1,
                    delay: 0.7,
                  }}
                >
                  {HeroData.subTitle}
                </motion.div>
              </div>
              <div
                className="sl-title mb-mb-10"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1
                  className="sl-title mb-mb-10 "
                  style={{
                    width: "max-content",
                    overflow: "visible",
                  }}
                >
                  {HeroData.title.split("").map((char, index) => {
                    return (
                      <motion.span
                        key={char + "-" + index}
                        initial={{
                          opacity: 0,
                          y: index % 2 === 0 ? 50 : -50,
                          x: index % 2 === 0 ? 50 : -50,
                        }}
                        animate={{
                          opacity: 1,
                          y: 0,
                          x: 0,
                        }}
                        transition={{
                          duration: 1,
                          delay: 0.1 * index + 0.7,
                        }}
                      >
                        {char}
                      </motion.span>
                    );
                  })}
                </h1>
              </div>

              <div
                className="sl-desc"
                style={{
                  overflowWrap: "break-word",
                }}
              >
                {HeroData.desc.split("").map((char, index) => {
                  return (
                    <motion.span
                      key={char + "-" + index}
                      initial={{
                        opacity: 0,
                        y: index % 2 === 0 ? 50 : -50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        duration: 1,
                        delay: (index % 6) * 0.1 + 0.7,
                      }}
                    >
                      {char === " " ? "\u00A0" : char}
                    </motion.span>
                  );
                })}
              </div>
              <motion.ul
                className="slider-btn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                }}
                transition={{
                  delay: 2,
                  duration: 1,
                }}
                {...fadeUpAnimation}
              >
                <li>
                  <Link className="readon learn-more slider-btn" to="/about">
                    Free Consultation
                  </Link>
                </li>
              </motion.ul>
            </div>
          </div>
        </div>
      </motion.div>
    </React.Fragment>
  );
};

export default SliderStyleTwo;
