import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";

const SingleServiceTwo = (props) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const rotateX = useTransform(mouseYSpring, [-0.3, 0.3], ["5deg", "-5deg"]);
  const rotateY = useTransform(mouseXSpring, [-0.3, 0.3], ["-5deg", "5deg"]);

  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.3;
    const yPct = mouseY / height - 0.3;

    x.set(xPct);
    y.set(yPct);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <motion.div
      className={props.itemClass}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        rotateY,
        rotateX,
        transformStyle: "preserve-3d",
      }}
    >
      <div
        className="services-icon"
        style={{
          transform: "translateZ(20px)",
          transformStyle: "preserve-3d",
        }}
      >
        <div className="image-part">
          <img className="main-img" src={props.mainIcon} alt={props.Title} />
          <img className="hover-img" src={props.hoverIcon} alt={props.Title} />
        </div>
      </div>
      <div
        className="services-content"
        style={{
          transform: "translateZ(20px)",
          transformStyle: "preserve-3d",
        }}
      >
        <motion.div
          className="services-text"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 1,
            delay: (props.index + 1) * 0.2,
          }}
          viewport={{
            once: true,
          }}
        >
          <h3 className="title">
            {props.isNavigate ? (
              <Link to={props.serviceURL}>{props.Title}</Link>
            ) : (
              <a href>{props.Title}</a>
            )}
          </h3>
        </motion.div>
        <motion.div
          className="services-desc"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 1,
            delay: (props.index + 1) * 0.2,
          }}
          viewport={{
            once: true,
          }}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: props.Text,
            }}
          ></p>
        </motion.div>
        <div className="serial-number">{props.prefix}</div>
      </div>
    </motion.div>
  );
};

export default SingleServiceTwo;
